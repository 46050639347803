import React from "react";
import HealthAndSafetyIcon from '../../images/location-details/health_and_safety.svg';
import Icon_Yes from '../../images/location-details/Icon_Yes.svg';
import Icon_No from '../../images/location-details/Icon_No.svg';
import Icon_Null from '../../images/location-details/Icon_Null.svg';
import "./LocationDetailsComponents.css";

export default function LocationSafetyRequirements(props) {
    const additionalSafetyRequirements = props.additionalSafetyRequirements?.length > 0 ?
                                         props.additionalSafetyRequirements.reduce((acc, curr) => `${acc}${curr.equipmentName}, `, '') : "";
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img src={HealthAndSafetyIcon} alt="" /></div>
            <div className="location-details-body"><p className='location-details-label'>Safety Requirements</p>
                {props.safetyRequirements || (additionalSafetyRequirements !== undefined && additionalSafetyRequirements !== "") ? (
                    <>
                        {props.safetyRequirements && (props.safetyRequirements.safetyGlassesRequired || props.safetyRequirements.hardHatRequired || props.safetyRequirements.vestRequired ||
                            props.safetyRequirements.closedToeShoesRequired || props.safetyRequirements.steelToeShoesRequired) ?
                            <div style={{ paddingTop: "10px" }}>
                                <p className='sub-title'>Required Equipment</p>
                                {props.safetyRequirements.safetyGlassesRequired && <p>Safety Glasses</p>}
                                {props.safetyRequirements.hardHatRequired && <p>Hard Hat</p>}
                                {props.safetyRequirements.vestRequired && <p>Vest</p>}
                                {props.safetyRequirements.closedToeShoesRequired && <p>Closed Toe Shoes</p>}
                                {props.safetyRequirements.steelToeShoesRequired && <p>Steel Toe Shoes</p>}
                            </div>
                            : <div style={{ marginBottom: "-6%" }}></div>
                        }
                        {(additionalSafetyRequirements !== undefined && additionalSafetyRequirements !== "") ?
                            <div className='sub-container'>
                                <p className='sub-title'>Additional Equipment Information</p>
                                <p className="location-details-word-wrap">{additionalSafetyRequirements.substring(0, additionalSafetyRequirements.length - 2)}</p>
                            </div>
                            : <></>
                        }
                        {props.safetyRequirements &&
                            (
                                <>
                                    {props.safetyRequirements.covidProtocolInstructions ?
                                        <div className='sub-container'>
                                            <p className='sub-title'>Covid Protocols</p>
                                            <p className="location-details-word-wrap">{props.safetyRequirements.covidProtocolInstructions}</p>
                                        </div>
                                        : <></>
                                    }
                                    <div className='sub-container'>
                                        <p className='sub-title'>Pets</p>
                                        {props.safetyRequirements.petsAllowed === true && <div style={{ marginRight: 60 }}><p><span className='amenity-label-yes'>Pets Allowed</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>}
                                        {props.safetyRequirements.petsAllowed === false && <div style={{ marginRight: 60 }}><p><span className='amenity-label-no'>Pets Allowed</span><img className='amenity-img' src={Icon_No} alt="" /></p></div>}
                                        {props.safetyRequirements.petsAllowed === null && <div style={{ marginRight: 60 }}><p><span className='amenity-label-null'>Pets Allowed</span><img className='amenity-img' src={Icon_Null} alt="" /></p></div>}
                                    </div>
                                </>
                            )
                        }
                    </>
                ) : <p className='location-details-nodata'>NO DATA AVAILABLE</p>
                }
            </div>
        </div>
    )
}