import React, { useState } from 'react';
import './Looks.css'
import Looker from './Looker';
import '../elements/tab/TabPanelContainer';
import { makeStyles } from '@material-ui/core/styles';
import Ripple from '../elements/ripple/Ripple';

export default function Looks(props) {

const visibleMonthlyLooksClassName = 'visible-monthly';
const hideMonthlyLooksClassName = 'hide-monthly';

const visibleVisitTrendsClassName = 'visible-visit-trends';
const hideVisitTrendsClassName = 'hide-visit-trends';

const [visitTrendsClassName, setVisitClassName] = useState(visibleVisitTrendsClassName);
const [monthlyClassName, setMonthlyClassName] = useState(hideMonthlyLooksClassName);
const [scrollLeft, setScrollLeft] = useState(0);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    }));

const classes = useStyles();

const timeZone = (props.timeZone == null) ? 'UTC' : 'US%2F' + props.timeZone;

function GetMonthlyLooks() {
    return(
    <div className={monthlyClassName}>
        <div className="dashboard last_month">
            <Looker
                id="last-month-dashboard"
                targetURL={"https://omnitracsanalyze.looker.com/dashboards-next/277?Rec+ID=" + props.locationId + "&query_timezone=" + timeZone}>
            </Looker>
        </div>
    </div> 
    )
}

function GetVisitTrendsLooks() {
    return(
    <div className={visitTrendsClassName}>
        <div className="dashboard visit_trends">
            <Looker
                id="visit-trends-dashboard"
                targetURL={"https://omnitracsanalyze.looker.com/dashboards-next/274?Rec+ID=" + props.locationId + "&query_timezone=" + timeZone}>
            </Looker>
        </div>
    </div>
    )
}

function EnableMonthlyLooks() {
    setMonthlyClassName(visibleMonthlyLooksClassName);
    setVisitClassName(hideVisitTrendsClassName);
    setScrollLeft(1);
}

function GetAllLooks()
{
    return(
        <>
         {GetVisitTrendsLooks()}
         {GetMonthlyLooks()}
        </>
    )
}

function EnableVisitTrendsLooks() {
    setVisitClassName(visibleVisitTrendsClassName);
    setMonthlyClassName(hideMonthlyLooksClassName);
    setScrollLeft(0);
}

return (
<div className="dashboard-container">
    <div className={classes.root}>
        <div className="tabs-container">
            <div>
            <Ripple onClickHandler={()=>EnableVisitTrendsLooks()}>
                <div className="tab-button">
                    <span style={{opacity: scrollLeft === 0 ? 1 : 0.7}} >
                        VISIT TRENDS
                    </span>                       
                </div>
                </Ripple>
                <Ripple onClickHandler={()=>EnableMonthlyLooks()}>
                <div className="tab-button">  
                    <span style={{opacity: scrollLeft === 1 ? 1 : 0.7}}>
                        LAST MONTH
                    </span>
                </div>      
                </Ripple> 
            </div>
            <span className='tab-scroll' style={{left: scrollLeft * 160 + 'px'}}></span>
        </div>
        {GetAllLooks()}
    </div>
</div>
);
}