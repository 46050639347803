import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import "../../components/AddEntityModal.css";
import "./PendingEditsModal.css";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../../constants/apiConstants.js';
import useAxios from 'axios-hooks';
import { useSessionContext } from "../../libs/sessionLib";
import ErrorIcon from '@material-ui/icons/Error';
import ProgressSpinner from "../../components/ProgressSpinner";
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import PendingLocationNameEdits from "./PendingLocationNameEdits";
import PendingGeofenceEdits from "./PendingGeofenceEdits";
import PendingLocationTypeEdits from "./PendingLocationTypeEdits";
import PendingContactEdits from "./PendingContactEdits";
import PendingBusinessHoursEdits from "./PendingBusinessHoursEdits";
import PendingClosuresEdits from "./PendingClosuresEdits";
import PendingAppointmentSchedulingEdits from "./PendingAppointmentSchedulingEdits";
import PendingAmenitiesEdits from "./PendingAmenitiesEdits";
import PendingServicesEdits from "./PendingServicesEdits";
import PendingSafetyRequirementsEdits from "./PendingSafetyRequirementsEdits";
import PendingProceduresEdits from "./PendingProceduresEdits";

export default function PendingEditsModal(props) {

    const { session } = useSessionContext();
    const api_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/location/" : API_BASE_URL + "/location/";

    const [activeSection, setActiveSection] = useState("locationName");  
    const [editContainerHeader, setEditContainerHeader] = useState("Location Names");
    const pendingEditsCountBySection = props.locationEditsInfo?.editsBySection;
    const [totalPendingEditsOfActiveSection, setTotalPendingEditsOfActiveSection] = useState(0);
    const [editsToUpdate, setEditsToUpdate] = useState(null);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(true);
    const toggleOptions = [ {id: 1, name: "Approve"}, {id: 0, name: "Deny"}];
    const formatPhoneNumber = (value) => {
        if (value !== null && value.length > 0){
            const phoneLength = value.length;
            if (phoneLength < 4) return value;
            if (phoneLength === 7) return `${value.slice(0, 3)}-${value.slice(3)}`;
            if (phoneLength === 10) return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }
        return value;
    };
    const userTimeZone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    const convertTimeToLocal = date => {
        var localDate = new Date(date);
        localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
        return localDate.toLocaleString('en-US', {hour12: false});
    };
    const reviewedAllDefault = {
        'locationName': { 'reviewedAll': false, 'approvedAll': null },
        'geoFence1': { 'reviewedAll': false, 'approvedAll': null },
        'locationType': { 'reviewedAll': false, 'approvedAll': null },
        'contactInfo': { 'reviewedAll': false, 'approvedAll': null },
        'amenities': { 'reviewedAll': false, 'approvedAll': null },
        'otherAmenities': { 'reviewedAll': false, 'approvedAll': null },
        'businessHours': { 'reviewedAll': false, 'approvedAll': null },
        'mainClosures': { 'reviewedAll': false, 'approvedAll': null },
        'appointmentScheduling': { 'reviewedAll': false, 'approvedAll': null },
        'services': { 'reviewedAll': false, 'approvedAll': null },
        'safetyRequirements': { 'reviewedAll': false, 'approvedAll': null },
        'otherSafetyRequirements': { 'reviewedAll': false, 'approvedAll': null },
        'procedures': { 'reviewedAll': false, 'approvedAll': null }
    };
    const [reviewedAll, setReviewedAll] = useState(reviewedAllDefault);

    useEffect(() => {
        if(props.locationEditsInfo?.locationId > 0) {
            getLocationDetails();
            getPendingEdits();
            setTotalPendingEditsOfActiveSection(pendingEditsCountBySection?.locationName);
        }
    }, [props.locationEditsInfo?.locationId]);

    // Get Location Details
    const api_location_details_url = api_url + props.locationEditsInfo?.locationId + "?isExternalUser=" + !session.isInternalUser;
    const [{ data: locationData, loading: locationDetailsLoading, error: locationDetailsError }, fetchLocationDetails] = useAxios(
        {
            url: api_location_details_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );
    const [locationDetails, setLocationDetails] = useState(null);
    function getLocationDetails() {
        try {           
            fetchLocationDetails();           
        } catch (e) {
            triggerErrorAlert(true, "Error getting location data.");
        }
    };

    useEffect(() => {
        setLocationDetails(locationData);
    }, [locationData]);

    useEffect(() => {
        handleError(locationDetailsError, "Error retrieving location's original data.");
    }, [locationDetailsError]);

    // Get Location's Pending Edits
    const api_pending_edits_by_locationId_url = api_url + "edits/pending-edits/" + props.locationEditsInfo?.locationId;
    const [{ data: pendingEditsData, loading: pendingEditsLoading, error: pendingEditsError }, fetchPendingEdits] = useAxios(
        {
            url: api_pending_edits_by_locationId_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );
    const [pendingEdits, setPendingEdits] = useState(null);
    function getPendingEdits() {
        try {
            fetchPendingEdits();
        } catch (e) {
            triggerErrorAlert(true, "Error getting pending edits data.");
        }
    };

    useEffect(() => {
        setPendingEdits(pendingEditsData);
    }, [pendingEditsData]);

    useEffect(() => {
        handleError(pendingEditsError, "Error retrieving location's pending edits.");
    }, [pendingEditsError]);

    
    useEffect(() => {
        setEditContainerHeader(renderSwitchHeader);     
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection])

    const modalRef = useRef();

    function handleSectionTabClick(sectionName, pendingEditsCount){
        modalRef.current?.scrollTo(0, 0);
        setActiveSection(sectionName);
        setTotalPendingEditsOfActiveSection(pendingEditsCount);
    }

    function renderSwitchHeader() {
        switch (activeSection) {
            case 'geofence': return "Geofence";
            case 'locationType': return "Location Type";
            case 'contacts': return "Contacts";
            case 'amenities': return "Amenities";
            case 'businessHours': return "Business Hours";
            case 'closures': return "Closures";
            case 'appointmentScheduling': return "Appointment Scheduling";
            case 'services': return "Services";
            case 'safetyRequirements': return "Safety Requirements";
            case 'procedures': return "Procedures";
            case 'rateLocation': return "Rate Location";
            case 'none': return "Review Location";
            default: return "Location Names";
        }
    }

    // Approve Deny functions
    function handleApproveDeny(target, sectionName){
        if(target){

            // Update PendingEditsList of Section
            var updatedPendingEdits = pendingEdits[sectionName];
            if(target.name === "approveDenyAll")
                updatedPendingEdits = updatedPendingEdits.map(item => ({ ...item, approved: parseInt(target.value) }));
            else
                updatedPendingEdits.splice(target.dataset.index, 1, {...updatedPendingEdits[target.dataset.index], approved: parseInt(target.value)});
                
            setPendingEdits({
                ...pendingEdits,
                [sectionName]: updatedPendingEdits
            });   

            // Update EditsToUpdate List and reviewedAll of Section
            var updatedReviewedEdits = editsToUpdate ? editsToUpdate[sectionName] : null ;     
            if(target.name === "approveDenyAll"){
                updatedPendingEdits.forEach(edits => {
                    var editIds = (sectionName === "businessHours") ? edits.editIds : [edits.editId];
                    updatedReviewedEdits = updateEditsToUpdateList(editIds, target.value, updatedReviewedEdits);
                });
                setReviewedAll({
                    ...reviewedAll,
                    [sectionName]: { 'reviewedAll': true, 'approvedAll': parseInt(target.value) }
                });
            }
            else{
                var editIds = (sectionName === "businessHours") ? target.name.split(',') : [target.name];
                updatedReviewedEdits = updateEditsToUpdateList(editIds, target.value, updatedReviewedEdits);
                if(reviewedAll[sectionName].approvedAll !== null)
                    setReviewedAll({
                        ...reviewedAll,
                        [sectionName]: { 'reviewedAll': true, 'approvedAll': null }
                    });
            }
            setEditsToUpdate({
                ...editsToUpdate,
                [sectionName]: updatedReviewedEdits,
            });
        }  
    }

    function updateEditsToUpdateList(editIds, approved, listToUpdate){
        editIds.forEach(editId => {
            if(listToUpdate?.some(s => s.id === editId)){
                listToUpdate = listToUpdate.map(item => {
                                                    if (item.id === editId) {
                                                        return {...item, approved: parseInt(approved) === 1};
                                                    }
                                                    return item;
                                                });                
            }
            else{
                listToUpdate = listToUpdate ? [ ...listToUpdate, { id: editId, approved: parseInt(approved) === 1 } ] 
                                            : [{ id: editId, approved: parseInt(approved) === 1 }];
            }
        });
        return listToUpdate;
    }

    function handleReviewedAllCheck(checked, sectionName){
        setReviewedAll({
            ...reviewedAll,
            [sectionName]: { 'reviewedAll': checked, 'approvedAll': null }
        });
    }

    useEffect(() => {
        setDisableSubmit(editsToUpdate === null);
    }, [editsToUpdate]);

    const [{ data: postApproveDenyData, loading: postApproveDenyLoading, error: postApproveDenyError }, postApproveDeny] = useAxios(
        {
            url: api_url + "edits/approve-deny",
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );
    async function handleSubmit() {
        setDisableSubmit(true);
        try {
            await postApproveDeny(
                {data: {
                    ...editsToUpdate,
                    locationId: props.locationEditsInfo.locationId,
                    editUpdatedBy: session.userName
                }}).then(_ => { setDisableSubmit(false); });               
            props.onSuccess(true, "Review has been submitted successfully.");
            handleClose();
        } catch (err) {
            setDisableSubmit(false);
        }
        
    }

    useEffect(() => {
        handleError(postApproveDenyError, "Error processing approve deny request.");
    }, [postApproveDenyError]);

    // Error
    function handleError(error, errorMessage){
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, errorMessage);
            } else {
                // anything else
                triggerErrorAlert(true, errorMessage);
            }
        }
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function handleClose() {
        setActiveSection("locationName");
        setDisableSubmit(true);
        setLocationDetails(null);
        setPendingEdits(null);
        setEditsToUpdate(null);
        setReviewedAll(reviewedAllDefault);
        setShowErrorAlert(false);
        setErrorAlertMessage("");
        props.onHide();
    }

    function renderSectionBadge(sectionName, edits){
        return (activeSection === sectionName) ?
                    <ArrowForwardIcon className="detail-arrow" /> : 
                    edits > 0 ? <div className = "edits-count">{edits > 99 ? "99+" : edits}</div> : <></>
    }

    return (
        props.locationEditsInfo ?
        (<Modal
            {...props}
            dialogClassName="pending-edits-modal-container pending-edits"
            centered
            onHide={handleClose}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>
                    <EditLocationAltIcon />   
                    <div style={{ marginLeft: "10px" }}>Review Pending Edits { "(" +  props.locationEditsInfo?.totalPendingEdits  + ")"}</div>
                    <div className="modal-close" onClick={() => !postApproveDenyLoading && handleClose()}><CloseIcon /></div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="edit-container-main">
                {pendingEditsLoading || locationDetailsLoading || postApproveDenyLoading ? (
                    <div className="spinner-container modal-spinner-container spinner-black" style={{ zIndex: "1" }}>
                        <ProgressSpinner fontColor="#FFFFFF" />
                    </div>
                ) : <></> }
                <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                    <ErrorIcon />
                    <div className="floating-alert-body">
                        <Alert.Heading>Error</Alert.Heading>
                        <p>{errorAlertMessage}</p>
                    </div>
                </Alert>
                {(locationDetails && pendingEdits) &&
                (<>                                                                         
                    <div className="edit-header">
                        <div className="edit-title">{locationDetails.name}</div>
                        <div>
                            <Button className="btn-cancel" disabled={postApproveDenyLoading} onClick={() => handleClose()}>Cancel</Button>
                            <Button style={{ marginLeft: '20px' }} className="btn-submit" disabled={disableSubmit} onClick={() => handleSubmit()}>Submit</Button>
                        </div>
                    </div>
                    <div className="pending-edits-container-body" ref={modalRef}>
                        <div className="edit-container">
                            <div className="edit-container-body">
                                <div className="edit-sections">
                                    <div className={activeSection === "locationName" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("locationName", pendingEditsCountBySection?.locationName)}>
                                        Location Names {renderSectionBadge("locationName", pendingEditsCountBySection?.locationName)}
                                    </div>
                                    <div className={activeSection === "geofence" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("geofence", pendingEditsCountBySection?.geoFence)}>
                                        Geofence {renderSectionBadge("geofence", pendingEditsCountBySection?.geoFence)}                                                
                                    </div>                               
                                    <div className={activeSection === "locationType" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("locationType", pendingEditsCountBySection?.locationType)}>                                           
                                        Location Type {renderSectionBadge("locationType", pendingEditsCountBySection?.locationType)}                                                                                                             
                                    </div>
                                    <div className={activeSection === "contacts" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("contacts", pendingEditsCountBySection?.contacts)}>
                                        Contacts {renderSectionBadge("contacts", pendingEditsCountBySection?.contacts)}
                                    </div>
                                    <div className={activeSection === "businessHours" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("businessHours", pendingEditsCountBySection?.businessHours)}>
                                        Hours {renderSectionBadge("businessHours", pendingEditsCountBySection?.businessHours)}   
                                    </div>
                                    <div className={activeSection === "closures" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("closures", pendingEditsCountBySection?.closures)}>
                                        Closures {renderSectionBadge("closures", pendingEditsCountBySection?.closures)}
                                    </div>
                                    <div className={activeSection === "appointmentScheduling" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("appointmentScheduling", pendingEditsCountBySection?.appointmentScheduling)}>
                                        Appointment Scheduling {renderSectionBadge("appointmentScheduling", pendingEditsCountBySection?.appointmentScheduling)}
                                    </div>
                                    <div className={activeSection === "amenities" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("amenities", pendingEditsCountBySection?.amenities)}>
                                        Amenities {renderSectionBadge("amenities", pendingEditsCountBySection?.amenities)}
                                    </div>
                                    <div className={activeSection === "services" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("services", pendingEditsCountBySection?.services)}>
                                        Services {renderSectionBadge("services", pendingEditsCountBySection?.services)}
                                    </div>
                                    <div className={activeSection === "safetyRequirements" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("safetyRequirements", pendingEditsCountBySection?.safetyRequirements)}>
                                        Safety Requirements {renderSectionBadge("safetyRequirements", pendingEditsCountBySection?.safetyRequirements)}
                                    </div>
                                    <div className={activeSection === "procedures" ? "edit-section-box active" : "edit-section-box"} onClick={() => handleSectionTabClick("procedures", pendingEditsCountBySection?.procedures)}>
                                        Procedures {renderSectionBadge("procedures", pendingEditsCountBySection?.procedures)}
                                    </div>
                                </div>
                                <div className="edit-details-container">
                                    <div className="edit-details-header">{`${editContainerHeader} (${totalPendingEditsOfActiveSection ? totalPendingEditsOfActiveSection : 0})`}</div> 
                                    {activeSection === "locationName" && (<PendingLocationNameEdits mainName={locationDetails.name ? locationDetails.name : ""} pendingEdits={pendingEdits.locationName} convertTimeToLocal={convertTimeToLocal} reviewedAll={reviewedAll.locationName} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "geofence" && (<PendingGeofenceEdits originalGeofence={locationDetails.geoFence1} pendingEdits={pendingEdits.geoFence1} convertTimeToLocal={convertTimeToLocal} reviewedAll={reviewedAll.geoFence1} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "locationType" && (<PendingLocationTypeEdits originalLocationType={locationDetails.locationTypeDescription} pendingEdits={pendingEdits.locationType} reviewedAll={reviewedAll.locationType} convertTimeToLocal={convertTimeToLocal} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "contacts" && (<PendingContactEdits originalContactInfo={locationDetails.contactInfo} pendingEdits={pendingEdits.contactInfo} reviewedAll={reviewedAll.contactInfo} formatPhoneNumber={formatPhoneNumber} convertTimeToLocal={convertTimeToLocal} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "businessHours" && (<PendingBusinessHoursEdits originalHours={locationDetails.businessHours} timeZoneAbbreviation={locationDetails.timeZoneAbbreviation} pendingEdits={pendingEdits.businessHours} convertTimeToLocal={convertTimeToLocal} reviewedAll={reviewedAll.businessHours} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck}  />)}
                                    {activeSection === "closures" && (<PendingClosuresEdits originalMainClosures={locationDetails.mainClosures} pendingMainClosuresEdits={pendingEdits.mainClosures} reviewedAll={reviewedAll.mainClosures} convertTimeToLocal={convertTimeToLocal} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "appointmentScheduling" && (<PendingAppointmentSchedulingEdits originalAppointmentScheduling={locationDetails.appointmentScheduling} pendingEdits={pendingEdits.appointmentScheduling} reviewedAll={reviewedAll.appointmentScheduling} formatPhoneNumber={formatPhoneNumber} convertTimeToLocal={convertTimeToLocal} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "amenities" && (<PendingAmenitiesEdits originalAmenities={locationDetails} pendingAmenitiesEdits={pendingEdits.amenities} reviewedAll={reviewedAll.amenities} pendingOtherAmenitiesEdits={pendingEdits.otherAmenities} reviewedAllOther={reviewedAll.otherAmenities} convertTimeToLocal={convertTimeToLocal} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "services" && (<PendingServicesEdits originalServices={locationDetails.services} pendingEdits={pendingEdits.services} reviewedAll={reviewedAll.services} toggleOptions={toggleOptions} convertTimeToLocal={convertTimeToLocal} userTimeZone={userTimeZone} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "safetyRequirements" && (<PendingSafetyRequirementsEdits originalSafetyRequirements={locationDetails.safetyRequirements} reviewedAll={reviewedAll.safetyRequirements} originalAdditionalEquipments={locationDetails.additionalSafetyRequirements} reviewedAllOther={reviewedAll.otherSafetyRequirements} pendingSafetyRequirementsEdits={pendingEdits.safetyRequirements} pendingAdditionalEquipmentsEdits={pendingEdits.otherSafetyRequirements} convertTimeToLocal={convertTimeToLocal} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}
                                    {activeSection === "procedures" && (<PendingProceduresEdits originalProcedures={locationDetails.procedures} pendingEdits={pendingEdits.procedures} reviewedAll={reviewedAll.procedures} convertTimeToLocal={convertTimeToLocal} userTimeZone={userTimeZone} toggleOptions={toggleOptions} handleApproveDeny={handleApproveDeny} handleReviewedAllCheck={handleReviewedAllCheck} />)}                                    
                                </div>
                            </div>                     
                        </div>
                    </div>
                </>)}
            </Modal.Body>
        </Modal>) : <></>
    );
}