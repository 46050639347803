import React from "react";
import "./LocationSearchResults.css";
import { Link } from "react-router-dom";
import BingMap from "../../components/BingMap";
import ProgressSpinner from "../../components/ProgressSpinner";
import { Button } from "react-bootstrap";

export default function LocationSearchResults(props){

    return (
        (props.loading ? (
            <div className="spinner-container">
                <ProgressSpinner fontColor="#FFFFFF" />
            </div>
        ) : (
            (props.locations !== undefined && props.locations.length > 0) ? (
                <div className="search-body">
                    <div className="search-results-container Main-Layout-Body-scroll">
                        <div className="search-results-header">RESULTS - Displaying Top {props.numberOfRecordsToShow} Results</div>
                        <div className="search-results">
                        {(props.loading || props.error) ? (
                            <div>{props.searchResultMessageToDisplay}</div>
                        ) : (
                            props.locations !== undefined ? (
                                props.locations.map(item =>
                                    <Link to={"/locationdetails/" + item.id} target="_blank" rel="noopener noreferrer" className="location-link">
                                        <div key={`${item.id}`} className="location-item"
                                            onMouseEnter={e => {
                                                props.setLocationDetailsButtonStyle(item.id);
                                            }}
                                            onMouseLeave={e => {
                                                props.setLocationDetailsButtonStyle("")
                                            }}
                                        >
                                            <div style={{ width: '100%', display: 'inline-flex' }} >
                                                <div className="location-name" style={{ width: "23px", textAlign: "right" }}>{item.pinLabel}. </div>
                                                <div style={{ width: "100%", marginLeft: "3px" }}>
                                                    <div className="location-name">{item.name}</div>
                                                    {(item.subNameMatched && props.subNameMatch.length > 0) ? (
                                                        <div className="subname-matched">{props.subNameMatch} is associated with this location.</div>
                                                    ) : (<></>)}
                                                    <div className="location-info-container">                                               
                                                        <div className="location-info location-type"><b>Location Type</b><br />{props.locationTypes[item.locationTypeId]}</div>
                                                        <div className="location-info location-address"><b>Address</b><br />{item.address}</div>                                            
                                                        <div className="location-info-display">
                                                            <div className="location-info location-city"><b>City</b><br />{item.city}</div>
                                                            <div className="location-info location-state"><b>State</b><br />{item.state}</div>
                                                            <div className="location-info location-zipcode"><b>Zip</b><br />{item.zipCode}</div>
                                                        </div>                                               
                                                    </div>
                                                </div>
                                            </div>                                           
                                        </div>
                                    </Link>
                                )
                            ) : (<></>)
                        )}
                    </div>
                    </div>
                    <div className="map">
                        <BingMap
                            height="100%"
                            width="100%"
                            pins={props.locations}
                        />
                    </div>
                </div>
            ) : (
                (!props.loading && props.showNoResults) ? (
                    <div className="no-results">
                        <div className="no-results-header">
                            No Results for search term(s) used
                        </div>
                        <div className="no-results-body">
                            <b>Search Help</b><br />
                            Double check your search for any typos or spelling errors - or try different search terms.<br />
                            <Button
                                type="submit"
                                className="btn-cancel"
                                onClick={(e) => { props.handleSearchInputClear(e) }}
                            >
                                Clear search terms and try again
                            </Button>
                        </div>
                    </div>
                ) : (<></>)
            )
        ))
    )
}