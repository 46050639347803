import React from "react";
import LocationIDIcon from '../../images/location-details/Location ID.svg';
import "./LocationDetailsComponents.css";

export default function LocationID(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img src={LocationIDIcon} alt="" /></div>
            <div className="location-details-body"><p className='location-details-label'>Location ID</p>
                <p>{props.id}</p></div>
        </div>
    )
}