import React from "react";
import AppointmentScheduling from '../../images/location-details/AppointmentSchedulingIcon.svg'
import "./LocationDetailsComponents.css";

export default function LocationAppointmentSchedulingMobile(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img src={AppointmentScheduling} alt="" /></div>
            <div className="location-details-body"><p className='location-details-label'>Appointment Type</p>
                {props.appointmentScheduling ? (                    
                        props.appointmentScheduling.byAppointment ?
                            (
                                <>
                                    <p>By Appointment Only</p>
                                </>
                            ) : (<p>FCFS</p>)                    
                ) : (<p className='location-details-nodata'>NO DATA AVAILABLE</p>)}
            </div>
        </div>
    )
}