import React, { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import EditLocationRadioButtons from "../editlocationdetails/EditLocationRadioButtons";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationAmenities from "../locationdetails/LocationAmenities";
import ApproveDenyAllToggle from "./ApproveDenyAllToggle";

export default function PendingAmenitiesEdits(props) {
  const pendingAmenitiesEdits = useMemo(() => { return props.pendingAmenitiesEdits }, [props]);
  const pendingOtherAmenitiesEdits = useMemo(() => { return props.pendingOtherAmenitiesEdits }, [props]);
  const [panel1Expanded, setPanel1Expanded] = useState(true);
  const [panel2Expanded, setPanel2Expanded] = useState(true);

  function handleAmenitiesApproveDeny(target){
    props.handleApproveDeny(target, "amenities");
  }

  function handleReviewedAllAmenitiesCheck(checked){
    props.handleReviewedAllCheck(checked, "amenities");
  }

  function handleOtherAmenitiesApproveDeny(target){
    props.handleApproveDeny(target, "otherAmenities");
  }

  function handleReviewedAllOtherAmenitiesCheck(checked){
    props.handleReviewedAllCheck(checked, "otherAmenities");
  }
  
  return (
    <div className="edit-details-body">
      <div className="edit-details-title">
        Original Amenities
        <div className="original-data-body amenities-edits"> 
          <LocationAmenities
            restrooms={props.originalAmenities.restrooms}
            overnightParking={props.originalAmenities.overnightParking}
            breakroom={props.originalAmenities.breakroom}
            vendingMachine={props.originalAmenities.vendingMachine}
            showers={props.originalAmenities.showers}
            wiFi={props.originalAmenities.wiFi}
            additionalAmenities={props.originalAmenities.additionalAmenities}
          />
          {props.originalAmenities.additionalAmenities.length === 0 && 
          <div style={{ marginTop: '2%' }}>
            <p style={{ marginBottom: '3px' }} className='sub-title'>Additional Amenity Information</p>
            <p className='location-details-nodata'>NO DATA AVAILABLE</p>
          </div>}
        </div>
      </div>     
      <div className="pending-edits-body-margin">
        {pendingAmenitiesEdits?.length > 0 || pendingOtherAmenitiesEdits?.length > 0 ?  
          (<>
          <Accordion expanded={panel1Expanded} onChange={() => setPanel1Expanded(!panel1Expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <div className="edit-details-title">Amenities Edits ({pendingAmenitiesEdits?.length})</div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{paddingLeft: "16px"}}>
                <ApproveDenyAllToggle 
                  handleApproveDenyAll={handleAmenitiesApproveDeny} 
                  reviewedAll={props.reviewedAll.reviewedAll} 
                  approveDenyAll={props.reviewedAll.approvedAll}
                  handleReviewedAllCheck={handleReviewedAllAmenitiesCheck}
                />
              </div>
              <div className="location-pending-edits-table">
                <Table className="floating-table edits-table-content-align-top">
                  <thead>
                    <tr>
                      <th className="edits-table-th-first">Editor Username</th>                           
                      <th>Time Stamp ({props.userTimeZone})</th>
                      <th>Amenities</th>                      
                      <th style={{ textAlign: "center"}}>Review Edits</th>
                    </tr>
                  </thead>               
                  <tbody>
                    {pendingAmenitiesEdits?.length > 0 ?
                      (pendingAmenitiesEdits.map((item, index) =>
                      <tr key={item.editId}>
                        <td className="edits-table-td-first">{item.editAddedBy}</td>                                
                        <td>{props.convertTimeToLocal(item.editAddedDate)}</td>
                        <td>
                          <LocationAmenities
                            restrooms={item.restrooms}
                            overnightParking={item.overnightParking}
                            breakroom={item.breakroom}
                            vendingMachine={item.vendingMachine}
                            showers={item.showers}
                            wiFi={item.wiFi}
                          />
                        </td>
                        <td style={{ textAlign: "center"}}>
                          <EditLocationRadioButtons 
                              radioName={item.editId}
                              radioIndex={index}
                              radioOptions={props.toggleOptions}
                              radioChecked={item.approved !== undefined ? item.approved : null}
                              defaultValue={null}
                              useToggle={true}
                              onRadioChange={handleAmenitiesApproveDeny}
                          />
                        </td>
                      </tr>
                    )) : 
                    (<tr>
                      <td colSpan="4" style={{ textAlign: 'center', borderRadius: '8px' }}>No Amenity Edits to review.</td>
                    </tr>)}
                  </tbody>
                </Table>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={panel2Expanded} onChange={() => setPanel2Expanded(!panel2Expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <div className="edit-details-title">Additional Amenity Edits ({pendingOtherAmenitiesEdits?.length})</div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{paddingLeft: "16px"}}>
                <ApproveDenyAllToggle 
                  handleApproveDenyAll={handleOtherAmenitiesApproveDeny} 
                  reviewedAll={props.reviewedAllOther.reviewedAll} 
                  approveDenyAll={props.reviewedAllOther.approvedAll}
                  handleReviewedAllCheck={handleReviewedAllOtherAmenitiesCheck}
                />
              </div>
              <div className="location-pending-edits-table">
                <Table className="floating-table edits-table-content-align-top">
                  <thead>
                    <tr>
                      <th className="edits-table-th-first">Editor Username</th>                           
                      <th>Time Stamp ({props.userTimeZone})</th>
                      <th className="edited-content-type" style={{ textAlign: "center"}}>Add/Remove</th>
                      <th style={{ width: "200px"}}>Amenity Name</th>                      
                      <th style={{ textAlign: "center"}}>Review Edits</th>
                    </tr>
                  </thead>               
                  <tbody>
                    {pendingOtherAmenitiesEdits?.length > 0 ?
                      (pendingOtherAmenitiesEdits.map((item, index) =>
                      <tr key={item.editId}>
                        <td className="edits-table-td-first">{item.editAddedBy}</td>                                
                        <td>{props.convertTimeToLocal(item.editAddedDate)}</td>
                        <td className="edited-content-type" style={{ textAlign: "center"}}>{item.deleteFlag ? "Remove" : "Add"}</td>
                        <td className="edited-content" style={{ textTransform: "capitalize"}}>{item.amenityName.toLowerCase()}</td>
                        <td style={{ textAlign: "center"}}>
                          <EditLocationRadioButtons 
                              radioName={item.editId}
                              radioIndex={index}
                              radioOptions={props.toggleOptions}
                              radioChecked={item.approved !== undefined ? item.approved : null}
                              defaultValue={null}
                              useToggle={true}
                              onRadioChange={handleOtherAmenitiesApproveDeny}
                          />
                        </td>
                      </tr>
                    )) : 
                    (<tr>
                      <td colSpan="5" style={{ textAlign: 'center', borderRadius: '8px' }}>No Additional Amenity Edits to review.</td>
                    </tr>)}
                  </tbody>
                </Table>
              </div>
            </AccordionDetails>
        </Accordion>
        </>) :
        (<div className="edit-details-title">*** No Edits to review ***</div>)}       
      </div>
    </div>
  );
}