import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks';
import "./AddEntityModal.css";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditRelationshipModal from "./EditRelationshipModal";
import "./EditCompanyLocation.css";
import { Link } from "react-router-dom";

export default function EditCompanyLocation(props) {

    const api_queryString = "?companyId=" + props.companyId + "&locationId=" + props.location.id;
    const api_get_location_relationship_url = ENVIRONMENT === "development" ?
        API_BASE_URL + "/api/company/location" + api_queryString :
        API_BASE_URL + "/company/location" + api_queryString;
    let [{ data: locationRelationship, error, response }, fetchData] = useAxios(
        {
            url: api_get_location_relationship_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const [relationshipButtonText, setRelationshipButtonText] = useState("Set RelationShip");

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        setRelationshipButtonText(locationRelationship && locationRelationship.relationshipId > 0 ? "Edit Relationship" : "Set Relationship");
    }, [locationRelationship?.relationshipId, response?.status])

    const [modalShow, setModalShow] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");

    function openModal(e) {
        e.preventDefault();
        setModalShow(true);
    }

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
        if(message.includes("remove")){
            locationRelationship.relationshipId = 0;
        }
        else{
            fetchData();
        }
    }

    return (
        <>
            <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                <CheckCircleIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{successAlertMessage}</p>
                </div>
            </Alert>
            <div className="edit-location-container">
                <div className="edit-location-button-container">
                    <Button type="submit" className="outlined-button" onClick={(e) => { openModal(e) }}>
                        {relationshipButtonText}
                    </Button>
                    <EditRelationshipModal locationId={props.location.id} locationName={props.location.name} locationRelationship={locationRelationship} show={modalShow} onHide={() => setModalShow(false)} onSuccess={triggerSuccessAlert} />

                    <Link to={"/editlocation/" + props.location.id}>
                        <Button style={{ marginLeft: '20px' }} type="submit" className="outlined-button">
                            Edit Details
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );

}