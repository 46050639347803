import React, { useEffect, useState } from "react";
import useAxios from 'axios-hooks'
import { Alert, Button, Form, FormControl, InputGroup, Table } from "react-bootstrap";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import { onError } from "../libs/errorLib";
import AddUserModal from "./AddUserModal";
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ClearIcon from '@material-ui/icons/Clear';
import "../pages/InternalAdminPortal.css";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    smallblue: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.getContrastText('#215BA6'),
        backgroundColor: '#215BA6',
        display: 'inline-flex',
        margin: '2px 10px 0 5px',
        fontSize: '11px',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function UserList(props) {
    const classes = useStyles();
    const api_url = ENVIRONMENT === "development" ? "/api/user/mostrecent/50" : API_BASE_URL + "/user/mostrecent/50";
    const api_search_url = ENVIRONMENT === "development" ? "/api/user/search" : API_BASE_URL + "/user/search";
    const [{ data: users, loading, error }, fetchData] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const [getUsersMessage, setGetUsersMessage] = useState("...loading")
    const [modalShow, setModalShow] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [searchCriteria, setSearchCriteria] = useState({
        firstName: "",
        lastName: "",
        company: ""
    });

    const handleChange = (e) => {
        setSearchCriteria({
            ...searchCriteria,
            [e.target.id]: e.target.value
        });
    }

    useEffect(() => {
        if (searchCriteria.firstName.length === 0 && searchCriteria.lastName.length === 0 && searchCriteria.company.length === 0) {
            getData();
        }
    }, [searchCriteria]);

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value){
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
                setGetUsersMessage(message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error getting user list");
                setGetUsersMessage("Error getting user list");
            } else {
                // anything else
                triggerErrorAlert(true, "Error getting user list");
                setGetUsersMessage("Error getting user list");
            }
        }
    }, [error]);

    function getData() {
        try {
            fetchData({
                url: api_url
            });

        } catch (e) {
            onError("Error getting user list");
            setGetUsersMessage("Error getting user list");
        }
    }

    function handleSearch(e) {
        e.preventDefault();
        if (searchCriteria.firstName.length > 2 || searchCriteria.lastName.length > 2 || searchCriteria.company.length > 2) {
            fetchData({
                url: api_search_url + "?firstName=" + searchCriteria.firstName + "&lastName=" + searchCriteria.lastName + "&companyName=" + searchCriteria.company
            });
        }
        else {
            triggerErrorAlert(true, "A minimum of 3 characters is required in at least one search field");
        }
    }

    function handleClear(e) {
        e.preventDefault();
        setSearchCriteria({
            firstName: "",
            lastName: "",
            company: ""
        });
    }

    function handleTextboxClear(e, id) {
        e.preventDefault();
        setSearchCriteria({
            ...searchCriteria,
            [id]: ""
        });
    }

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
        getData();
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function openModal(e) {
        e.preventDefault();
        setModalShow(true);
    }
    return (
        <>
            <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                <CheckCircleIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{successAlertMessage}</p>
                </div>
            </Alert>
            <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                <ErrorIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{errorAlertMessage}</p>
                </div>
            </Alert>
            <div className="table-search-header">
                <Form inline>
                    <InputGroup className="search-box">
                        <FormControl id="firstName" placeholder="First Name" value={searchCriteria.firstName} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.firstName.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={(e) => handleTextboxClear(e, "firstName")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <InputGroup className="search-box">
                        <FormControl id="lastName" placeholder="Last Name" value={searchCriteria.lastName} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.lastName.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={(e) => handleTextboxClear(e, "lastName")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <InputGroup className="search-box">
                        <FormControl id="company" placeholder="Company Name" value={searchCriteria.company} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.company.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={(e) => handleTextboxClear(e, "company")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <Button type="submit" className="search-button" onClick={(e) => { handleSearch(e) }}>
                        <SearchIcon style={{ paddingRight: '5px' }} />Search
                    </Button>
                    <Button
                        type="submit"
                        variant="link"
                        className="clear-button"
                        onClick={(e) => { handleClear(e) }}
                        disabled={(searchCriteria.firstName.length === 0 && searchCriteria.lastName.length === 0 && searchCriteria.company.length === 0) ? true : false}
                    >
                        Clear Search
                    </Button>
                    <Button type="submit" className="modal-button" onClick={(e) => { openModal(e) }}>
                        <AddIcon style={{ paddingRight: '5px', marginTop: '-2px' }} />New User
                    </Button>
                    <AddUserModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={triggerSuccessAlert} />
                </Form>
            </div>
            <div className="list-body">
                <Table className="floating-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First, Last Name</th>
                            <th>Email Address</th>
                            <th>Company</th>
                            <th>Phone #</th>
                            <th>Job Title</th>
                            <th>User Type</th>
                            <th>Int/Ext/API</th>
                        </tr>
                    </thead>
                    {(loading || error) ? (
                        <tbody>
                            <tr>
                                <td colSpan="8" style={{ textAlign: 'center', borderRadius: '8px' }}><div>{getUsersMessage}</div></td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {users !== undefined ? (
                                users.map(item =>
                                    <tr key={`${item.id}`}>
                                        <td><MoreVertIcon /></td>
                                        <td>
                                            {item.initials.length > 0 ? (
                                                <Avatar className={classes.smallblue}>{item.initials.toUpperCase()}</Avatar>
                                            ) : (<></>)}

                                            {item.fullName}
                                        </td>
                                        <td>{item.email}</td>
                                        <td><Link to={"/company/" + item.companyId} target="_blank" rel="noopener noreferrer">{item.companyName}</Link></td>
                                        <td>{item.phoneNumber}</td>
                                        <td>{item.title}</td>
                                        <td>{item.userRoleId === 7 ? '' : item.externalUserTypeName}</td>
                                        <td>{item.userRoleId === 7 ? 'API' : item.isInternalUser ? 'Internal' : 'External'}</td>
                                    </tr>
                                )) : (<></>)}
                        </tbody>
                    )}
                </Table>
            </div>
        </>
    );
}