import React, { useEffect, useState } from "react";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../../constants/apiConstants.js';
import { onError } from "../../libs/errorLib";
import useAxios from 'axios-hooks';
import "./CompanyUsers.css";
import Popover from '@material-ui/core/Popover';
import { Table } from "react-bootstrap";
import PhoneIcon from '../../images/a-icon-phone.svg';
import EmailIcon from '../../images/a-icon-email.svg';
import CloseIcon from '../../images/a-icon-close.svg';
import FormatPhoneNumber from "../../components/elements/phone/FormatPhoneNumber";

export default function CompanyDetails(props) {

    const api_company_users_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/company/users/" + props.id : API_BASE_URL + "/company/users/" + props.id;
    const [{ data: users, loading, error }, fetchData]  = useAxios(
        {
            url: api_company_users_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        try {
            fetchData();            
        } catch (e) {
            onError("Error getting location details");
        }
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [openedPopoverId, setOpenedPopoverId] = useState(null);

    const handlePopoverOpen = (event, popoverId) => {
        setOpenedPopoverId(popoverId);
        setAnchorEl(event.target);
    };

    const handlePopoverClose = () => {
        setOpenedPopoverId(null);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
    users ? (
            <div className="users-container">
            {users.map(item =>
                <div className="user-item-container" key={item.id}>
                    <div className="user-item" aria-owns={open ? item.id : undefined}
                                               aria-haspopup="true"
                                               onMouseEnter={(e) => handlePopoverOpen(e, item.id)}
                                               onMouseLeave={handlePopoverClose}>
                        <div className="user-initials">{item.initials}</div>
                        <div className="user">
                            <div className="user-fullname">{item.fullName}</div>
                            <div className="user-title">{item.title}</div>
                        </div>
                        <Popover id={`${item.id}`} 
                                 transitionDuration={800}                           
                                 anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'center',
                                               }}
                                 transformOrigin={{
                                                   vertical: 'top',
                                                   horizontal: 'left',
                                                  }} 
                                 anchorEl={anchorEl}
                                 open={openedPopoverId === item.id}
                        >
                        <div className="user-info">
                            <div className="user-fullname">{item.fullName}</div>
                            <div className="user-title">{item.title}</div>
                            <div className="user-info-close" onClick={() => handlePopoverClose()}><img src={CloseIcon} alt="" /></div>
                            <Table className="user-info-table">
                            <tbody>
                                <tr style={{ height: 0 }}>                    
                                    <td>
                                        <p className='user-contact-label'>Contact</p>
                                        <p className="user-contact-content user-email"><img src={EmailIcon} alt="" />{item.email}</p>
                                        {item.phoneNumber && (                                                             
                                                                <p className="user-contact-content">
                                                                    <img src={PhoneIcon} alt="" />
                                                                    <FormatPhoneNumber phoneNumber={item.phoneNumber}></FormatPhoneNumber>
                                                                </p>
                                                             )}
                                    </td>
                                </tr>
                                <tr style={{ height: 0 }}>                    
                                    <td>
                                        <p className='user-info-label'>USER TYPE</p>
                                        <p className="user-info-content">{item.externalUserTypeName} </p>
                                    </td>
                                </tr>
                                <tr style={{ height: 0 }}>                    
                                    <td>
                                        <p className='user-info-label'>ROLE & PERMISSION</p>
                                        <p className="user-info-content">{item.roleName} </p>
                                    </td>
                                </tr>
                            </tbody>
                            </Table>
                        </div>
                        </Popover>
                    </div>
                </div>               
                )}
            </div>
      ):<></>
);
}
