import React, { useEffect, useRef, useState } from "react";
import useAxios from 'axios-hooks'
import { useSessionContext } from "../libs/sessionLib";
import { Alert, Button, Form, FormControl, InputGroup, Table } from "react-bootstrap";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import { onError } from "../libs/errorLib";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import "../pages/InternalAdminPortal.css";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    smallblue: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.getContrastText('#215BA6'),
        backgroundColor: '#215BA6',
        display: 'inline-flex',
        margin: '2px 10px 0 5px',
        fontSize: '11px',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function PendingUserList(props) {
    const classes = useStyles();
    const { session } = useSessionContext();    
    const api_url = ENVIRONMENT === "development" ? "/api/user/new-user-requests" : API_BASE_URL + "/user/new-user-requests";
    const api_search_url = ENVIRONMENT === "development" ? "/api/user/search-new-user-requests" : API_BASE_URL + "/user/search-new-user-requests";
    const [{ data: users, loading, error }, fetchData] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const [getUsersMessage, setGetUsersMessage] = useState("...loading");
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [companyDict, setCompanyDict] = useState({});

    const [searchCriteria, setSearchCriteria] = useState({
        firstName: "",
        lastName: "",
        company: ""
    });

    const approve_deny_user_api_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/approve-deny-user-requests" : API_BASE_URL + "/user/approve-deny-user-requests";
    const [{ data, loading: approveDenyLoading, error: approveDenyError, response }, approveDenyNewUser] = useAxios(
        {
            url: approve_deny_user_api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if(users) props.renderUserRequestCount(users.length);
    }, [users]);

    function getData() {
        try {
            fetchData({
                url: api_url
            });

        } catch (e) {
            onError("Error getting pending user list");
            setGetUsersMessage("Error getting pending user list");
        }
    };

    const handleChange = (e) => {
        setSearchCriteria({
            ...searchCriteria,
            [e.target.id]: e.target.value
        });
    }

    useEffect(() => {
        if (searchCriteria.firstName.length === 0 && searchCriteria.lastName.length === 0 && searchCriteria.company.length === 0) {
            getData();
        }
    }, [searchCriteria]);

    function handleSearch(e) {
        e.preventDefault();
        if (searchCriteria.firstName.length > 2 || searchCriteria.lastName.length > 2 || searchCriteria.company.length > 2) {
            fetchData({
                url: api_search_url + "?firstName=" + searchCriteria.firstName + "&lastName=" + searchCriteria.lastName + "&companyName=" + searchCriteria.company
            });
        }
        else {
            triggerErrorAlert(true, "A minimum of 3 characters is required in at least one search field");
        }
    };

    const filterBy = () => true;
    const [compOptions, setCompOptions] = useState([]);
    const api_company_search_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/company/search" : API_BASE_URL + "/company/search";
    const [{ data: companies, loading: compLoading, error: compError, response: compResponse }, fetchCompanies] = useAxios(
        {
            url: api_company_search_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    useEffect(() => {
        if (companies !== undefined) {
            const options = companies.map((i) => ({
                id: i.id,
                companyName: i.companyName,
            }));
            setCompOptions(options);
        }
    }, [companies]);
    const [companySearchError, setCompanySearchError] = useState("");
    async function handleCompanySearch(query) {
        try {
            setCompanySearchError("");
            if (query.length > 2) {
                await fetchCompanies({
                    url: api_company_search_url + "?name=" + query
                });
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status !== 404) {
                    setCompanySearchError("Error getting companies");
                    console.log(err.response)
                }
            }
            else {
                setCompanySearchError("Error getting companies");
                console.log(err)
            }
        }
    }

    useEffect(() => {
        if (compError) {
            if (compError.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (compError.response.data) {
                    if (compError.response.data.value) {
                        message = compError.response.data.value
                    }
                    else {
                        message = compError.response.data
                    }
                }
                else {
                    message = "Error getting company list"
                }
                setCompanySearchError(message);
                triggerErrorAlert(true, message);
            } else if (compError.request) {
                // client never received a response, or request never left
                setCompanySearchError("Error getting company list");
                console.log(compError.request)
            } else {
                // anything else
                setCompanySearchError("Error getting company list");
                console.log(compError)
            }
        }
    }, [compError]);

    const companyTypeaheadRef = useRef(null);
    function handleCompanyChange(e, userId) {
        setCompanySearchError("");
        if (e[0] !== undefined) {
            setCompanyDict(prevState => ({
                ...prevState,
                [userId]: e[0].id
            }));
        } else {
            setCompanyDict(prevState => ({
                ...prevState,
                [userId]: ""
            }));
        }
    }

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
                setGetUsersMessage(message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error getting pending user list");
                setGetUsersMessage("Error getting pending user list");
            } else {
                // anything else
                triggerErrorAlert(true, "Error getting pending user list");
                setGetUsersMessage("Error getting pending user list");
            }
        }
    }, [error]);

    async function approveDenyUser(id, approved) {
        setIsSubmitting(true);
        try {
            if (users) {
                var user = users.find(x => x.id === id);

                if (user) {
                    var canContinue = false;

                    if (approved) {
                        if (id in companyDict && companyDict[id] !== undefined) {
                            canContinue = true;
                        }
                        else {
                            triggerErrorAlert(true, "Company is required for approving user.");
                            setIsSubmitting(false);
                        }
                    }
                    else {
                        canContinue = true;
                    }
                    if (canContinue) {
                        var response = await approveDenyNewUser({
                            data: {
                                userDetails: {
                                    createdby: (session !== undefined ? session.id : ""),
                                    modifiedby: (session !== undefined ? session.id : ""),
                                    companyId: (id in companyDict && companyDict[id] !== undefined) ? companyDict[id] : null,
                                    email: user.email,
                                    firstname: user.firstName,
                                    lastname: user.lastName,
                                    phoneNumber: user.phoneNumber,
                                    title: user.title,
                                    password: "",
                                    username: user.email,
                                    isinternaluser: false,
                                    userroleid: 6,
                                    externalusertypeid: user.externalUserTypeId,
                                    integrationappname: "",
                                    integrationuserid: ""
                                },
                                newUserRequestId: user.id,
                                approved: approved
                            }
                        })
                        .then(_ => {
                            setIsSubmitting(false);
                            triggerSuccessAlert(true, "Successfully " + (approved ? "Approved" : "Denied") + " " + user.firstName + " " + user.lastName);
                        });
                    }
                }
            }
        }
        catch (err) {
            triggerErrorAlert(true, "Error " + (approved ? "Approving" : "Denying") + " user");
            setIsSubmitting(false);
        }
    }

    function handleClear(e) {
        e.preventDefault();
        setSearchCriteria({
            firstName: "",
            lastName: "",
            company: ""
        });
    }

    function handleTextboxClear(e, id) {
        e.preventDefault();
        setSearchCriteria({
            ...searchCriteria,
            [id]: ""
        });
    }

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
        getData();
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    const [companySectionVisible, setCompanySectionVisible] = useState({});
    function handleCompanyButtonClick(id) {
        var element = document.getElementById(id);
        if (element) {
            element.style.display = (element.style.display != 'none' ? 'none' : 'revert');
        }

        if (id in companySectionVisible && companySectionVisible[id] === true) {
            setCompanySectionVisible(prevState => ({
                ...prevState,
                [id]: false
            }));
        }
        else {
            setCompanySectionVisible(prevState => ({
                ...prevState,
                [id]: true
            }));
        }
    }
    return (
        <>
            <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                <CheckCircleIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{successAlertMessage}</p>
                </div>
            </Alert>
            <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                <ErrorIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{errorAlertMessage}</p>
                </div>
            </Alert>
            <div className="table-search-header">
                <Form inline>
                    <InputGroup className="search-box">
                        <FormControl id="firstName" placeholder="First Name" value={searchCriteria.firstName} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.firstName.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={(e) => handleTextboxClear(e, "firstName")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <InputGroup className="search-box">
                        <FormControl id="lastName" placeholder="Last Name" value={searchCriteria.lastName} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.lastName.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={(e) => handleTextboxClear(e, "lastName")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <InputGroup className="search-box">
                        <FormControl id="company" placeholder="Company Name" value={searchCriteria.company} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.company.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={(e) => handleTextboxClear(e, "company")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <Button type="submit" className="search-button" onClick={(e) => { handleSearch(e) }}>
                        <SearchIcon style={{ paddingRight: '5px' }} />Search
                    </Button>
                    <Button
                        type="submit"
                        variant="link"
                        className="clear-button"
                        onClick={(e) => { handleClear(e) }}
                        disabled={(searchCriteria.firstName.length === 0 && searchCriteria.lastName.length === 0 && searchCriteria.company.length === 0) ? true : false}
                    >
                        Clear Search
                    </Button>
                </Form>
            </div>
            <div className="list-body">
                <Table className="floating-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First, Last Name</th>
                            <th>Email Address</th>
                            <th style={{ textAlign: "center" }}>Company</th>
                            <th>Phone #</th>
                            <th>Job Title</th>
                            <th>User Type</th>
                            <th>Approve/Deny</th>
                        </tr>
                    </thead>
                    {(loading || error) ? (
                        <tbody>
                            <tr>
                                <td colSpan="8" style={{ textAlign: 'center', borderRadius: '8px' }}><div>{getUsersMessage}</div></td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {users !== undefined ? (
                                users.map(item =>
                                    <>
                                        <tr key={`${item.id}-user`}>
                                            <td><MoreVertIcon /></td>
                                            <td>
                                                {item.initials.length > 0 ? (
                                                    <Avatar className={classes.smallblue}>{item.initials.toUpperCase()}</Avatar>
                                                ) : (<></>)}

                                                {item.fullName}
                                            </td>
                                            <td>{item.email}</td>
                                            <td style={{ textAlign: "center" }}>
                                                <Button className="toggle-button" onClick={() => handleCompanyButtonClick(`${item.id}`)}>
                                                    Company Details
                                                    {(item.id in companySectionVisible && companySectionVisible[item.id] === true) ? (
                                                        <ArrowDropUpIcon />
                                                    ) : (
                                                        <ArrowDropDownIcon />
                                                    )}
                                                </Button>
                                            </td>
                                            <td>{item.phoneNumber}</td>
                                            <td>{item.title}</td>
                                            <td>{item.userRoleId === 7 ? '' : item.externalUserTypeName}</td>
                                            <td style={{ paddingLeft: "0", paddingRight: "0" }}>
                                                <Button className="small-action-button approve"
                                                    disabled={(!(item.id in companyDict && companyDict[item.id] !== undefined && companyDict[item.id].length > 0) || isSubmitting)}
                                                    onClick={() => approveDenyUser(item.id, true)}
                                                >
                                                    Approve
                                                </Button>
                                                <Button className="small-action-button deny"
                                                    disabled={(!(item.id in companySectionVisible && companySectionVisible[item.id]) || isSubmitting)}
                                                    onClick={() => approveDenyUser(item.id, false)}
                                                >
                                                    Deny
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr key={`${item.id}-company`} id={`${item.id}-company`} style={(item.id in companySectionVisible && companySectionVisible[item.id] === true) ? { display: "revert" } : { display: "none" }}>
                                            <td colSpan="8">
                                                <div className="d-flex flex-row justify-content-around">
                                                    <div>
                                                        <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>Company Name</div>
                                                        <div>{item.companyName}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{ fontWeight: "bold" }}>Assign Company</div>
                                                        <div className="async-floating-label" style={{ display: "block", height: "40px" }}>
                                                            <AsyncTypeahead
                                                                //className={errorClasses.companyId}
                                                                filterBy={filterBy}
                                                                id="companyId"
                                                                isLoading={compLoading}
                                                                labelKey="companyName"
                                                                minLength={3}
                                                                onSearch={handleCompanySearch}
                                                                onChange={(e) => handleCompanyChange(e, item.id)}
                                                                options={compOptions}
                                                                placeholder="Search Company"
                                                                useCache={false}
                                                                //onBlur={(e) => handleCompanyBlur(e)}
                                                                ref={companyTypeaheadRef}
                                                            />
                                                            {companySearchError !== undefined && companySearchError.length > 0 ? (
                                                                <Form.Text className="error" style={{ marginTop: '-25px' }}>{companySearchError}</Form.Text>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>Company Website</div>
                                                        <div>{item.companyWebsite}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>Company City</div>
                                                        <div>{item.companyCity}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>Company State</div>
                                                        <div>{item.companyStateCode}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )) : (<></>)}
                        </tbody>
                    )}
                </Table>
            </div>
        </>
    );
}