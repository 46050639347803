import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import "./UpdatePassword.css"
import FloatLabelTextBox from "../components/FloatLabelTextBox";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks'
import { useSessionContext } from "../libs/sessionLib";
import ErrorIcon from '@material-ui/icons/Error';
import PasswordChecklist from "../components/elements/PasswordChecklist";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from "react-router-dom";

export default function UpdatePassword(props) {
    const history = useHistory();
    const { session } = useSessionContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [errorClasses, setErrorClasses] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });
    const [updatePassword, setUpdatePassword] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });

    const api_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/update-password" : API_BASE_URL + "/user/update-password";
    const [{ data, loading, error, response }, postUpdatePassword] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    function handleChange(e) {
        setUpdatePassword({
            ...updatePassword,
            [e.target.name]: e.target.value,
        })
    }
    function handleClose() {
        clearUpdatePassword();
        clearErrors();
        history.push('/');
    }

    const [passwordValid, setPasswordValid] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);
        var errors = {};
        var missingFields = false;
        var oldAndNewPasswordsMatch = false;
        var confirmPasswordDoesntMatch = false;

        if (updatePassword.oldPassword.length === 0) {
            errors["oldPassword"] = "error";
            missingFields = true;
        }

        if (updatePassword.newPassword.length === 0) {
            errors["newPassword"] = "error";
            missingFields = true;
        }

        if (updatePassword.confirmNewPassword.length === 0) {
            errors["confirmNewPassword"] = "error";
            missingFields = true;
        }

        if (updatePassword.oldPassword === updatePassword.newPassword) {
            errors["newPassword"] = "error"; //new pass same as old
            oldAndNewPasswordsMatch = true;
        }

        if (updatePassword.newPassword !== updatePassword.confirmNewPassword) {
            errors["confirmNewPassword"] = "error"; //confirm pass doesnt match new pass
            confirmPasswordDoesntMatch = true;
        }

        setErrorClasses({
            oldPassword: ("oldPassword" in errors ? errors["oldPassword"] : ""),
            newPassword: ("newPassword" in errors ? errors["newPassword"] : ""),
            confirmNewPassword: ("confirmNewPassword" in errors ? errors["confirmNewPassword"] : "")
        });

        if (passwordValid) {
            if (!missingFields && !oldAndNewPasswordsMatch && !confirmPasswordDoesntMatch) {
                try {
                    await postUpdatePassword({
                        data: {
                            ...updatePassword,
                            userId: (session !== undefined ? session.id : ""),
                        }
                    }).then(_ => { setIsSubmitting(false); });
                    clearUpdatePassword();
                    clearErrors();
                    triggerSuccessAlert(true, "Password has been updated.");
                } catch (err) {
                    setIsSubmitting(false);
                }
            } else {
                if (missingFields) {
                    triggerErrorAlert(true, "Required fields missing.");
                }

                if (oldAndNewPasswordsMatch) {
                    triggerErrorAlert(true, "New password is the same as current password.");
                }

                if (confirmPasswordDoesntMatch) {
                    triggerErrorAlert(true, "Confirm password and new password do not match.");
                }
                setIsSubmitting(false);
            }
        }
    }

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }

                if (message.toString().toUpperCase() === "INCORRECT PASSWORD.") {
                    message = "That password does not match our records. Please try again or contact Location.Intel@solera.com for further assistance."
                }
                triggerErrorAlert(true, message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error updating password");
            } else {
                // anything else
                triggerErrorAlert(true, "Error updating password");
            }
        }
    }, [error]);

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
    }

    function clearUpdatePassword() {
        setUpdatePassword({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        });
    }

    function clearErrors() {
        setErrorClasses({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        });
    }

    return (
        <div style={{ textAlign: 'center'}}>
            <div className="update-password-container">
                <div className="update-password-header">
                    Update Password
                </div>
                <div className="update-password-body">
                    <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                        <CheckCircleIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Success</Alert.Heading>
                            <p>{successAlertMessage}</p>
                        </div>
                    </Alert>
                    <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                        <ErrorIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>{errorAlertMessage}</p>
                        </div>
                    </Alert>
                    <Form onSubmit={handleSubmit} style={{ padding: '0' }}>
                        <Row>
                            <Form.Group className="large-form-group">
                                <FloatLabelTextBox
                                    inputLabel="OLD PASSWORD"
                                    inputAutoFocus="true"
                                    inputType="password"
                                    inputName="oldPassword"
                                    inputPlaceholder="Old Password"
                                    inputValue={updatePassword.oldPassword}
                                    inputClassName={errorClasses.oldPassword}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="large-form-group">
                                <FloatLabelTextBox
                                    inputLabel="NEW PASSWORD"
                                    inputAutoFocus="true"
                                    inputType="password"
                                    inputName="newPassword"
                                    inputPlaceholder="New Password"
                                    inputValue={updatePassword.newPassword}
                                    inputClassName={errorClasses.newPassword}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="large-form-group">
                                <FloatLabelTextBox
                                    inputLabel="CONFIRM NEW PASSWORD"
                                    inputAutoFocus="true"
                                    inputType="password"
                                    inputName="confirmNewPassword"
                                    inputPlaceholder="Confirm New Password"
                                    inputValue={updatePassword.confirmNewPassword}
                                    inputClassName={errorClasses.confirmNewPassword}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                            minLength={8}
                            value={updatePassword.newPassword}
                            valueAgain={updatePassword.confirmNewPassword}
                            passwordValid={setPasswordValid}
                            messages={{
                                minLength: "Password must contain at least 8 characters.",
                                specialChar: "Password must contain at least 1 special character.",
                                number: "Password must contain at least 1 number.",
                                capital: "Password must contain at least 1 upper case letter.",
                                lowercase: "Password must contain at least 1 lower case letter.",
                                match: "Passwords match.",
                            }}
                        />
                    </Form>
                </div>
                <div className="update-password-button-container">
                    <Button className="btn-cancel" onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
                    <Button className="btn-submit" onClick={(e) => { handleSubmit(e) }} type="submit" disabled={(!passwordValid || isSubmitting)}>Save</Button>
                </div>
            </div>
        </div>
    );
}