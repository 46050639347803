import React from "react";
import ProceduresIcon from '../../images/location-details/Procedures.svg';
import "./LocationDetailsComponents.css";

export default function LocationProcedures(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img src={ProceduresIcon} alt="" /></div>
            <div className="location-details-body"><p style={{ paddingBottom: "10px" }} className='location-details-label'>Procedures</p>
                {props.procedures ?
                    (
                        <>                            
                            <div style={{ paddingBottom: "15px" }}>
                                <p className='sub-title'>Report to Guard Shack</p>
                                {props.procedures.reportToGuardShack === null ? (<p>Unknown</p>) :
                                 props.procedures.reportToGuardShack ? (<p>Yes</p>) : (<p>No</p>)
                                }
                            </div>                           
                            <div style={{ paddingBottom: "15px" }}>
                                <p className='sub-title'>Driver Load/Unload</p>
                                {props.procedures.driverUnload === null ? (<p>Unknown</p>) :
                                 props.procedures.driverUnload ? (<p>Yes</p>) : (<p>No</p>)
                                }
                            </div>                         
                            <div>
                                <p className='sub-title'>Dock Assist</p>
                                {props.procedures.dockAssist === null ? (<p>Unknown</p>) :
                                 props.procedures.dockAssist ? (<p>Yes</p>) : (<p>No</p>)
                                }
                            </div>
                            {(props.procedures.drivingDirections && props.procedures.drivingDirections.length > 0) &&
                                <div className="sub-container" style={{ lineHeight: 'normal' }}>
                                    <p className='sub-title'>Additional Driver Directions</p>
                                    <p className="location-details-word-wrap" style={{ marginTop: "3%" }}>{props.procedures.drivingDirections}</p>
                                </div>
                            }
                        </>
                    ) : (<p className='location-details-nodata'>NO DATA AVAILABLE</p>)
                }
            </div>
        </div>
    )
}