import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useAxios from 'axios-hooks'
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import LoginImage from '../images/Login-Page-Image.png';
import FloatLabelTextBox from "../components/FloatLabelTextBox.js"
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import { setSessionCookie, useSessionContext } from "../libs/sessionLib";
import { useAmplitude, identifyInst } from '../services/Amplitude';
import AddNewUserRequestModal from "../components/NewUserRequestModal";
import { Alert, Button, Form } from "react-bootstrap";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ForgotPasswordModal from "../components/ForgotPasswordModal";

export default function Login(props) {
    const history = useHistory();
    const { setSession } = useSessionContext();
    const [state, setState] = useState({
        email: "",
        password: "",
    });
    const from = props.location.state || { from: { pathname: '/' } };
    const pathname = from.from.pathname;

    const [errorClass, setErrorClass] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [modalShow, setModalShow] = useState(false);
    const [forgotPasswordModalShow, setForgotPasswordModalShow] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");

    const api_url = ENVIRONMENT === "development" ? "/api/user/login" : API_BASE_URL + "/user/login";
    const [{ loading, error }, postData] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }

    const amplitude = useAmplitude();

    function setAmplitudeUser(user) {
        amplitude.setUserId(user.email);
        identifyInst.setOnce("User Id", user.id).setOnce("User Name", user.userName).setOnce("External User", (!user.isInternalUser ? "Yes" : "No"));
        identifyInst.set("Company Name", user.companyName);
        amplitude.identify(identifyInst);
        amplitude.logEvent("Login");
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const payload = {
            "email": state.email,
            "password": state.password,
        }

        if (state.email.length > 0 && state.password.length > 0) {
            try {
                const resp = await postData({
                    data: {
                        ...payload
                    }
                });
                setSessionCookie(resp.data);
                setSession(resp.data);
                // Set Amplitude UserId and Properties
                setAmplitudeUser(resp.data);
                history.push(pathname);
            } catch (e) {
                console.log(e)

                let message = e.toString();

                // Auth errors
                if ((e instanceof Error) && e.message && e.response?.status === 400) {
                    message = "The login information entered does not match our records. Please double-check and try again";
                } else {
                    message = "Something went wrong. Please try again later."
                }
                setErrorClass("error");
                setErrorMessage(message);
            }
        }
        else {
            setErrorClass("error");
            setErrorMessage("Email and password are required.");
        }
    };

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function openModal(e) {
        e.preventDefault();
        setModalShow(true);
    }

    function openForgotPasswordModal(e) {
        e.preventDefault();
        setForgotPasswordModalShow(true);
    }

    return (
        <div className="Login-Background">
            <div className="Login-Container">
                <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                    <CheckCircleIcon />
                    <div className="floating-alert-body">
                        <Alert.Heading>Success</Alert.Heading>
                        <p>{successAlertMessage}</p>
                    </div>
                </Alert>
                <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                    <ErrorIcon />
                    <div className="floating-alert-body">
                        <Alert.Heading>Error</Alert.Heading>
                        <p>{errorAlertMessage}</p>
                    </div>
                </Alert>
                <div className="Login">
                    <p className="Login-Header">Login</p>
                    <div className="Login-Form">
                        <Form data-testid="login-form" onSubmit={handleSubmit}>
                            <Form.Group size="lg">
                                <FloatLabelTextBox
                                    inputLabel="EMAIL"
                                    inputAutoFocus="true"
                                    inputType="email"
                                    inputName="email"
                                    inputPlaceholder="Email"
                                    inputValue={state.email}
                                    inputClassName={errorClass}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Form.Group size="lg" style={{ marginBottom: "auto" }}>
                                <FloatLabelTextBox
                                    inputLabel="PASSWORD"
                                    inputAutoFocus="false"
                                    inputType="password"
                                    inputName="password"
                                    inputPlaceholder="Password"
                                    inputValue={state.password}
                                    inputClassName={errorClass}
                                    inputHelperText={errorMessage}
                                    handleChangeProps={handleChange}
                                />
                                <div>
                                    <Button variant="link" type="submit" onClick={(e) => { openForgotPasswordModal(e) }}>
                                        Forgot Password?
                                    </Button>
                                    <ForgotPasswordModal show={forgotPasswordModalShow} onHide={() => setForgotPasswordModalShow(false)} onSuccess={triggerSuccessAlert}></ForgotPasswordModal>
                                </div>
                            </Form.Group>
                            <LoaderButton
                                block
                                size="lg"
                                type="submit"
                                isLoading={loading}>
                                Log In
                            </LoaderButton>
                        </Form>
                        <div className="new-member-request-container">
                            Not a member?
                            <Button variant="link" type="submit" onClick={(e) => { openModal(e) }}>
                                Get Started Here
                            </Button>
                            <AddNewUserRequestModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={triggerSuccessAlert} />
                        </div>
                    </div>
                </div>
                <div className="Login-Image">
                    <img src={LoginImage} alt="" />
                </div>
            </div>
        </div>
    );
}