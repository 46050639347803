import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import GeofenceMap from "../../components/GeofenceMap";
import EditLocationRadioButtons from "../../components/editlocationdetails/EditLocationRadioButtons";
import ApproveDenyAllToggle from "./ApproveDenyAllToggle";

export default function PendingGeofenceEdits(props) {
  const pendingEdits = useMemo(() => { return props.pendingEdits }, [props]);
  const nullPoint = "{\"type\": \"Polygon\", \"coordinates\": [[[0, 0], [0, 0.000000001], [0.000000001, 0], [0, 0]]]}";

  function handleApproveDeny(target){
    props.handleApproveDeny(target, "geoFence1");
  }

  function handleReviewedAllCheck(checked){
    props.handleReviewedAllCheck(checked, "geoFence1");
  }
  
  return (
    <div className="edit-details-body">
      <div className="edit-details-title">Original Geofence</div>
      {props.originalGeofence && props.originalGeofence !== nullPoint ? 
          <div className="original-data-body">
            <GeofenceMap geoFence1={props.originalGeofence} height="270px" width="40%" zoom={5} />
          </div> 
        : <p className='location-details-nodata'>NO DATA AVAILABLE</p>}     
      <div className="pending-edits-body-margin">
        {pendingEdits?.length > 0 ? (
            <>
            <div className="edit-details-title">Geofence Edits</div>
            <ApproveDenyAllToggle 
              handleApproveDenyAll={handleApproveDeny} 
              reviewedAll={props.reviewedAll.reviewedAll} 
              approveDenyAll={props.reviewedAll.approvedAll}
              handleReviewedAllCheck={handleReviewedAllCheck}
            />
            <div className="location-pending-edits-table">
                <Table className="floating-table edits-table-content-align-top">
                    <thead>
                        <tr>
                            <th className="edits-table-th-first">Editor Username</th>                           
                            <th>Time Stamp ({props.userTimeZone})</th>
                            <th>Geofence</th>                      
                            <th style={{ textAlign: "center", paddingRight: '4%'}}>Review Edits</th>
                        </tr>
                    </thead>               
                    <tbody>
                            {pendingEdits.map((item, index) =>
                            <tr key={item.editId}>
                                <td className="edits-table-td-first">{item.editAddedBy}</td>                                
                                <td>{props.convertTimeToLocal(item.editAddedDate)}</td>
                                <td style={{ width: "370px" }}>
                                    <GeofenceMap geoFence1={item.geoFence1} height="270px" zoom={5} />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                <EditLocationRadioButtons 
                                    radioName={item.editId}
                                    radioIndex={index}
                                    radioOptions={props.toggleOptions}
                                    radioChecked={item.approved !== undefined ? item.approved : null}
                                    defaultValue={null}
                                    useToggle={true}
                                    onRadioChange={handleApproveDeny}
                                />
                                </td>
                            </tr>
                        )}
                    </tbody>
                    </Table>
                </div>
            </>
        ) :
        (
            <div className="edit-details-title">
                *** No Edits to review ***
            </div>
        )}       
      </div>
    </div>
  );
}