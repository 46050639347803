import React from "react";
import Form from "react-bootstrap/Form";
import "./FixedLabel.css";

export default function FixedLabelTextBox(props) {
    return (
        <>
            <div className="fixed-label">
                <label className="textbox-fixed-label" htmlFor={props.inputName}>{props.inputLabel}</label>
                <Form.Control
                    data-testid={props.inputName}
                    id={props.inputName}
                    autoFocus={props.inputAutofocus}
                    type={props.inputType}
                    placeholder={props.inputPlaceholder}
                    value={props.inputValue}
                    name={props.inputName}
                    onChange={(e) => props.handleChangeProps(e)}
                    className={props.inputClassName}
                    disabled={props.inputDisabled}
                    rows={props.inputRows}
                    as={props.inputAs}
                    spellCheck={props.inputSpellCheck}
                />

            </div>
            {props.inputHelperText !== undefined && props.inputHelperText.length > 0 ? (
                <Form.Text className={props.inputClassName}>{props.inputHelperText}</Form.Text>
            ) : (
                <></>
            )}
        </>
    );
}