import React, { useEffect, useRef, useCallback } from "react";
import { ReactBingmaps } from 'react-bingmaps';
export default function BingMap(props) {
    const bingMapsKey = "AlQjYAdxVOgQXx9UAF0l8cNcRCniaeIwZvm5C3X9JtlxbdW1-TZABlh6yFvRIgQD";

    const mapContainer = useRef(null);
  const map = useRef(null);

  // removes pushpins
  function removePushpins(map, Maps) {
    for (var i = map.entities.getLength() - 1; i >= 0; i--) {
      var pushpin = map.entities.get(i);
      if (pushpin instanceof Maps.Pushpin) {
        map.entities.removeAt(i);
      }
    }
  }

  // add pushpins with infoboxes
  const addPushpinsWithInfoboxes = useCallback(
    (pushPinsToAdd, infobox, map, Maps) => {
      removePushpins(map, Maps);
      pushPinsToAdd.forEach((pushPin) => {
        if (pushPin === null) {
          return;
        }
        const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
        newPin.metadata = pushPin.metadata;
        Maps.Events.addHandler(newPin, "mouseover", (e) => {
          infobox.setOptions({
            location: e.target.getLocation(),
            title: e.target.metadata.title,
            description: e.target.metadata.description,
            visible: true,
            htmlContent: pushPin.infoboxHtml,
          });
        });
        Maps.Events.addHandler(newPin, "mouseout", (e) => {
          infobox.setOptions({
            visible: false,
          });
        });
        map.entities.push(newPin);
      });
    },
    []
  );

  // add pushpins
    function addPushpins(pushPinsToAdd, map, Maps) {
        var locations = [];
        map.entities.clear();
        pushPinsToAdd.forEach((pushPin) => {
            if (pushPin === null) {
                return;
            }

            if (pushPin.latitude && pushPin.latitude !== null && pushPin.longitude && pushPin.longitude !== null) {
                const center = new Maps.Location(pushPin.latitude, pushPin.longitude);
                const newPin = new Maps.Pushpin(center, {
                    text: (pushPin.pinLabel !== null && pushPin.pinLabel !== undefined) && pushPin.pinLabel.toString(),
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20.417" height="29.166" viewBox="0 0 20.417 29.166"><defs><style>.a{fill:#215ba6;}</style></defs><path class="a" d="M10.208,0A10.2,10.2,0,0,0,0,10.208c0,7.656,10.208,18.958,10.208,18.958s10.208-11.3,10.208-18.958A10.2,10.2,0,0,0,10.208,0Z"/></svg>',
                    anchor: new Maps.Point(10, 29)
                });
                map.entities.push(newPin);
               locations.push(center);

              if (locations.length === 1 && ((pushPin.pinLabel === null || pushPin.pinLabel === undefined))){
                map.setView({ center: center, zoom: 15 });
                return;
              }

              if (locations.length > 0){
                var rect = Maps.LocationRect.fromLocations(locations);
                map.setView({ bounds: rect, padding: 80 });
              }
            }
        });

    }

  // set view options
  function setMapViewOptions(map, viewOptions, Maps) {
    const options = { ...viewOptions };
    if (viewOptions.mapTypeId) {
      options.mapTypeId = Maps.MapTypeId[viewOptions.mapTypeId];
    }
    if (viewOptions.hideRoadLabels) {
      options.labelOverlay = Maps.LabelOverlay.hidden;
    }
    map.setView(options);
  }

  // set map options
  function setMapOptions(map, mapOptions, Maps) {
    const options = { ...mapOptions };

    // some map options require values from the Maps class
    // these conditional statements handle those cases
    if (mapOptions.navigationBarMode) {
      options.navigationBarMode =
        Maps.NavigationBarMode[mapOptions.navigationBarMode];
    }
    if (mapOptions.navigationBarOrientation) {
      options.navigationBarOrientation =
        Maps.NavigationBarOrientation[mapOptions.navigationBarOrientation];
    }
    if (mapOptions.supportedMapTypes) {
      options.supportedMapTypes = mapOptions.supportedMapTypes.map(
        (type) => Maps.MapTypeId[type]
      );
    }
    map.setOptions(options);
  }

  // make map, set options, add pins
  const makeMap = useCallback(() => {
    const { Maps } = window.Microsoft;

    // only make a new map if one doesn't already exist
    if (!map.current) {
      map.current = new Maps.Map(mapContainer.current, {
        credentials: bingMapsKey,
        //mapTypeId: Maps.MapTypeId.grayscale,
    //supportedMapTypes: [Maps.MapTypeId.road, Maps.MapTypeId.aerial, Maps.MapTypeId.grayscale]
      });
    }
    // set viewOptions, if any
    // if (viewOptions) {
    //   setMapViewOptions(map.current, viewOptions, Maps);
    // }

    // // set mapOptions, if any
    // if (mapOptions) {
    //   setMapOptions(map.current, mapOptions, Maps);
    // }

    // add push pins, if any
    if (props.pins) {
      addPushpins(props.pins, map.current, Maps);
    }

    // add infoboxes, if any
    // if (pushPinsWithInfoboxes) {
    //   const infobox = new Maps.Infobox(map.current.getCenter(), {
    //     visible: false,
    //   });
    //   infobox.setMap(map.current);
    //   addPushpinsWithInfoboxes(
    //     pushPinsWithInfoboxes,
    //     infobox,
    //     map.current,
    //     Maps
    //   );
    // }
    //onMapReady && onMapReady();
  }, [
    addPushpinsWithInfoboxes,
    bingMapsKey,
    //mapOptions,
    //onMapReady,
    props.pins,
    //pushPinsWithInfoboxes,
    //viewOptions,
  ]);

  useEffect(() => {
    if (window.Microsoft && window.Microsoft.Maps) {
      makeMap();
    } else {
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("type", "text/javascript");
      scriptTag.setAttribute(
        "src",
        `https://www.bing.com/api/maps/mapcontrol?callback=makeMap`
      );
      scriptTag.async = true;
      scriptTag.defer = true;
      document.body.appendChild(scriptTag);
      window.makeMap = makeMap;
    }
  }, [makeMap]);

  return (
      <>
    <div ref={mapContainer} style={{ height: props.height, width: props.width }}></div>
    </>
  );
}