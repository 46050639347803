import React from "react";
import BusinessHoursIcon from '../../images/location-details/BusinessHoursIcon.svg';
import "./LocationDetailsComponents.css";
import { sortBy } from "lodash";

const weekdays = {
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday"
}

export default function LocationBusinessHours(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img src={BusinessHoursIcon} alt="" /></div>
            <div className="location-details-body"><p style={{ paddingBottom: "10px" }} className='location-details-label'>Business Hours ({props.timeZoneAbbreviation})</p>
                {props.businessHours?.length > 0 ? (
                    <>
                        {props.businessHours.map((item, index) => (
                            <div key={index} className="business-hours-container">
                                <p key={index} style={{ paddingBottom: "8px" }} className='business-hours-title'>{item.businessHoursTypeName}</p>
                                <div className='business-hours-days-container'>
                                    {item.days !== undefined && sortBy(item.days, 'weekdayId').map((day, dayIndex) => (
                                        <div key={dayIndex} className="business-hours-day-row">
                                            <div className="business-hours-day">
                                                {weekdays[day.weekdayId]}
                                            </div>
                                            <div className="business-hours-hours">
                                                <>
                                                    {!day.openOnWeekday ? ("Closed") : (
                                                        day.openingHours + ((day.openingMinutes !== "00") ? (":" + day.openingMinutes) : "") + day.openingHoursAMPM
                                                        + " - " +
                                                        day.closingHours + ((day.closingMinutes !== "00") ? (":" + day.closingMinutes) : "") + day.closingHoursAMPM
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <p className='location-details-nodata' style={{marginTop: "-10px"}}>NO DATA AVAILABLE</p>
                )}
            </div>
        </div>

    )
}