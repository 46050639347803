import React, { useState, useEffect } from "react";
import "./LocationSearch.css";
import useAxios from 'axios-hooks'
import { Alert } from "react-bootstrap";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import IsMobileDevice from "../components/IsMobileDevice";
import ErrorIcon from '@material-ui/icons/Error';
import { useAmplitude } from '../services/Amplitude';
import LocationSearchDesktopView from "./views/LocationSearchDesktopView";
import LocationSearchMobileView from "./views/LocationSearchMobileView";

const locationTypes = {
    1: "Shipper/Receiver",
    2: "Carrier Terminal",
    3: "Last-Mile Location",
    4: "Truck Stop",
    5: "Weigh Station",
    6: "Truck Parking"
}

export default function LocationSearch() {

    const isMobileView = IsMobileDevice();
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const numberOfRecords = 20;
    const [searchCriteria, setSearchCriteria] = useState({
        address: "",
        name: "",
        city: "",
        state: "",
        locationTypeIds: [],
        returnLimit: numberOfRecords
    });
    const [getLocationsMessage, setGetLocationsMessage] = useState("...loading")
    const [locations, setLocations] = useState([]);
    const api_search_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/location/search" : API_BASE_URL + "/location/search";
    const [{ data, loading, error }, fetchData] = useAxios(
        {
            url: api_search_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );
    const [locationDetailsButtonStyle, setLocationDetailsButtonStyle] = useState("");
    const [showNoResults, setShowNoResults] = useState(false);
    const [searchType, setSearchType] = useState("name");
    const [subNameMatch, setSubNameMatch] = useState("");
    const amplitude = useAmplitude();

    const handleChange = (e) => {
        setSearchCriteria({
            ...searchCriteria,
            [e.target.id]: e.target.value
        });
    }

    function handleClear(e) {
        clearAll();
        window.location.reload(false);
    }

    function handleTextboxClear(e, id) {
        e.preventDefault();
        setSearchCriteria({
            ...searchCriteria,
            [id]: ""
        });
    }

    function clearAll() {
        setSearchCriteria({
            address: "",
            name: "",
            city: "",
            state: "",
            locationTypeIds: [],
            returnLimit: 20
        });
        setLocations([]);
        setShowNoResults(false);
    }

    function handleToggle(e) {
        setSearchType(e.target.value)
        clearAll();
    }

    function handleFilterToggle(e) {
        if (searchCriteria.locationTypeIds.includes(e.target.value)) {
            //remove
            setSearchCriteria({
                ...searchCriteria,
                locationTypeIds: searchCriteria.locationTypeIds.filter(function (typeId) {
                    return typeId !== e.target.value
                })
            });
        }
        else {
            //add
            setSearchCriteria({
                ...searchCriteria,
                locationTypeIds: searchCriteria.locationTypeIds.concat(e.target.value)
            });
        }
    }

    function handleSearch(e) {
        e.preventDefault();
        setShowNoResults(true);
        setGetLocationsMessage("...loading")
        setSubNameMatch(searchCriteria.name)
        if (searchType === "address") {
            if (searchCriteria.address.length > 2 && searchCriteria.city.length > 2 && searchCriteria.state.length > 0) {
                fetchData({
                    url: api_search_url + "?address=" + encodeURIComponent(searchCriteria.address) + "&city=" + encodeURIComponent(searchCriteria.city) + "&state=" + encodeURIComponent(searchCriteria.state) + "&returnLimit=" + searchCriteria.returnLimit
                });
            }
            else {
                setShowNoResults(false);
                triggerErrorAlert(true, "City and State are required when searching with address");
            }
        }
        else {
            if (searchCriteria.name.length > 2 || searchCriteria.city.length > 2 || searchCriteria.state.length > 0) {

                let locationTypeUrl = ""
                if (searchCriteria.locationTypeIds && searchCriteria.locationTypeIds.length > 0) {
                    searchCriteria.locationTypeIds.map(typeId => locationTypeUrl += "&locationTypeIds=" + typeId)
                }

                fetchData({
                    url: api_search_url + "?name=" + encodeURIComponent(searchCriteria.name) + "&city=" + encodeURIComponent(searchCriteria.city) + "&state=" + encodeURIComponent(searchCriteria.state) + locationTypeUrl + "&returnLimit=" + searchCriteria.returnLimit
                });
            }
            else {
                setShowNoResults(false);
                triggerErrorAlert(true, "A minimum of 3 characters is required in at least one search field");
            }
        }
    }

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else if (error.response.data.message) {
                    message = error.response.data.message
                }
                else {
                    message = "Error getting location list"
                }
                //Log Error Event in Amplitude
                amplitude.logEvent("Error", {
                    "Error Message": message,
                    "Location Name": searchCriteria.name,
                    "City": searchCriteria.city,
                    "State": searchCriteria.state,
                });
                triggerErrorAlert(true, message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error getting location list");
            } else {
                // anything else
                triggerErrorAlert(true, "Error getting location list");
            }
            setGetLocationsMessage("Error getting location list")
        }
    }, [error]);

    useEffect(() => {
        setLocations(data);
        if (data) {
            if (searchCriteria.address.length > 2) {
                amplitude.logEvent("Location Search By Address", {
                    "Location Address": searchCriteria.address,
                    "City": searchCriteria.city,
                    "State": searchCriteria.state,
                    "Count of Records Returned": data.length,
                });
            }
            else if (searchCriteria.name.length > 2 || searchCriteria.city.length > 2 || searchCriteria.state.length > 0) {
                amplitude.logEvent("Location Search By Location Name", {
                    "Location Name": searchCriteria.name,
                    "City": searchCriteria.city,
                    "State": searchCriteria.state,
                    "Count of Records Returned": data.length,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }
    return (
        <>
            <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                <ErrorIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{errorAlertMessage}</p>
                </div>
            </Alert>
            {(isMobileView) ? 
                <LocationSearchMobileView 
                    handleToggle={handleToggle}
                    searchType={searchType}
                    locationTypes={locationTypes}
                    handleFilterToggle={handleFilterToggle}
                    searchCriteria={searchCriteria}
                    handleSearchInputChange={handleChange}
                    handleSearch={handleSearch}
                    handleSearchInputClear={handleClear}
                    loading={loading}
                    locations={locations}
                    numberOfRecordsToShow={numberOfRecords}
                    error={error}
                    searchResultMessageToDisplay={getLocationsMessage}
                    setLocationDetailsButtonStyle={setLocationDetailsButtonStyle}
                    subNameMatch={subNameMatch}
                    showNoResults={showNoResults}
                />
                :
                <LocationSearchDesktopView
                    handleToggle={handleToggle}
                    searchType={searchType}
                    locationTypes={locationTypes}
                    handleFilterToggle={handleFilterToggle}
                    searchCriteria={searchCriteria}
                    handleSearchInputChange={handleChange}
                    handleSearch={handleSearch}
                    handleSearchInputClear={handleClear}
                    loading={loading}
                    locations={locations}
                    numberOfRecordsToShow={numberOfRecords}
                    error={error}
                    searchResultMessageToDisplay={getLocationsMessage}
                    setLocationDetailsButtonStyle={setLocationDetailsButtonStyle}
                    subNameMatch={subNameMatch}
                    showNoResults={showNoResults}
                />
            }
        </>
    );
}