import { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./AddEntityModal.css"
import FloatLabelTextBox from "./FloatLabelTextBox";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks'
import ErrorIcon from '@material-ui/icons/Error';
import ProgressSpinner from "../components/ProgressSpinner";
import CloseIcon from '@material-ui/icons/Close';

export default function ForgotPasswordModal(props) {

    const [email, setEmail] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(true);
    const api_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/forgot-password" : API_BASE_URL + "/user/forgot-password";
    const [{ data, loading, error, response }, postForgotPassword] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    function handleClose() {
        setEmail("");
        setDisableSubmit(true);
        props.onHide();
    }

    function handleChange(e){
        setEmail(e.target.value);
        if (validateEmail(e.target.value)) 
            setDisableSubmit(false);
        else
        setDisableSubmit(true);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setDisableSubmit(true);
        
        if (validateEmail(email)) { 
            const payload = {
                "email": email
            }          
            try {
                await postForgotPassword(
                    {data: {
                        ...payload
                    }}
                ).then(_ => { setDisableSubmit(false); });
                setEmail("");                
                props.onSuccess(true, "A temporary password will be sent to this email address. Please check your inbox.");
                handleClose();
            } catch (err) {
                setDisableSubmit(false);
            }
        }
    }

    function validateEmail(elementValue){
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(elementValue);
    }


    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error resetting password");
            } else {
                // anything else
                triggerErrorAlert(true, "Error resetting password");
            }
        }
    }, [error]);

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    return (
        <>
            <Modal
                {...props}
                dialogClassName="modal-container"
                centered
                onHide={handleClose}
            >
                <Modal.Header>
                    <Modal.Title>
                        Forgot your password?
                        <div className="modal-close" onClick={() => handleClose()}><CloseIcon /></div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="forgot-password-body" style={{ paddingLeft: "20px" }}>
                {loading ? (
                    <div className="spinner-container modal-spinner-container" style={{ zIndex: "1" }}>
                        <ProgressSpinner fontColor="#FFFFFF" />
                    </div>
                    ) : <></> }
                    <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                        <ErrorIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>{errorAlertMessage}</p>
                        </div>
                    </Alert>
                    <div className="modal-description" style={{ textAlign: "justify", lineHeight: "1.3rem" }}>
                        Please enter the email address associated with your account and we will send you instructions on how to reset your password.
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="medium-form-group">
                            <FloatLabelTextBox
                                inputLabel="EMAIL"
                                inputAutoFocus="true"
                                inputType="text"
                                inputName="emailAddress"
                                inputValue={email}
                                inputPlaceholder="Email"
                                handleChangeProps={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-cancel" onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button className="btn-submit" onClick={(e) => { handleSubmit(e) }} type="submit" disabled={disableSubmit}>Reset Password</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}