import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import "./LocationDetailsMobileView.css";
import LocationAddress from "../../components/locationdetails/LocationAddress";
import LocationType from "../../components/locationdetails/LocationType";
import LocationBusinessHours from "../../components/locationdetails/LocationBusinessHours";
import LocationClosures from "../../components/locationdetails/LocationClosures";
import LocationAppointmentSchedulingMobile from "../../components/locationdetails/LocationAppointmentSchedulingMobile";
import LocationAmenities from "../../components/locationdetails/LocationAmenities";
import LocationServices from "../../components/locationdetails/LocationServices";
import LocationSafetyRequirements from "../../components/locationdetails/LocationSafetyRequirements";
import LocationProcedures from "../../components/locationdetails/LocationProcedures";
import MobileMap from "../../components/locationdetails/MobileMaps";
import { Link } from "react-router-dom";
import MobileAnalytics from "../../components/locationdetails/MobileAnalytics";

export default function LocationDetailsMobileView(props) {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div>
                {value === index && (
                    <div p={3}>
                        {children}
                    </div>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const StyledTabs = styled((props) => (
        <Tabs
            {...props}
            TabIndicatorProps={{
                children: <span className="MuiTabs-indicatorSpan" />,
                style: {
                    minHeight: '20px'
                },
            }}
        />
    ))({
        '& .MuiTab-root': {
            fontFamily: 'Roboto-Bold',
            padding: '6px 25px 10px 0',
            minHeight: '20px',
            minWidth: '0',
            textAlign: 'left'
        },
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'left',
            backgroundColor: 'transparent',
            height: '5px',
        },
        '& .MuiTabs-indicatorSpan': {
            maxWidth: 25,
            width: '100%',
            height: '5px',
            backgroundColor: '#68368b',
            borderRadius: '20px',
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    });

    const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            color: '#525252',
            '&.Mui-selected': {
                color: '#292929',
            },
            '&.Mui-focusVisible': {
                backgroundColor: 'rgba(100, 95, 228, 0.32)',
            },
        }),
    );

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="location-details-mobile-view">
            {(props.location !== undefined && props.location !== null) ? (
                <>
                    <div className="header">
                        {props.location.name}
                    </div>
                    <Box sx={{ width: '100%', padding: '10px 24px' }}>
                        <StyledTabs value={value} onChange={handleChange}>
                            <StyledTab label="DETAILS" />
                            <StyledTab label="MAP" />
                            <StyledTab label="ANALYTICS" />
                        </StyledTabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {props.session?.roleName !== "Viewer" &&
                        <Link className="edits-link" to={"/editlocation/" + props.location.id}>Write a Review</Link>}
                        <div className="mobile-body">
                            <div className="mobile-details-container">
                                <LocationAddress
                                    address={props.location.address}
                                    city={props.location.city}
                                    state={props.location.state}
                                    zipCode={props.location.zipCode}
                                ></LocationAddress>
                            </div>
                            <div className="mobile-details-container">
                                <LocationType
                                    locationTypeDescription={props.location.locationTypeDescription}
                                ></LocationType>
                            </div>
                            <div className="mobile-details-container">
                                <LocationBusinessHours
                                    timeZoneAbbreviation={props.location.timeZoneAbbreviation}
                                    businessHours={props.location.businessHours}
                                >
                                </LocationBusinessHours>
                            </div>
                            <div className="mobile-details-container">
                                <LocationClosures
                                    mainClosures={props.location.mainClosures}
                                ></LocationClosures>
                            </div>
                            <div className="mobile-details-container">
                                <LocationAppointmentSchedulingMobile
                                    appointmentScheduling={props.location.appointmentScheduling}
                                >
                                </LocationAppointmentSchedulingMobile>
                            </div>
                            <div className="mobile-details-container">
                                <LocationAmenities
                                    restrooms={props.location.restrooms}
                                    overnightParking={props.location.overnightParking}
                                    breakroom={props.location.breakroom}
                                    vendingMachine={props.location.vendingMachine}
                                    showers={props.location.showers}
                                    wiFi={props.location.wiFi}
                                    additionalAmenities={props.location.additionalAmenities}
                                ></LocationAmenities>
                            </div>
                            <div className="mobile-details-container">
                                <LocationServices
                                    services={props.location.services}
                                ></LocationServices>
                            </div>
                            <div className="mobile-details-container">
                                <LocationSafetyRequirements
                                    safetyRequirements={props.location.safetyRequirements}
                                    additionalSafetyRequirements={props.location.additionalSafetyRequirements}
                                ></LocationSafetyRequirements>
                            </div>
                            <div className="mobile-details-container">
                                <LocationProcedures
                                    procedures={props.location.procedures}
                                ></LocationProcedures>
                            </div>
                            <div className='word-cloud-mobile-main'>
                                <div className='word-cloud-mobile-label'><p>WHAT DRIVERS ARE SAYING</p></div>
                                {(props.location.wordCloud !== null && props.location.wordCloud !== undefined) ?
                                    <div className='word-cloud-mobile-section'>{Object.keys(props.location.wordCloud).map((key, index) =>
                                        <div className="word-cloud-mobile-child" key={index}><span className='mobile-word'>{key}</span><span className='mobile-count' style={{ marginLeft: '15px' }}>{props.location.wordCloud[key]}</span></div>)}
                                    </div> : <div className='word-cloud-mobile-section'><p style={{ fontFamily: 'Roboto-Medium', fontSize: 16, height: 20 }}>NO DATA AVAILABLE YET FOR THIS LOCATION.</p></div>}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MobileMap location={props.location} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <MobileAnalytics locationid={props.location.id} />
                    </TabPanel>
                </>
            ) : (<></>)}
        </div>
    )
}