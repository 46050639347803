import React from 'react';
import IsMobileDevice from "../IsMobileDevice";
import "./EditLocationRadioButtons.css";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function EditLocationRadioButtons(props) {

    const isMobileView = IsMobileDevice();

    function radioChange(e) {
        props.onRadioChange(e.currentTarget);
      }

    return(
        <div className="radio-container">
            { (props.radioLabel) && <div className="radio-label">{props.radioLabel}</div> }
            { (props.radiolabelDescription) && <div className="label-description">{props.radiolabelDescription}</div> }
            {isMobileView ||  props.useToggle ? 
                (
                    <ToggleButtonGroup
                        value={props.radioChecked === null ? props.defaultValue : props.radioChecked}
                        exclusive
                        onChange={radioChange}
                        aria-label="text alignment"
                        disabled={props.radioDisabled}
                    >                    
                        {props.radioOptions?.map((item, index) => ( 
                            <ToggleButton name={props.radioName} data-index={props.radioIndex} value={item.id} key={index} aria-label="left aligned">
                                <span>{item.name}</span>
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                )
                :
                (
                    <div className="radio-container">
                        {
                            props.radioOptions?.map((item, index) => ( 
                            <>
                                <p key={index}>
                                    <input id={`radio-${item.id}-${props.radioName}`} type="radio" value={item.id} name={props.radioName} checked={props.radioChecked === item.id} onChange={radioChange} />
                                    <label htmlFor={`radio-${item.id}-${props.radioName}`}>{item.name}</label>
                                </p>
                            </>
                            ))
                        }
                    </div>
                )
            }              
        </div>
    )
}