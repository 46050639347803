
import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import L from "leaflet";
import GeoJSONCluster from "./GeoJSONCluster";

export default function GeofenceMap(props){

    const nullPoint = "{\"type\": \"Polygon\", \"coordinates\": [[[0, 0], [0, 0.000000001], [0.000000001, 0], [0, 0]]]}";
    const geoJsonStyles = {
        fontFamily: "sans-serif",
        textAlign: "center",
        height: "100%"
    };

    const mapHeight = props.height ? props.height : "100%";
    const mapWidth = props.width ? props.width : "100%"
    const geoJsonMapStyle = {
        height: mapHeight,
        width: mapWidth
    };

    return(
        <>
            {(props.geoFence1 && props.geoFence1 !== nullPoint) &&
                <div style={geoJsonStyles}>
                    <MapContainer
                        style={geoJsonMapStyle}
                        bounds={L.geoJSON(JSON.parse(props.geoFence1)).getBounds()}
                        key="map"
                        scrollWheelZoom={true}
                        zoom={props.zoom ? props.zoom : 13}>
                        <TileLayer
                            url={"http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"}
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            maxZoom='20'
                            minZoom='2'
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        />
                        <GeoJSONCluster data={JSON.parse(props.geoFence1)} />
                    </MapContainer>
                </div>
            }
        </>
    )
}