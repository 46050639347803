import React from "react";
import "./FloatLabelTimePicker.css";
import "./FloatLabel.css";

export default function FloatLabelTimePicker(props) {

    return (
        <>
            <input 
                type="time" 
                value={props.inputValue}
                onChange={props.inputOnChange}  
                disabled={props.inputDisabled !== null || props.inputDisabled == undefined ? props.inputDisabled : false}
            />
            <label className="time-picker-floating-label" htmlFor={props.inputName}>{props.inputLabel}</label>
        </>
    );
}