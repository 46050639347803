import React from "react";
import "./LocationDetailsComponents.css";

export default function LocationClosures(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"></div>
            <div className="location-details-body"><p className='location-details-label'>Closures</p>
                {(props.mainClosures !== null && props.mainClosures !== undefined) ?
                    (
                        <div className="location-details-closures">
                            {props.mainClosures.closedNewYearsDay ? (
                                <div>New Year’s Day: <b>January 1<sup>st</sup></b></div>
                            ) : (<></>)}
                            {props.mainClosures.closedIndependenceDay ? (
                                <div>Independence Day: <b>July 4<sup>th</sup></b></div>
                            ) : (<></>)}
                            {props.mainClosures.closedVeteransDay ? (
                                <div>Veterans Day: <b>November 11<sup>th</sup></b></div>
                            ) : (<></>)}
                            {props.mainClosures.closedChristmasEve ? (
                                <div>Christmas Eve: <b>December 24<sup>th</sup></b></div>
                            ) : (<></>)}
                            {props.mainClosures.closedChristmasDay ? (
                                <div>Christmas Day: <b>December 25<sup>th</sup></b></div>
                            ) : (<></>)}
                            {props.mainClosures.closedNewYearsEve ? (
                                <div>New Year’s Eve: <b>December 31<sup>st</sup></b></div>
                            ) : (<></>)}
                        </div>
                    ) :
                    (
                        <p className='location-details-nodata'>NO DATA AVAILABLE</p>
                    )}
            </div>
        </div>
    )
}