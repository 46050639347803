import React, { useEffect, useMemo, useState } from "react";
import "leaflet/dist/leaflet.css";
import NoTruckPathImage from '../../images/location-details/NoTruckPathImage.svg';
import "./EditGeofence.css";
import EditableGeofenceMap from "../EditableGeofenceMap.js";

export default function EditGeofence(props) {
    const [geofence, setGeofence] = useState(props.geofence);
    const [helperText, setHelperText] = useState("");
    const [errorClass, setErrorClass] = useState("");

    const geoJsonStyles = {
        fontFamily: "sans-serif",
        textAlign: "center",
        height: "100%"
    };

    const geoJsonMapStyle = {
        height: "470px"
    };

    const nullPoint = "{\"type\":\"Polygon\",\"coordinates\":[[[0,0],[0,0.000000001],[0.000000001,0],[0,0]]]}";

    function updateGeofence(geofenceStr){
        setGeofence(geofenceStr);
        props.handleChange("geofence", geofenceStr);
    }

    return (
        <div className="edit-details-body">
            <div className="edit-details-description">
                Select the edit icon and drag the corners of the blue box to select the boundaries and perimeter for this location. Click save when finished.
            </div>
            <div className="edit-details-body-padding">
                <div className='geofence-map'>
                    <div style={geoJsonStyles}>
                        <EditableGeofenceMap
                            zoom={4}
                            data={geofence && geofence.length > 0 ? JSON.parse(geofence): ""}
                            originalGeofence={props.originalGeofence && props.originalGeofence.length > 0 ? JSON.parse(props.originalGeofence): ""}
                            updateGeofence={updateGeofence}
                            latitude={props.latitude}
                            longitude={props.longitude}
                            setDisableSubmit={props.setDisableSubmit}
                            triggerErrorAlert={props.triggerErrorAlert} />
                    </div>
                </div>
            </div>
        </div>
    );
}