import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import NoTruckPathImage from '../../images/location-details/NoTruckPathImage.svg'
import BingMap from "../../components/BingMap.js";
import "./MobileMap.css";
import { isEqual } from "lodash";
import MobileMapContainer from "./MobileMapContainer";

const customTheme = createTheme({
    palette: {
        neutral: {
            main: '#68368b',
            contrastText: '#fff',
        },
    },
    components: {
        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    padding: '0 75px'
                },
                dot: {
                    width: '10px',
                    height: '10px',
                    margin: '0 5px',
                    objectFit: 'contain',
                    border: 'solid 0.5px #68368b',
                    backgroundColor: '#ffffff'
                },
                dotActive: {
                    backgroundColor: '#68368b'
                },
            },
        },
    },
});

export default function MobileMap(props) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = 3;

    function handleNext() {
        if (activeStep < maxSteps - 1)
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        else
            setActiveStep(0);
    };

    function handleBack() {
        if (activeStep > 0)
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        else
            setActiveStep(maxSteps - 1);
    };

    const nullPoint = "{\"type\": \"Polygon\", \"coordinates\": [[[0, 0], [0, 0.000000001], [0.000000001, 0], [0, 0]]]}";
    function getStepContent(step) {
        switch (step) {
            case 1:
                return (
                    <>
                        <p className="mobile-map-label">GEOFENCE</p>
                        <div className='mobile-location-details-map'>
                            {(props.location.geoFence1 && props.location.geoFence1 !== nullPoint) ?
                                <MobileMapContainer geoFence={props.location.geoFence1} />
                                : <div className='mobile-no-geofence-data-div'>
                                    <div className='mobile-no-geofence-data-center'>
                                        <p>No data available for geofence yet.</p>
                                        <img src={NoTruckPathImage} alt="" />
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <p className="mobile-map-label">TRUCK PATHS</p>
                        <div className='mobile-location-details-map'>
                            {(props.location.geoFence2 && props.location.geoFence2 !== nullPoint) ?
                                <MobileMapContainer geoFence={props.location.geoFence2} />
                                : <div className='mobile-no-geofence-data-div'>
                                    <div className='mobile-no-geofence-data-center'>
                                        <p>No data available for truck paths yet.</p>
                                        <img src={NoTruckPathImage} alt="" />
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <p className="mobile-map-label">LOCATION</p>
                        <div className='mobile-location-details-map'>
                            <BingMap
                                height="100%"
                                width="100%"
                                pins={[props.location]}
                            />
                        </div>
                    </>
                );
        }
    };

    return (
        <Box style={{ paddingBottom: '10px' }}>
            <div style={{ padding: '10px 25px', width: '100%' }}>
                {getStepContent(activeStep)}
            </div>
            <ThemeProvider theme={customTheme}>
                <MobileStepper
                    variant="dots"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="large"
                            color="neutral"
                            onClick={handleNext}
                        >
                            {theme.direction === 'rtl' ? (
                                <ArrowCircleLeftIcon />
                            ) : (
                                <ArrowCircleRightIcon />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="large" color="neutral" onClick={handleBack}>
                            {theme.direction === 'rtl' ? (
                                <ArrowCircleRightIcon />
                            ) : (
                                <ArrowCircleLeftIcon />
                            )}
                        </Button>
                    }
                />
            </ThemeProvider>
        </Box>
    );
}