import React, { useEffect } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import "./PasswordChecklist.css";

export default function PasswordChecklist(props) {
    const ruleDefinitions = {
        minLength: {
            valid: props.value.length >= (props.minLength || 100),
            message: props.messages.minLength || `Password must contain at least ${props.minLength} characters.`,
        },
        specialChar: {
            valid: /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g.test(props.value),
            message: props.messages.specialChar || "Password must contain at least 1 special character.",
        },
        number: {
            valid: /\d/g.test(props.value),
            message: props.messages.number || "Password must contain at least 1 number.",
        },
        capital: {
            valid: (() => {
                var i = 0
                if (props.value.length === 0) {
                    return false
                }
                while (i < props.value.length) {
                    const character = props.value.charAt(i)
                    if (character == character.toLowerCase()) {
                        // Character is lowercase, numeric, or a symbol
                    } else if (character == character.toUpperCase()) {
                        return true
                    }
                    i++
                }
                return false
            })(),
            message: props.messages.capital || "Password must contain at least 1 upper case letter.",
        },
        match: {
            valid: props.value.length > 0 && props.value === props.valueAgain,
            message: props.messages.match || "Passwords match.",
        },
        lowercase: {
            valid: (() => {
                var i = 0
                if (props.value.length === 0) {
                    return false
                }
                while (i < props.value.length) {
                    const character = props.value.charAt(i)
                    if (character == character.toUpperCase()) {
                        // Character is lowercase, numeric, or a symbol
                    } else if (character == character.toLowerCase()) {
                        return true
                    }
                    i++
                }
                return false
            })(),
            message: props.messages.lowercase || "Password must contain at least 1 lower case letter.",
        },
        letter: {
            valid: /[a-zA-Z]/g.test(props.value),
            message: props.messages.letter || "Password must contain at least 1 letter.",
        },
        maxLength: {
            valid: props.value.length <= (props.maxLength || 16),
            message: props.messages.maxLength || `Password can not have more than ${props.maxLength} characters.`,
        },
        notEmpty: {
            valid: Boolean(props.value.length > 0 && props.valueAgain && props.valueAgain.length > 0),
            message: props.messages.notEmpty || "Password fields are not empty.",
        },
    }

    const enabledRules = props.rules.filter((rule) => Boolean(ruleDefinitions[rule]))

    useEffect(() => {
        var rulesValidList = [];
        enabledRules.forEach((rule => {
            rulesValidList.push(ruleDefinitions[rule].valid);
        }));
        props.passwordValid(rulesValidList.every(element => element === true));
    }, [ruleDefinitions])

    return (
        <ul>
            {enabledRules.map((rule) => {
                const { message, valid } = ruleDefinitions[rule]
                return (
                    <PasswordRule key={rule} valid={valid}>
                        {message}
                    </PasswordRule>
                )
            })}
        </ul>
    )
}

export function PasswordRule(props) {
    return (
        <li className={props.valid ? "valid" : "invalid"}>
            {props.valid ? <CheckIcon /> : <ClearIcon />}
            <span>{props.children}</span>
        </li>
    )
}