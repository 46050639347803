import React from "react";
import { Route, Switch } from "react-router";
import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import HomeLayout from "../layouts/HomePageLayout";
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import LocationSearch from "../pages/LocationSearch";
import LocationDetails from "../pages/LocationDetails";
import ProtectedRoute from "./ProtectedRoute";
import AdminRoutes from "./AdminRoutes";
import InternalAdminPortal from "../pages/InternalAdminPortal";
import Logout from "../pages/Logout";
import Company from "../pages/Company";
import EditLocationDetails from "../components/EditLocationDetails";
import UpdatePassword from "../pages/UpdatePassword";
import Home from "../pages/Home";
import IntegrationAuthLander from "../pages/IntegrationAuthLander";

export default function MainRoutes() {
    return (
        <Switch>
            <Route path={['/home' ]}>
                <HomeLayout>
                    <Route path='/home' component={Home} />
                </HomeLayout>                
            </Route>        
            <ProtectedRoute exact path={['/', '/updatepassword', '/locationsearch', '/locationdetails/:id/:edit?', '/editlocation/:id', '/company/:id']}>
                <MainLayout>
                    <Route path='/updatepassword' component={UpdatePassword} />
                    <Route path='/locationsearch' component={LocationSearch} />
                    <Route path='/locationdetails/:id/:edit?' component={LocationDetails} />
                    <Route path='/editlocation/:id' component={EditLocationDetails} />
                    <Route path='/company/:id' component={Company} />
                </MainLayout>
            </ProtectedRoute>
            <Route path={['/login', '/logout', '/locationdetails']}>
                <AuthLayout>
                    <Route path='/locationdetails' component={IntegrationAuthLander} />
                    <Route path='/login' component={Login} />
                    <Route path='/logout' component={Logout} />
                </AuthLayout>
            </Route>
            <AdminRoutes path={['/internaladminportal']}>
                <MainLayout>
                    <Route path='/internaladminportal' component={InternalAdminPortal} />
                </MainLayout>
            </AdminRoutes>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}