import EditLocationRadioButtons from "../../components/editlocationdetails/EditLocationRadioButtons";

export default function ApproveDenyAllToggle(props) {

  return (
            <div className="approve-deny-all-container">
                <label className="option-check-container">I have reviewed all proposed edits
                    <input type="checkbox" name="reviewedAll" className="option-checkbox" checked={props.reviewedAll} onChange={(e) => props.handleReviewedAllCheck(e.target.checked)}></input>
                    <span className="option-checkmark"></span>
                </label>
                <EditLocationRadioButtons 
                    radioName="approveDenyAll"
                    radioOptions={[{id: 1, name: "Approve All"}, {id: 0, name: "Deny All"}]}
                    radioChecked={props.approveDenyAll}
                    defaultValue={null}
                    useToggle={true}
                    onRadioChange={props.handleApproveDenyAll}
                    radioDisabled={!props.reviewedAll}
                />
            </div>
        )
}