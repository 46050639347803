
import React, { useState } from 'react';
import './Ripple.css';

export default function Ripple(props) {

    const {children= null, classes = "", onClickHandler = null} = props;
    
    const [spanStyles, setSpanStyles] = useState({});
    const [count, setCount] = useState(0);

    function showRipple(e) {
        const style = { top: 0 + 'px', left: 0 + 'px', height: 100 + '%', width: 100 + '%' };
        setCount(count + 1);
        setSpanStyles({...spanStyles, [count] : style});
      }

      function renderRippleSpan() {
        const spanArray = Object.keys(spanStyles);
        if (spanArray && spanArray.length > 0) {
          return (
            spanArray.map((key, index) => {
              return <span key={'spanCount_' + index} className="" style={{ ...spanStyles[key]}}></span>
            })
          )
        } else {
          return null;
        }
      }

      function cleanUp() {
        setSpanStyles({});
        setCount(0);
      }
      
      function callCleanUp(cleanup, delay) {
        return function() {
          clearTimeout(bounce());
          function bounce() {
            setTimeout(() => {
              cleanup();
            }, delay);
          }
        }
      }

    return (
        <div className={'ripple ' + classes} onClick={onClickHandler}>
            {children}
            <div className="rippleContainer" onMouseDown={(e)=>showRipple(e)} onMouseUp={callCleanUp(()=>cleanUp(), 2000)}>
                {renderRippleSpan()}
            </div>
        </div>
    );
}