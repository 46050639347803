import React, { useEffect, useState } from "react";
import useAxios from 'axios-hooks'
import { Alert, Button, Form, FormControl, InputGroup, Table } from "react-bootstrap";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import { onError } from "../libs/errorLib";
import AddCompanyModal from "./AddCompanyModal";
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ClearIcon from '@material-ui/icons/Clear';
import "../pages/InternalAdminPortal.css";
import { FormGroup } from "@material-ui/core";
import USStates from '../constants/USStates';
import { Link } from "react-router-dom";

export default function CompanyList(props) {
    const api_url = ENVIRONMENT === "development" ? "/api/company/mostrecent/50" : API_BASE_URL + "/company/mostrecent/50";
    const api_search_url = ENVIRONMENT === "development" ? "/api/company/search" : API_BASE_URL + "/company/search";
    const [{ data: companies, loading, error }, fetchData] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );
    
    const [getCompaniesMessage, setGetCompaniesMessage] = useState("...loading")
    const [modalShow, setModalShow] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [searchCriteria, setSearchCriteria] = useState({
        name: "",
        city: "",
        state: ""
    });

    const handleChange = (e) => {
        setSearchCriteria({
            ...searchCriteria,
            [e.target.id]: e.target.value
        });
    }

    useEffect(() => {
        if (searchCriteria.name.length === 0 && searchCriteria.city.length === 0 && searchCriteria.state.length === 0) {
            getData();
        }
    }, [searchCriteria]);

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value){
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
                setGetCompaniesMessage(message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error getting company list");
                setGetCompaniesMessage("Error getting company list");
            } else {
                // anything else
                triggerErrorAlert(true, "Error getting company list");
                setGetCompaniesMessage("Error getting company list");
            }
        }
    }, [error]);

    function getData() {
        try {
            fetchData({
                url: api_url
            });
            
        } catch (e) {
            onError("Error getting company list");
            setGetCompaniesMessage("Error getting company list");
        }
    }

    function handleSearch(e){
        e.preventDefault();
        if (searchCriteria.name.length > 2 || searchCriteria.city.length > 2 || searchCriteria.state.length > 0){
            fetchData({
                url: api_search_url + "?name=" + searchCriteria.name + "&city=" + searchCriteria.city+ "&state=" + searchCriteria.state
            });
        }        
        else{
            triggerErrorAlert(true, "A minimum of 3 characters is required in at least one search field");
        }
    }
    
    function handleClear(e){
        e.preventDefault();
        setSearchCriteria({
            name: "",
            city: "",
            state: ""
        });
    }

    function handleTextboxClear(e, id) {
        e.preventDefault();
        setSearchCriteria({
            ...searchCriteria,
            [id]: ""
        });        
    }

    function openModal(e) {
        e.preventDefault();
        setModalShow(true);
    }

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
        getData();
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }
    return (
        <>
            <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                <CheckCircleIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{successAlertMessage}</p>
                </div>
            </Alert>
            <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                <ErrorIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{errorAlertMessage}</p>
                </div>
            </Alert>
            <div className="table-search-header">
                <Form inline>
                    <InputGroup className="search-box">
                        <FormControl id="name" placeholder="Company" value={searchCriteria.name} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.name.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={(e) => handleTextboxClear(e, "name")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <InputGroup className="search-box">
                        <FormControl id="city" placeholder="City" value={searchCriteria.city} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.city.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={(e) => handleTextboxClear(e, "city")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <FormGroup className="search-box">
                        <Form.Control
                            id="state"
                            type="text"
                            placeholder="State"
                            value={searchCriteria.state}
                            name="state"
                            onChange={(e) => handleChange(e)}
                            as="select"
                            style={{ width: '95%'}}
                        >
                            <option key="" value="">Select a state</option>
                            {USStates.map((item, index) =>
                                <option key={index} value={item.abbreviation}>{item.name}</option>
                            )}
                        </Form.Control>
                    </FormGroup>
                    <Button type="submit" className="search-button" onClick={(e) => { handleSearch(e) }}>
                        <SearchIcon style={{ paddingRight: '5px' }} />Search
                    </Button>
                    <Button
                        type="submit"
                        variant="link"
                        className="clear-button"
                        onClick={(e) => { handleClear(e) }}
                        disabled={(searchCriteria.name.length === 0 && searchCriteria.city.length === 0 && searchCriteria.state.length === 0) ? true : false}
                    >
                        Clear Search
                    </Button>
                    <Button type="submit" className="modal-button" onClick={(e) => { openModal(e) }}>
                        <AddIcon style={{ paddingRight: '5px', marginTop: '-2px' }} />New Company
                    </Button>
                    <AddCompanyModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={triggerSuccessAlert} />
                </Form>
            </div>
            <div className="list-body">
                <Table className="floating-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Company</th>
                            <th>City</th>
                            <th>State/Province</th>
                            <th>Website</th>
                            <th>Date/Time Created</th>
                            <th>Created By</th>
                        </tr>
                    </thead>
                    {(loading || error) ? (
                        <tbody>
                            <tr>
                                <td colSpan="7" style={{ textAlign: 'center', borderRadius: '8px' }}><div>{getCompaniesMessage}</div></td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {companies !== undefined ? (
                                companies.map(item =>
                                <tr key={`${item.id}`}>
                                    <td><MoreVertIcon /></td>
                                    <td><Link to={"/company/" + item.id} target="_blank" rel="noopener noreferrer">{item.companyName}</Link></td>
                                    <td>{item.city}</td>
                                    <td>{item.stateName}</td>
                                    <td>
                                        {item.website && item.website !== undefined ? (
                                            item.website.length > 0 ? (
                                                <a href={(item.website.includes('http://') || item.website.includes('https://')) ? item.website : "https://" + item.website}>Company Website</a>
                                            ) : (<></>)   
                                        ) : (<></>)}                                                                            
                                    </td>
                                    <td>{item.created}</td>
                                    <td>{item.createdByUser}</td>
                                </tr>
                            )) : (<></>)}
                        </tbody>
                    )}
                </Table>
            </div>
        </>
    );
}