import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { FeatureGroup, MapContainer, useMap } from "react-leaflet";

export default function MobileMapContainer(props) {
    const geoJsonStyles = {
        fontFamily: "sans-serif",
        textAlign: "center",
        height: "100%",
        width: "100%"
    };

    const geoJsonMapStyle = {
        height: "100%"
    };
    return (
        <div style={geoJsonStyles}>
            <MapContainer
                style={geoJsonMapStyle}
                bounds={L.geoJSON(JSON.parse(props.geoFence)).getBounds()}
                key="map"
                scrollWheelZoom={true}
                zoom={13}>
                <DrawGeofence data={JSON.parse(props.geoFence)} />
            </MapContainer>
        </div>
    )
}

function DrawGeofence(props) {
    const map = useMap();

    const _onFeatureGroupReady = (reactFGref) => {
        let leafletGeoJSON = new L.GeoJSON(props.data);

        map.eachLayer(function (layer) {
            map.removeLayer(layer);
        });

        L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 20,
            minZoom: 2,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(map);

        var layer = leafletGeoJSON;

        layer.addTo(map);

        map.fitBounds(layer.getBounds());
    };

    return (
        <FeatureGroup
            ref={(reactFGref) => {
                if (reactFGref) _onFeatureGroupReady(reactFGref);
            }}
        >
        </FeatureGroup>
    );
}