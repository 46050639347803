import React from "react";
import Form from "react-bootstrap/Form";
import NumberFormat from 'react-number-format';
import "./FloatLabelCurrency.css";
import "./FloatLabel.css";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

export default function FloatLabelCurrency(props) {

    let optionsList;
    if (props.selectOptions !== undefined && props.selectOptions.length) {
        optionsList = props.selectOptions.map((item, index) =>
            <option key={index} value={item.id}>{item.name}</option>
        );
    }
    else {
        optionsList = <></>;
    }

    function handleEnterKeyPress(e){
        e.preventDefault(); 
        e.target.blur();
    }

    return (
        <>
            <div className="floating-label currency-container" style={{ height: "2.5rem", marginTop: "2%" }}>
                <NumberFormat
                    style={{ padding: "0.375rem 0.75rem" }}
                    data-testid={props.inputName}
                    id={props.inputName}
                    autoFocus={props.inputAutofocus}
                    name={props.inputName}
                    onChange={(e) => props.handleChangeProps(e)}
                    onKeyPress={(e) => { e.key === 'Enter' && handleEnterKeyPress(e) }}
                    className={props.inputClassName}
                    disabled={props.inputDisabled}
                    placeholder={props.inputPlaceholder}
                    value={props.inputValue}
                    decimalScale={props.inputDecimalScale}
                    thousandSeparator={true}                 
                    mask=" "
                    allowLeadingZeros={true}
                    allowEmptyFormatting={false}
                    isAllowed={(values) => {
                        const {formattedValue, floatValue } = values;
                        return formattedValue === "" || floatValue <= props.inputMAXCost
                    }}
                />
                <label className="textbox-floating-label" htmlFor={props.inputName}>{props.inputLabel}</label>
                <div className="currency-icon-container"><AttachMoneyIcon></AttachMoneyIcon></div>
                <div className="currency-type-container">
                    <div className="floating-label" style={{ display: "block" }}>
                        <Form.Control
                            data-testid="currencyType"
                            id="currencyType"
                            type="text"
                            value={props.inputCurrencyType}
                            name="currencyType"
                            onChange={(e) => props.handleChangeProps(e)}
                            as="select"
                            disabled={props.inputDisabled}
                        >
                        {optionsList}
                        </Form.Control>
                    </div>
                </div>
            </div>
            {props.inputHelperText !== undefined && props.inputHelperText.length > 0 ? (
                <Form.Text className={props.inputClassName}>{props.inputHelperText}</Form.Text>
            ) : (
                <></>
            )}
        </>
    );
}