import React, { useEffect, useMemo, useState } from "react";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../../constants/apiConstants';
import IsMobileDevice from "../IsMobileDevice";
import useAxios from 'axios-hooks'
import EditLocationRadioButtons from "./EditLocationRadioButtons";
import Form from "react-bootstrap/Form";
import FloatLabelTextBoxWithAddIcon from "../elements/textbox/FloatLabelTextBoxWithAddIcon";

export default function EditAmenities(props) {

  const isMobileView = IsMobileDevice();
  const propsAmenities = useMemo(() => {return props.amenities}, [props]);
  const[amenities, setAmenities] = useState(propsAmenities); 
  const[additionalAmenitiesNameArray, setAdditionalAmenitiesArray] = useState([]);
  const[otherAmenities, setOtherAmenities] = useState([]);

  useEffect(() => {
    let additionalAmenities = props.additionalAmenities;
    if(props.additionalAmenitiesEdits?.length > 0){
      setOtherAmenities(props.additionalAmenitiesEdits);
      const newArray = ((props.additionalAmenitiesEdits.filter(f => f.deleteFlag === false)).reverse()).concat(props.additionalAmenities);
      additionalAmenities = newArray.filter(i => !props.additionalAmenitiesEdits.find(f => f.amenityName === i.amenityName && f.deleteFlag === true));
    }
    setAdditionalAmenitiesArray(additionalAmenities ? additionalAmenities.map(item => item.amenityName) : []);
  },[]);

  //Amenities
  function handleChange(target){
      const value = parseInt(target.value) > 1 ? null : (parseInt(target.value) === 1);
      switch(target.name)
      {
        case 'overnightparking':
                                setAmenities({
                                  ...amenities,
                                  overnightParking: value, 
                                });
                                break;
        case 'restroom':
                                setAmenities({
                                  ...amenities,
                                  restroom: value, 
                                });
                                break;
        case 'breakroom':
                                setAmenities({
                                  ...amenities,
                                  breakroom: value, 
                                });
                                break;
        case 'vendingmachine':
                                setAmenities({
                                  ...amenities,
                                  vendingMachine: value, 
                                });
                                break;
        case 'showers':
                                setAmenities({
                                  ...amenities,
                                  showers: value, 
                                });
                                break;
        case 'wifi':
                                setAmenities({
                                  ...amenities,
                                  wiFi: value, 
                                });
                                break;
        default: break;
      }
  }

  useEffect(() => {
    props.handleChange("amenities", amenities);
  },[amenities]);

  //Additional Amenities
  const api_other_amenities_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/location/other/amenities" : API_BASE_URL + "/location/other/amenities";
  const [{ data: otherAmenitiesOptions }, fetchOtherAmenities]  = useAxios(
      {
          url: api_other_amenities_url,
          headers: {
              'x-api-key': ACCESS_TOKEN_NAME
          }
      },
      { manual: true }
  );

  function getData(){
    try {
      fetchOtherAmenities({
           url: api_other_amenities_url
       });
    } catch (err) { }
  }

  useEffect(() => {
    getData();
  },[]);

  function handleAdditionalAmenityChange(amenityName, deleteFlag){
 
    //Remove from 'otherAmenities' array to undo add/remove action on an Additional Amenity
    if((!amenityExists(props.additionalAmenities, amenityName) && deleteFlag) ||
       (amenityExists(props.additionalAmenities, amenityName) && amenityExists(otherAmenities, amenityName) && !deleteFlag)){
      var index = otherAmenities.findIndex(i => i.amenityName === amenityName);
      otherAmenities.splice(index, 1);
      setOtherAmenities([...otherAmenities]);
    } 
    else{
      setOtherAmenities([
        ...otherAmenities,
        {
          amenityName: amenityName,
          amenityValue: true,
          deleteFlag: deleteFlag
        }
      ])
    }
  }

  function amenityExists(amenityArray, amenityName) {
    return amenityArray?.some(el => el.amenityName === amenityName);
  }

  useEffect(() => {
    props.handleChange("otherAmenities", otherAmenities);
  },[otherAmenities]);

  const radioOptions = isMobileView ? [ {id: 1, name: "Yes"}, {id: 0, name: "No"}, {id: 2, name: "Not Sure"}] : [ {id: 1, name: "Yes"}, {id: 0, name: "No"}];

  return (
    <div className="edit-details-body">
      <div className="edit-details-description hide-on-mobile-view">
        Does this location offer the following amenities for drivers?
      </div>
      <div className="edit-details-body-padding">
        <EditLocationRadioButtons 
            radioLabel="Overnight Parking"
            radiolabelDescription="Does this location offer overnight parking for drivers?"
            radioName="overnightparking"
            radioOptions={radioOptions}
            radioChecked={amenities.overnightParking ? 1 : (amenities.overnightParking === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        <EditLocationRadioButtons 
            radioLabel="Restrooms"
            radiolabelDescription="Does this location offer restrooms for drivers?"
            radioName="restroom"
            radioOptions={radioOptions}
            radioChecked={amenities.restroom ? 1 : (amenities.restroom === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        <EditLocationRadioButtons 
            radioLabel="Breakroom"
            radiolabelDescription="Does this location offer a breakroom for drivers?"
            radioName="breakroom"
            radioOptions={radioOptions}
            radioChecked={amenities.breakroom ? 1 : (amenities.breakroom === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        <EditLocationRadioButtons 
            radioLabel="Vending Machines"
            radiolabelDescription="Does this location offer vending machines for drivers?"
            radioName="vendingmachine"
            radioOptions={radioOptions}
            radioChecked={amenities.vendingMachine ? 1 : (amenities.vendingMachine === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        <EditLocationRadioButtons 
            radioLabel="Showers"
            radiolabelDescription="Does this location offer showers for drivers?"
            radioName="showers"
            radioOptions={radioOptions}
            radioChecked={amenities.showers ? 1 : (amenities.showers === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        <EditLocationRadioButtons 
            radioLabel="WI-FI"
            radiolabelDescription="Is Wi-Fi available for driver use?"
            radioName="wifi"
            radioOptions={radioOptions}
            radioChecked={amenities.wiFi ? 1 : (amenities.wiFi === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        </div>
         <div className="edit-details-body-padding hide-on-mobile-view">
             <div className="edit-details-description edit-details-text-dark">Additional Amenity Information</div>
             <div className="label-description edit-details-text-dark">Are there any other amenities available at this location?</div>
             <Form>
                 <FloatLabelTextBoxWithAddIcon 
                    inputLabel="AMENITY"
                    inputAutoFocus={true}
                    inputType="text"
                    inputName="additional-amenity"
                    inputPlaceholder="Add Amenity"
                    inputAlreadyExistingErrorMessage="This Amenity has already been added."
                    inputFieldsArray={additionalAmenitiesNameArray}
                    onFormChange={handleAdditionalAmenityChange}
                    inputMaxLength={25}                    
                    isAutoComplete={true}
                    inputSearchOptions={otherAmenitiesOptions}
                 />
             </Form>
         </div>
    </div>
  );
}