import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./LocationDetails.css";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import IsMobileDevice from "../components/IsMobileDevice";
import useAxios from 'axios-hooks'
import { onError } from "../libs/errorLib";
import "leaflet/dist/leaflet.css";
import { useSessionContext } from "../libs/sessionLib";
import { useAmplitude } from '../services/Amplitude';
import "../components/elements/button/AppButton.css";
import { Alert } from "react-bootstrap";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationDetailsDesktopView from "./views/LocationDetailsDesktopView";
import LocationDetailsMobileView from "./views/LocationDetailsMobileView";
import ProgressSpinner from "../components/ProgressSpinner";
import { useInsertionEffect } from "react";

export default function LocationDetails() {
    const isMobileView = IsMobileDevice();
    const { session } = useSessionContext();
    let { id, edit } = useParams();
    const api_search_url = ENVIRONMENT === "development" ? "/api/location/" + id + "?isExternalUser=" + !session.isInternalUser : API_BASE_URL + "/location/" + id + "?isExternalUser=" + !session.isInternalUser;
    const [{ data: location, loading, error }, fetchData] = useAxios(
        {
            url: api_search_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        try {
            fetchData();
        } catch (e) {
            onError("Error getting location details");
        }
    }

    useEffect(() => {
        if (location && edit === "success") {
            triggerSuccessAlert(true, "Thank you. Your edits are being processed.");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
    }

    const amplitude = useAmplitude();
    useEffect(() => {
        if (location) {
            amplitude.logEvent("Location Details View", {
                "Location Id": location.id,
                "Location Name": location.name,
                "City": location.city,
                "State": location.state,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const formatPhoneNumber = (value) => {
        if (value !== null && value.length > 0) {
            const phoneLength = value.length;
            if (phoneLength < 4) return value;
            if (phoneLength === 7) return `${value.slice(0, 3)}-${value.slice(3)}`;
            if (phoneLength === 10) return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }
        return value;
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {loading && (
                <div className="spinner-container spinner-black">
                    <ProgressSpinner fontColor="#FFFFFF" />
                </div>
            )}
            {(location !== undefined && location !== null) ? (
                <>
                    <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                        <CheckCircleIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Edits Submitted.</Alert.Heading>
                            <p>{successAlertMessage}</p>
                        </div>
                    </Alert>
                    {(isMobileView) ?
                        <LocationDetailsMobileView
                            location={location}
                            formatPhoneNumber={formatPhoneNumber}
                            session={session}
                        />
                        :
                        <LocationDetailsDesktopView
                            handleChange={handleChange}
                            loading={loading}
                            session={session}
                            location={location}
                            formatPhoneNumber={formatPhoneNumber}
                            error={error}
                        />
                    }
                </>
            ) : (<></>)}
        </>
    );
}