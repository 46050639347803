import React from "react";
import "./ProgressSpinner.css";
import { ReactComponent as LoadingSpinner } from '../images/loading-spinner.svg';
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";

const override = css`
color: #707070;
position: absolute;
  top: 128px;
  left: 155px;
`;

export default function ProgressSpinner(props) {
    return (
        <div className="spinner" style={{ color: props.fontColor }}>
            <LoadingSpinner />
            <p>Loading</p>
            <PulseLoader css={override} size={5} color={props.fontColor} loading={true} speedMultiplier={0.5} />
        </div>
    );
}