import React from "react";
import ServicesIcon from '../../images/location-details/Services.svg';
import Icon_Yes from '../../images/location-details/Icon_Yes.svg';
import Icon_No from '../../images/location-details/Icon_No.svg';
import Icon_Null from '../../images/location-details/Icon_Null.svg';
import "./LocationDetailsComponents.css";

export default function LocationServices(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img style={{ marginTop: "-5px" }} src={ServicesIcon} alt="" /></div>
            <div className="location-details-body"><p className='location-details-label'>Services</p>
                {props.services ? (
                    <div>
                        {props.services.lumper === true && <div style={{ marginRight: 60 }}><p><span className='amenity-label-yes'>Lumper</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>}
                        {props.services.lumper === false && <div style={{ marginRight: 60 }}><p><span className='amenity-label-no'>Lumper</span><img className='amenity-img' src={Icon_No} alt="" /></p></div>}
                        {props.services.lumper === null && <div style={{ marginRight: 60 }}><p><span className='amenity-label-null'>Lumper</span><img className='amenity-img' src={Icon_Null} alt="" /></p></div>}
                        {props.services.lumperCost !== null &&
                            <div className="sub-container">
                                <p className='sub-title'>Lumper fee</p>
                                <p style={{ wordSpacing: '1px' }}>
                                    {`$${props.services.lumperCost.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2 })} `}
                                    <span style={{ fontFamily: 'Roboto-Bold' }}>{props.services.currencyType ? props.services.currencyType : "USD"}</span>
                                </p>
                            </div>
                        }
                        {props.services.paymentTypeCash || props.services.paymentTypeCreditCard || props.services.paymentTypeCheck || props.services.paymentTypeCOMCheck ?
                            <div className="sub-container">
                                <p className='sub-title'>Payment Options Accepted</p>
                                {props.services.paymentTypeCash && <p>Cash</p>}
                                {props.services.paymentTypeCreditCard && <p>Credit Card</p>}
                                {props.services.paymentTypeCheck && <p>Check</p>}
                                {props.services.paymentTypeCOMCheck && <p>Comcheck</p>}
                            </div>
                            : <></>
                        }
                    </div>
                ) : <p className='location-details-nodata'>NO DATA AVAILABLE</p>
                }
            </div>
        </div>
    )
}