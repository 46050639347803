import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import './FloatLabelTextBoxWithAddIcon.css';
import FloatLabelTextBox from '../../FloatLabelTextBox';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

export default function FloatLabelTextBoxWithAddIcon(props) {

    const [fields, setFields] = useState([]);
    const typeaheadRef = useRef(null);
    const [options, setOptions] = useState([]);
    const [valueToAdd, setValueToAdd] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorClass, setErrorClass] = useState("");  
    
    const autoCompleteSuggestions = props.inputSearchOptions?.map((item, index) => ({
                                        id: index+1,
                                        name: item,
                                    }));

    useEffect(() => {
        setFields(props.inputFieldsArray);
    },[props.inputFieldsArray]);

    useEffect(() => {
        (valueToAdd.length > 2) ? setOptions(autoCompleteSuggestions) : setOptions([]);
      }, [valueToAdd.length]);

      function handleBlur(e){
        if (e.target.value === "") {
            typeaheadRef.current.clear();
            setValueToAdd("");
        }
    }

    function handleInputChange(value)
    {
        setValueToAdd(value);
        if(value.length <= props.inputMaxLength){
            setErrorClass("");
            setErrorMessage("");
        }            
        else{
            setErrorClass("error");
            setErrorMessage("The max length of " + props.inputMaxLength + " characters is reached.");
        }   
    }

    function onClickAddButton(errorMessage){
        if(valueToAdd.length > 0 && valueToAdd.length <= props.inputMaxLength){            
            if(!checkIfValueExistsInArray(valueToAdd)) {
                setFields([valueToAdd, ...fields]);
                (props.isAutoComplete) && typeaheadRef.current.clear();
                setValueToAdd("");
                setErrorClass("");
                setErrorMessage("");
                props.onFormChange(valueToAdd, false);
            }
            else{
                setErrorClass("error");
                setErrorMessage(errorMessage);
            }
        }        
    }

    function onClickRemoveButton(item){
        var index = fields.findIndex(i => i === item);
        if(index > -1) {
                var updatedFields = fields;
                updatedFields.splice(index, 1);
                setFields([...updatedFields]);
                props.onFormChange(item, true);
            }        
    }

    function checkIfValueExistsInArray(value){
        const valueExists = fields.some(element => {
            return element.toLowerCase() === value.toLowerCase();
          });
        return valueExists;
    }

    return (
        <div className="dynamic-form-container">
            {props.isAutoComplete ? (
                <>
                <div className={valueToAdd.length > 0 ? "medium-form-group async-floating-label small-field" : "medium-form-group async-floating-label"} style={{ marginBottom: "30px" }}>
                        <AsyncTypeahead
                            allowNew
                            className={errorClass}
                            id={`${props.inputName}0`}
                            minLength={0}
                            labelKey="name"
                            onSearch={(query) => {
                                handleInputChange(query);
                            }}
                            isLoading={false}
                            onChange={(event) => {
                                (event && event.length > 0) && setValueToAdd(event[0].name);
                            }}
                            options={options}
                            placeholder={props.inputPlaceholder}
                            useCache={false}
                            ref={typeaheadRef}
                            onBlur={(e) => handleBlur(e)}                         
                        />
                        <label className={valueToAdd.length > 0 ? "floating" : "hidden-label"} htmlFor={`${props.inputName}0`}>
                            {props.inputLabel}
                        </label>
                    <Form.Text className={`dynamic-form-text ${errorClass}`} style={{ marginTop: valueToAdd.length > 0 ? '-50px' : '-20px', }}>
                        <div key={-1}>
                            <div style={{ float: "left", }}>
                                {errorMessage}
                            </div>
                            <div style={{ float: "right", }}>
                                {valueToAdd.length}/{props.inputMaxLength}
                            </div>
                        </div>
                    </Form.Text>
                    <div className="icon-container" onClick={() => onClickAddButton(props.inputAlreadyExistingErrorMessage)}>
                        <AddCircleOutlineIcon />
                        <AddCircleRoundedIcon />
                    </div>
                    </div>
                </>
            ) : (
                <Form.Group className="medium-form-group floating-label-inputbox">
                    <FloatLabelTextBox
                        inputLabel={props.inputLabel}
                        inputAutoFocus={props.inputAutoFocus}
                        inputType={props.inputType}
                        inputName={`${props.inputName}0`}
                        inputPlaceholder={props.inputPlaceholder}
                        inputValue={valueToAdd}
                        inputClassName={`dynamic-form-text ${errorClass}`}
                        inputHelperText={[<div key={-1}><div style={{ float: "left", }}>{errorMessage}</div><div style={{ float: "right", }}>{valueToAdd.length}/{props.inputMaxLength}</div></div>]}
                        handleChangeProps={(event) => {
                            (event) && handleInputChange(event.target.value);
                        }}
                    />
                    <div className="icon-container" onClick={() => onClickAddButton(props.inputAlreadyExistingErrorMessage)}>
                        <AddCircleOutlineIcon />
                        <AddCircleRoundedIcon />
                    </div>
                </Form.Group>
            )}          
            {
                fields?.map((item, index) => ( 
                    <Form.Group key={index} className="medium-form-group floating-label-inputbox added-textbox-bottom-margin">
                       <div className='floating-label'> 
                            <Form.Control
                                autoFocus={props.inputAutoFocus}
                                type={props.inputType}
                                name={`${props.inputName}${index+1}`}
                                value={item}
                                disabled={true}
                            />
                        </div>
                        <div className="icon-container" onClick={() => onClickRemoveButton(item)}>
                            <RemoveCircleOutlineIcon />
                            <RemoveCircleRoundedIcon />
                        </div>
                    </Form.Group>                   
                ))
            }
        </div>  
    );
}