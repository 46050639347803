import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

export default function TabPanelContainer(props) {

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
            <>
                {value === index && (
                <div>
                    {children}
                </div>
                )}
            </>  
        );
      }

    TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        }));

    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className={classes.root}>
            <Tabs TabIndicatorProps={{style: {background:'#00A131'}}} value={value} onChange={handleChange} aria-label="simple tabs example">    
            {
                props.elements.map((element, i) => (
                <Tab key={i} label={element[0]} {...a11yProps(element[0])} />
                ))
            }      
            </Tabs>
            </div>
            {
                props.elements.map((element, i) => (
                    <TabPanel key={i} value={value} index={i}>
                        {element[1]}
                    </TabPanel>   
                ))
            }              
        </>
    );
}