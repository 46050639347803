import React from "react";
import "./LocationDetailsComponents.css";

export default function LocationAppointmentSchedulingDesktop(props) {

    const appointmentSchedulingWebAddressURL = formatWebURL(props.appointmentScheduling?.websiteAddress);

    function formatWebURL(webUrl){
        return webUrl ? ((webUrl.includes('http://') || webUrl.includes('https://')) ? webUrl : "https://" + webUrl) : "";
    }

    return (
        <div style={{ display: "inline-flex", width: "100%", marginTop: "10px" }}>
            <div className="location-details-icon"></div>
            <div className="location-details-body">
                <p className='location-details-label'>Appointment Scheduling</p>
                {props.appointmentScheduling ?
                    (
                        <>
                            {props.appointmentScheduling.byAppointment ?
                                (
                                    <>
                                        <div><p className='sub-title'>Appointment Type</p><p>By appointment Only</p></div>
                                        {(props.appointmentScheduling.methodOnline || props.appointmentScheduling.methodPhone || props.appointmentScheduling.methodEmail) &&
                                            <div className="sub-container" style={{ lineHeight: 'normal' }}>
                                                <p className='sub-title'>Scheduling Method</p>
                                                {props.appointmentScheduling.methodOnline &&
                                                    <div style={{ marginTop: "3%" }}>
                                                        <p className="appointment-method-title">Online</p>
                                                        <a className="location-details-word-wrap" href={appointmentSchedulingWebAddressURL} target="_blank" rel="noreferrer">{(new URL(appointmentSchedulingWebAddressURL)).hostname}</a>
                                                    </div>
                                                }
                                                {props.appointmentScheduling.methodPhone &&
                                                    <div style={{ marginTop: "3.5%" }}>
                                                        <p className="appointment-method-title">By Phone</p>
                                                        <p>{props.formatPhoneNumber(props.appointmentScheduling.phoneNumber)}</p>
                                                    </div>
                                                }
                                                {props.appointmentScheduling.methodEmail &&
                                                    <div style={{ marginTop: "3.5%" }}>
                                                        <p className="appointment-method-title">By Email</p>
                                                        <a href={`mailto: ${props.appointmentScheduling.emailAddress}`} className="location-details-word-wrap">{props.appointmentScheduling.emailAddress}</a>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                ) : (<div><p className='sub-title'>Appointment Type</p><p>FCFS</p></div>)
                            }
                            {(props.appointmentScheduling.specialInstructions && props.appointmentScheduling.specialInstructions.length > 0) &&
                                <div className="sub-container" style={{ lineHeight: 'normal' }}>
                                    <p className='sub-title'>Special Instructions</p>
                                    <p className="location-details-word-wrap" style={{ marginTop: "3%" }}>{props.appointmentScheduling.specialInstructions}</p>
                                </div>
                            }
                        </>
                    ) : (<p className='location-details-nodata'>NO DATA AVAILABLE</p>)
                }
            </div>
        </div>


    )
}