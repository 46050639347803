import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Row from 'react-bootstrap/Row'
import Tab from "react-bootstrap/Tab";
import CompanyList from "../components/CompanyList";
import PendingUserList from "../components/PendingUserList";
import PendingLocationEditList from "../components/pendinglocationedits/PendingLocationEditList";
import UserList from "../components/UserList";
import { useSessionContext } from "../libs/sessionLib";
import "./InternalAdminPortal.css";
import Badge from '@mui/material/Badge';

export default function InternalAdminPortal() {
    const { session } = useSessionContext();
    const [userRequestCount, setUserRequestCount] = useState(0);
    const renderUserRequestCount = num => {
        setUserRequestCount(num);
    };
    const [editsCount, setEditsCount] = useState(0);
    const renderLocationWithEditsCount = num => {
        setEditsCount(num);
    };

    return (
        <>
            {(session !== undefined) && session.isInternalUser && session.roleId === 1 ? (
                <div className="Admin-Portal-Container">
                    <Tab.Container defaultActiveKey="companies">
                        <Row>
                            <Nav className="tab-panel-header">
                                Internal Admin Portal
                                <div className="tab-nav">
                                    <Nav.Item className="tab-nav-tabs">
                                        <Nav.Link eventKey="companies">Companies</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="tab-nav-tabs">
                                        <Nav.Link eventKey="users">Users</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="tab-nav-tabs">
                                        <Badge badgeContent={userRequestCount} color="secondary">
                                            <Nav.Link eventKey="pending-users">Pending Users</Nav.Link>
                                        </Badge>
                                    </Nav.Item>
                                    <Nav.Item className="tab-nav-tabs">
                                        <Badge badgeContent={editsCount} color="secondary">
                                            <Nav.Link eventKey="pending-location-edits">Pending Edits</Nav.Link>
                                        </Badge>
                                    </Nav.Item>
                                </div>
                            </Nav>
                        </Row>
                        <Row>
                            <Tab.Content style={{ width: '100%' }}>
                                <Tab.Pane eventKey="companies">
                                    <CompanyList />
                                </Tab.Pane>
                                <Tab.Pane eventKey="users">
                                    <UserList />
                                </Tab.Pane>
                                <Tab.Pane eventKey="pending-users">
                                    <PendingUserList renderUserRequestCount={renderUserRequestCount}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="pending-location-edits">
                                    <PendingLocationEditList renderLocationWithEditsCount={renderLocationWithEditsCount} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Row>
                    </Tab.Container>
                </div>
            ) : (
                <div>Not Authorized</div>
            )}
        </>
    );
}