import { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import "./AddEntityModal.css"
import FloatLabelTextBox from "./FloatLabelTextBox";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks';
import ErrorIcon from '@material-ui/icons/Error';
import PasswordChecklist from "../components/elements/PasswordChecklist";

export default function NewIntegrationUserModal(props) {
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [errorClasses, setErrorClasses] = useState({
        firstName: "",
        lastName: "",
        email: "",
        title: "",
        phoneNumber: "",
        newPassword: "",
        confirmNewPassword: ""
    });
    const [newUser, setNewUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        title: "",
        phoneNumber: "",
        password: "",
        isinternaluser: false,
        userroleid: 6,
        externalusertypeid: 3
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const api_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/" : API_BASE_URL + "/user/";
    const [{ data, loading, error, response }, postNewUser] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    function handleChange(e) {
        setNewUser({
            ...newUser,
            [e.target.name]: e.target.value,
        })
    }
    const [allRequiredFieldsFilled, setAllRequiredFieldsFilled] = useState(false);
    useEffect(() => {
        if (newUser.phoneNumber.length === 0 || newUser.firstName.length === 0 || newUser.lastName.length === 0 || newUser.password.length === 0 || newUser.email.length === 0) {
            setAllRequiredFieldsFilled(false);
        }
        else {
            setAllRequiredFieldsFilled(true);
        }
    }, [newUser]);

    function handlePhoneNumberChange(e) {
        const phoneNumber = e.target.value.replace(/[^\d]/g, "");
        setNewUser({
            ...newUser,
            phoneNumber: phoneNumber,
        })
    }

    const [userPassword, setUserPassword] = useState({
        newPassword: "",
        confirmNewPassword: ""
    });

    function handlePasswordChange(e) {
        setUserPassword({
            ...userPassword,
            [e.target.name]: e.target.value,
        })
    }

    const [passwordValid, setPasswordValid] = useState(false);

    useEffect(() => {
        var password = "";
        if (passwordValid) {
            password = userPassword.newPassword;
        }
        setNewUser({
            ...newUser,
            password: password
        })
    }, [passwordValid]);

    function validateFields() {
        var missingFields = false;
        var invalidEmail = false;
        var invalidPhoneNumber = false;
        var confirmPasswordDoesntMatch = false;
        var errors = {};

        if (newUser.phoneNumber.length === 0) {
            errors["phoneNumber"] = "error";
            missingFields = true;
        }
        else {
            if (validatePhoneNumber(newUser.phoneNumber)) {
                errors["phoneNumber"] = "";
            }
            else {
                errors["phoneNumber"] = "error";
                invalidPhoneNumber = true;
            }
        }
        if (newUser.firstName.length === 0) {
            errors["firstName"] = "error";
            missingFields = true;
        }
        else {
            errors["firstName"] = "";
        }

        if (newUser.lastName.length === 0) {
            errors["lastName"] = "error";
            missingFields = true;
        }
        else {
            errors["lastName"] = "";
        }

        if (newUser.password.length === 0) {
            errors["password"] = "error";
            missingFields = true;
        }
        else {
            errors["password"] = "";
        }

        if (newUser.email.length === 0) {
            errors["email"] = "error";
            missingFields = true;
        }
        else {
            if (validateEmail(newUser.email)) {
                errors["email"] = "";
            }
            else {
                errors["email"] = "error";
                invalidEmail = true;
            }
        }

        if (userPassword.newPassword.length === 0) {
            errors["newPassword"] = "error";
            missingFields = true;
        }

        if (userPassword.confirmNewPassword.length === 0) {
            errors["confirmNewPassword"] = "error";
            missingFields = true;
        }

        if (userPassword.newPassword !== userPassword.confirmNewPassword) {
            errors["confirmNewPassword"] = "error"; //confirm pass doesnt match new pass
            confirmPasswordDoesntMatch = true;
        }

        setErrorClasses({
            firstName: ("firstName" in errors ? errors["firstName"] : ""),
            lastName: ("lastName" in errors ? errors["lastName"] : ""),
            email: ("email" in errors ? errors["email"] : ""),
            password: ("password" in errors ? errors["password"] : ""),
            phoneNumber: ("phoneNumber" in errors ? errors["phoneNumber"] : ""),
            newPassword: ("newPassword" in errors ? errors["newPassword"] : ""),
            confirmNewPassword: ("confirmNewPassword" in errors ? errors["confirmNewPassword"] : "")
        });

        if (!missingFields && !invalidEmail && !invalidPhoneNumber && !confirmPasswordDoesntMatch) {
            return true;
        } else {
            if (missingFields) {
                triggerErrorAlert(true, "Required fields missing.");
            }
            if (invalidEmail) {
                triggerErrorAlert(true, "Email is invalid.");
            }
            if (invalidPhoneNumber) {
                triggerErrorAlert(true, "Phone number is invalid. Format XXXXXXXXXXXX");
            }
            return false;
        }
    }

    async function saveData(e, includeData) {
        e.preventDefault();
        setIsSubmitting(true);
        var canContinue = false;
        var userName = "";
        var successMessage = "";
        if (includeData) {
            canContinue = validateFields();
            userName = newUser.email
            successMessage = "'" + newUser.firstName + " " + newUser.lastName + "' has been saved and added.";
        }
        else {
            canContinue = true;
            if (props.appName === "drive") {
                if (props.integrationID.driverId && props.integrationID.tenantId) {
                    userName = props.integrationID.driverId + "-" + props.integrationID.tenantId;
                }
                else {
                    //using date if there is no ot drive data to make username unique
                    var today = new Date();
                    userName = "OTDriver" + today.getFullYear() + (today.getMonth() + 1) + today.getDate() + today.getHours() + today.getMinutes() + today.getSeconds();
                }
            }
            else {
                var today = new Date();
                userName = props.appName + today.getFullYear() + (today.getMonth() + 1) + today.getDate() + today.getHours() + today.getMinutes() + today.getSeconds();
            }
            successMessage = "Guest profile successfully created.";
        }

        if (canContinue) {
            try {
                var resp = await postNewUser({
                    data: {
                        ...newUser,
                        companyId: null,
                        userName: userName,
                        firstName: includeData ? newUser.firstName : props.appName.toUpperCase(),
                        lastName: includeData ? newUser.lastName : "DRIVER",
                        email: includeData ? newUser.email : null,
                        password: includeData ? newUser.password : null,
                        phoneNumber: includeData ? newUser.phoneNumber : null,
                        createdById: null,
                        modifiedById: null,
                        isinternaluser: false,
                        userroleid: 6,
                        externalusertypeid: 3,
                        integrationappname: props.appName,
                        integrationuserid: JSON.stringify(props.integrationID)
                    }
                });
                setIsSubmitting(false);
                clearUser();
                clearErrors();
                props.onSuccess(resp.data, successMessage);
            } catch (err) {
                triggerErrorAlert(true, "Error saving user");
                setIsSubmitting(false);
            }
        } else {
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error adding user");
            } else {
                // anything else
                triggerErrorAlert(true, "Error adding user");
            }
        }
    }, [error]);

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function clearUser() {
        setNewUser({
            companyId: "",
            firstName: "",
            lastName: "",
            email: "",
            userName: "",
            password: "",
            phoneNumber: "",
            createdById: null,
            modifiedById: null
        });
    }

    function clearErrors() {
        setErrorClasses({
            companyId: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            title: "",
            phoneNumber: ""
        });
    }

    function validateEmail(elementValue) {
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(elementValue);
    }

    function validatePhoneNumber(elementValue) {
        var phonePattern = /^\+?([0-9]{0,2})\)?[- ]?([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$/;
        return phonePattern.test(elementValue);
    }

    return (
        <>
            <Modal
                {...props}
                dialogClassName="integration-user-modal-container"
                centered
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>
                        Welcome to Location Intelligence
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                        <ErrorIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>{errorAlertMessage}</p>
                        </div>
                    </Alert>
                    <div style={{ fontFamily: 'Roboto-Light', fontSize: '14px', paddingBottom: '10px' }}>We are about to create a guest profile for your visit. If you would like to create a full account please provide the following information.</div>
                    <Form onSubmit={saveData} style={{ padding: '0' }}>
                        Personal Information
                        <Row style={{ marginTop: "10px" }}>
                            <Form.Group className="medium-form-group inline-left">
                                <FloatLabelTextBox
                                    inputLabel="FIRST NAME"
                                    inputAutoFocus="true"
                                    inputType="text"
                                    inputName="firstName"
                                    inputPlaceholder="First Name"
                                    inputValue={newUser.firstName}
                                    inputClassName={errorClasses.firstName}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="medium-form-group inline-right">
                                <FloatLabelTextBox
                                    inputLabel="LAST NAME"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="lastName"
                                    inputPlaceholder="Last Name"
                                    inputValue={newUser.lastName}
                                    inputClassName={errorClasses.lastName}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="medium-form-group inline-left">
                                <FloatLabelTextBox
                                    inputLabel="JOB TITLE"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="title"
                                    inputPlaceholder="Job Title"
                                    inputValue={newUser.title}
                                    inputClassName={errorClasses.title}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        Contact Information
                        <Row style={{ marginTop: "10px" }}>
                            <Form.Group className="medium-form-group inline-left">
                                <FloatLabelTextBox
                                    inputLabel="EMAIL"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="email"
                                    inputPlaceholder="Email"
                                    inputValue={newUser.email}
                                    inputClassName={errorClasses.email}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="medium-form-group inline-right">
                                <FloatLabelTextBox
                                    inputLabel="PHONE #"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="phoneNumber"
                                    inputPlaceholder="Phone #"
                                    inputValue={newUser.phoneNumber}
                                    inputClassName={errorClasses.phoneNumber}
                                    handleChangeProps={handlePhoneNumberChange}
                                />
                            </Form.Group>
                        </Row>
                        Password
                        <Row style={{ marginTop: "10px" }}>
                            <Form.Group className="large-form-group">
                                <FloatLabelTextBox
                                    inputLabel="NEW PASSWORD"
                                    inputAutoFocus="true"
                                    inputType="password"
                                    inputName="newPassword"
                                    inputPlaceholder="New Password"
                                    inputValue={userPassword.newPassword}
                                    inputClassName={errorClasses.newPassword}
                                    handleChangeProps={handlePasswordChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="large-form-group">
                                <FloatLabelTextBox
                                    inputLabel="CONFIRM NEW PASSWORD"
                                    inputAutoFocus="true"
                                    inputType="password"
                                    inputName="confirmNewPassword"
                                    inputPlaceholder="Confirm New Password"
                                    inputValue={userPassword.confirmNewPassword}
                                    inputClassName={errorClasses.confirmNewPassword}
                                    handleChangeProps={handlePasswordChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row style={{height:"100%"}}>
                            <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                                minLength={8}
                                value={userPassword.newPassword}
                                valueAgain={userPassword.confirmNewPassword}
                                passwordValid={setPasswordValid}
                                messages={{
                                    minLength: "Password must contain at least 8 characters.",
                                    specialChar: "Password must contain at least 1 special character.",
                                    number: "Password must contain at least 1 number.",
                                    capital: "Password must contain at least 1 upper case letter.",
                                    lowercase: "Password must contain at least 1 lower case letter.",
                                    match: "Passwords match.",
                                }}
                            />
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-cancel" onClick={(e) => { saveData(e, false) }} type="submit" disabled={isSubmitting}>Continue without Saving</Button>
                    <Button className="btn-submit" onClick={(e) => { saveData(e, true) }} type="submit" disabled={isSubmitting || !allRequiredFieldsFilled}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}