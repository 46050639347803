import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import FloatLabelTextBox from "../FloatLabelTextBox";
import "./EditLocationNames.css";

export default function EditLocationNames(props) {
  const propsLocationNames = useMemo(() => { return props.mainName }, [props]);
  const [mainName, setMainName] = useState("");
  const [helperText, setHelperText] = useState("");
  const [errorClass, setErrorClass] = useState("");

  useEffect(() => {
    if (propsLocationNames) {
      setMainName(propsLocationNames !== undefined ? propsLocationNames : "");
    }
  }, [propsLocationNames]);

  useEffect(() => {
    props.handleChange("locationName", mainName);
  },[mainName]);

  function onChangeFormGroupInput(e) {
    if (e.target.value.length <= 50) {
      setMainName(e.target.value);
      setErrorClass("");
      setHelperText("");
    }
    else {
      setErrorClass("bh-error");
      setHelperText("The max length of 50 characters is reached.");
    }
  }
  return (
    <div className="edit-details-body">
      <div className="edit-details-description">
        This location’s main name can be edited.
      </div>
      <div className="edit-details-body-padding">
        <Form>
          <Form.Group>
            <div className="main-name-container">
              <FloatLabelTextBox
                inputLabel="MAIN NAME"
                inputAutoFocus="true"
                inputType="text"
                inputName="locationMainName"
                inputValue={mainName}
                inputPlaceholder="Main Name"
                inputClassName={`${errorClass}`}
                inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText}</div><div style={{ float: "right" }}>{mainName.length}/50</div></div>]}
                handleChangeProps={(e) => onChangeFormGroupInput(e)}
              />
            </div>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}