import React from "react";
import ContactsIcon from '../../images/location-details/contacts.svg'
import EmailIcon from '../../images/location-details/email-icon.svg';
import PhoneIcon from '../../images/location-details/a-icon-phone.svg';
import "./LocationDetailsComponents.css";

export default function LocationContacts(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img style={{ width: "20px", height: "20px" }} src={ContactsIcon} alt="" /></div>
            <div className="location-details-body"><p className='location-details-label'>Contact</p>
                {props.contactInfo?.length > 0 ? (
                    <>
                        {props.contactInfo.map((item, index) => (
                            <div key={index} className="contact-container">
                                <p key={index} className='contacts-title'>{item.contactTypeName}</p>
                                {item.emailAddress?.length > 0 ? (
                                    <div className="contact-info-row">
                                        <div className="contact-icon"><img src={EmailIcon} alt="" /></div>
                                        <div><p className='contact-info'>{item.emailAddress}</p></div>
                                    </div>
                                ) : (<></>)}
                                {item.phone?.length > 0 ? (
                                    <div className="contact-info-row">
                                        <div className="contact-icon"><img src={PhoneIcon} alt="" /></div>
                                        <div><p className='contact-info'>{props.formatPhoneNumber(item.phone)}</p></div>
                                    </div>
                                ) : (<></>)}
                            </div>
                        ))}
                    </>
                ) : (
                    <p className='location-details-nodata'>NO DATA AVAILABLE</p>
                )}
            </div>
        </div>
    )
}