import React, { useEffect, useState } from "react";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  GeoJSON,
  useMap,
  ZoomControl
} from "react-leaflet";
import EditControl from "./EditControl";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./EditableGeofenceMap.css";
import { isEqual } from "lodash";

// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png"
});

export default function EditableGeofenceMap(props) {
  const geoJsonMapStyle = {
    height: "470px"
  };

  function handleChange(geofence) {
    props.updateGeofence(geofence && geofence !== null ? JSON.stringify(geofence) : "");
  }
  return (
    <div className="geofence-map-container">
      <MapContainer
        style={geoJsonMapStyle}
        key="map"
        scrollWheelZoom={true}
        zoom={13}
        zoomControl={false}
        bounds={props.data && props.data !== null ? L.geoJSON(props.data).getBounds() : L.latLng(props.latitude, props.longitude).toBounds(100)}
      >
        <DrawControl data={props.data} originalGeofence={props.originalGeofence} handleChange={handleChange} setDisableSubmit={props.setDisableSubmit} triggerErrorAlert={props.triggerErrorAlert} />
        <TileLayer
          url={"http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          maxZoom='20'
          minZoom='2'
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        <ZoomControl position="topleft" />
      </MapContainer>
    </div>
  );
}

function DrawControl(props) {
  const map = useMap();
  const _editableFG = React.useRef(null);
  const originalGeofence = React.useRef("");

  useEffect(() => {
    if (props.originalGeofence && props.originalGeofence !== null) {
      var polygon = L.polygon(props.originalGeofence.coordinates[0]).toGeoJSON(7);
      let polyGeoJSON = new L.geoJSON(polygon, {
        coordsToLatLng: function (coords) {
          return new L.LatLng(coords[0], coords[1]);
        }
      });

      if (!originalGeofence.current) {
        // store the ref for future access to content  
        originalGeofence.current = polyGeoJSON;
      }
    }
  }, []);

  const _onFeatureGroupReady = (reactFGref) => {
    if (props.data && props.data !== null) {
      var polygon = L.polygon(props.data.coordinates[0]).toGeoJSON(7);

      let polyGeoJSON = new L.geoJSON(polygon, {
        coordsToLatLng: function (coords) {
          return new L.LatLng(coords[0], coords[1]);
        }
      });

      if (!_editableFG.current) {
        polyGeoJSON.eachLayer((layer) => {
          reactFGref.addLayer(layer);
        });        
      }
    }    
    // store the ref for future access to content  
    _editableFG.current = reactFGref;
  };

  function editStop() {
    if (_editableFG.current) {

      var geofence = _editableFG.current.getLayers().map((l) => l.toGeoJSON(7));
      var oldGeofence = originalGeofence.current && originalGeofence.current !== null ? originalGeofence.current.getLayers().map((l) => l.toGeoJSON(7)) : [];

      if (!isEqual(geofence, oldGeofence)) {
        if (geofence.length === 1) {
          Object.entries(geofence[0]).map(([key, value]) => {
            if (key === "geometry") {
              props.handleChange(value);
            }
          });
        }
        else {
          if (geofence.length === 0) {
            props.triggerErrorAlert(true, "At least 1 geofence polygon is required. Please create a polygon to save.");

            _editableFG.current.eachLayer(function (layer) {
              _editableFG.current.removeLayer(layer);
            });

            props.setDisableSubmit(true);
          }
          if (geofence.length > 1) {
            props.triggerErrorAlert(true, "Maximum 1 geofence polygon is allowed. Please delete a polygon to save.");
            props.setDisableSubmit(true);
          }
          props.handleChange(props.originalGeofence);
        }
      }
      else {
        // no change
        props.handleChange(props.originalGeofence);
        props.setDisableSubmit(true);
      }
    }
  }

  return (
    <FeatureGroup
      ref={(reactFGref) => {
        if (reactFGref) _onFeatureGroupReady(reactFGref);
      }}
    >
      <EditControl
        position="topright"
        //onEdited={_onEdited
        onCreated={editStop}
        //onDeleted={_onDeleted}
        //onMounted={_onMounted}
        //onEditStart={_onEditStart}
        onEditStop={editStop}
        //onDeleteStart={_onDeleteStart}
        onDeleteStop={editStop}
        draw={{
          circle: false,
          circlemarker: false,
          polyline: false,
          polygon: true,
          rectangle: false,
          marker: false
        }}
        leaflet={{ map }}
      />
    </FeatureGroup>
  );
}