import React, { useEffect, useState } from "react";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../../constants/apiConstants.js';
import { onError } from "../../libs/errorLib";
import useAxios from 'axios-hooks';
import "./CompanyLocations.css";
import "../../pages/InternalAdminPortal.css";
import { Alert, Table } from "react-bootstrap";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorIcon from '@material-ui/icons/Error';

export default function CompanyDetails(props) {

    const api_company_locations_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/company/location/all/" + props.id : API_BASE_URL + "/company/location/all/" + props.id;
    const [{ data: locations, loading, error }, fetchData]  = useAxios(
        {
            url: api_company_locations_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const [getCompanyLocationsMessage, setGetCompanyLocationsMessage] = useState("...loading")
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        try {
            fetchData();            
        } catch (e) {
            onError("Error getting location details");
        }
    }

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value){
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
                setGetCompanyLocationsMessage(message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error getting company location list");
                setGetCompanyLocationsMessage("Error getting company location list");
            } else {
                // anything else
                triggerErrorAlert(true, "Error getting company location list");
                setGetCompanyLocationsMessage("Error getting company location list");
            }
        }
        else if(locations && locations.length === 0) {
            setGetCompanyLocationsMessage("There are no Locations linked with this Company.");
        }

    }, [error, locations]);

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    return(
        locations ? (
        <>   
            <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                <ErrorIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{errorAlertMessage}</p>
                </div>
            </Alert>               
            <div className='company-locations-header'><p>Associated Locations ({locations.length})</p></div>
            <div className="company-locations-body">
                <Table className="floating-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Location Name</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State/Province</th>
                            <th>Zip/Postal</th>
                            <th>Relationship</th>
                        </tr>
                    </thead>
                    {(loading || error || locations.length === 0) ? (
                        <tbody>
                            <tr>
                                <td colSpan="7" style={{ textAlign: 'center', borderRadius: '8px' }}><div>{getCompanyLocationsMessage}</div></td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {locations !== undefined ? (
                                locations.map(item =>
                                <tr key={`${item.id}`}>
                                    <td><MoreVertIcon className="moreVerIcon" /></td>
                                    <td className="table-content-ellipsis">
                                        {item.locationName && 
                                                    (
                                                        <a href={"/locationdetails/" + item.locationId} target="_blank" rel="noreferrer">
                                                            {(item.locationName).toLowerCase()}
                                                        </a>
                                                    )}
                                    </td>
                                    <td className="table-content-ellipsis">{item.locationAddress}</td>
                                    <td>{item.locationCity}</td>
                                    <td>{item.locationStateName}</td>
                                    <td>{item.locationZipCode}</td>
                                    <td>{item.relationshipName}</td>
                                </tr>
                            )) : (<></>)}
                        </tbody>
                    )}
                </Table>
            </div>
        </>
        ) : <></>
    )
}