import React, { useState } from "react";
import IsMobileDevice from "../components/IsMobileDevice";
import AddNewUserRequestModal from "../components/NewUserRequestModal";
import { Alert } from "react-bootstrap";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./HomePageLayout.css";
import "./NavbarLayout.css";
import "./NavbarMobileLayout.css";
import { LinkContainer } from "react-router-bootstrap";
import OmnitracsLogo from '../images/LocationIntelligence.svg';
import SoleraLogoWhite from '../images/solera-logo-white.svg';
import LocIntelligenceLogoWhite from '../images/location-intelligence-logo-white.svg';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import LoginIcon from '@mui/icons-material/Login';
import CloseIcon from '@mui/icons-material/Close';

function HomePageLayout({ children }) {

    const isMobileView = IsMobileDevice();
    const [modalShow, setModalShow] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [showNavBar, setShowNavBar] = useState(false);
    
    function openModal(e) {
        e.preventDefault();
        setModalShow(true);
    }

    function handleNavBarStateChange(isOpen) {
        setShowNavBar(isOpen);
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }

    function handleSignUpOnMobileView(e){
        handleNavBarStateChange(false);
        openModal(e);
    }

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
    }

    return (
        <>
            <div className="HomeLayout container py-3">
                <Navbar collapseOnSelect expand="md" className="mb-3 home-nav" expanded={showNavBar}>
                    <LinkContainer to="/">
                        <Navbar.Brand href="/" className="font-weight-bold text-muted">
                            <img src={OmnitracsLogo} alt="" />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle onClick={() => handleNavBarStateChange(true)} />
                    <Navbar.Collapse className="navbar-items-container">
                    {isMobileView ?
                        <Nav className="navbar-items">
                            <div className="navbar-items-header">
                                <img id="solera-logo" src={SoleraLogoWhite} alt="" />
                                <img id="loc-intel-logo" src={LocIntelligenceLogoWhite} alt="" />
                                <div className="close-icon" onClick={() => handleNavBarStateChange(false)}><CloseIcon /></div>
                            </div>
                            <LinkContainer to={"/login"}>
                                <Nav.Link onClick={() => handleNavBarStateChange(false)}><LoginIcon />Log In</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="">
                                <Nav.Link onClick={(e) => handleSignUpOnMobileView(e)}><PersonAddAltIcon />Sign Up</Nav.Link>
                            </LinkContainer>
                        </Nav>
                        :
                        <Nav className="navbar-items">
                            <LinkContainer to="/login">
                                <Nav.Link className="btn-submit">Login</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="">
                                <Nav.Link className="btn-submit btn-signup" onClick={(e) => { openModal(e) }}>Sign Up</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    }
                    </Navbar.Collapse>
                </Navbar>
                <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                <CheckCircleIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{successAlertMessage}</p>
                </div>
                </Alert>
                <div className="Home-Layout-Body">
                    {children}
                </div>
                <AddNewUserRequestModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={triggerSuccessAlert} />
            </div>
        </>
    );
}
export default HomePageLayout;