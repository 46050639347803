import React, { useEffect, useMemo, useState } from "react";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../../constants/apiConstants.js';
import IsMobileDevice from "../IsMobileDevice";
import useAxios from 'axios-hooks'
import { Form } from "react-bootstrap";
import "./EditSafetyRequirements.css";
import FloatLabelTextBox from "../FloatLabelTextBox";
import EditLocationRadioButtons from "./EditLocationRadioButtons";
import FloatLabelTextBoxWithAddIcon from "../elements/textbox/FloatLabelTextBoxWithAddIcon";

export default function EditSafetyRequirements(props) {

  const isMobileView = IsMobileDevice();
  const[safetyRequirements, setSafetyRequirements] = useState(props.safetyRequirements);
  const[additionalEquipmentsNameArray, setAdditionalEquipmentsNameArray] = useState([]);
  const[otherEquipments, setOtherEquipments] = useState([]);
  const radioOptions = [ {id: 1, name: "Yes"}, {id: 0, name: "No"}];
  const [helperText, setHelperText] = useState({
    equipmentName: "",
    covidProtocolInstructions: "",
    });
  const [errorClass, setErrorClass] = useState({
    equipmentName: "",
    covidProtocolInstructions: "",
  }); 

  function handleChange(target, maxLength){
    let inputValue = null;
    let isValid = true;
    switch(target.name){
        case 'petsAllowed':               inputValue = parseInt(target.value) === 1;
                                          break;

        case 'covidProtocolInstructions': let errorMsg = "";
                                          let errorCls = "";
                                          if(target.value.length <= maxLength){
                                            inputValue = target.value.length > 0 ? target.value : null;
                                          }
                                          else{
                                              isValid = false;                                           
                                              errorMsg = "The max length of " + maxLength + " characters is reached.";
                                              errorCls = "bh-error";
                                          }

                                          setHelperText({
                                            ...helperText,
                                            covidProtocolInstructions: errorMsg,
                                          });
                                          setErrorClass({
                                            ...errorClass,
                                            covidProtocolInstructions: errorCls,
                                          });
                                          break;

        default:                          inputValue = target.checked;
                                          break;
    }

    if(isValid){
        setSafetyRequirements({
            ...safetyRequirements,
            [target.name]: inputValue,
        });
    }    
  }

  useEffect(() => {
    props.handleChange("safetyRequirements", safetyRequirements);
  },[safetyRequirements]);

  //Additional Equipment
  useEffect(() => {
    let additionalEquipments = props.additionalEquipments;
    if(props.additionalEquipmentsEdits?.length > 0){
        setOtherEquipments(props.additionalEquipmentsEdits);
      const newArray = ((props.additionalEquipmentsEdits.filter(f => f.deleteFlag === false)).reverse()).concat(props.additionalEquipments);
      additionalEquipments = newArray.filter(i => !props.additionalEquipmentsEdits.find(f => f.equipmentName === i.equipmentName && f.deleteFlag === true));
    }
    setAdditionalEquipmentsNameArray(additionalEquipments ? additionalEquipments.map(item => item.equipmentName) : []);
  },[]);


  const api_other_equipment_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/location/other/safety-requirements" : API_BASE_URL + "/location/other/safety-requirements";
  const [{ data: otherEquipmentOptions }, fetchOtherEquipment]  = useAxios(
      {
          url: api_other_equipment_url,
          headers: {
              'x-api-key': ACCESS_TOKEN_NAME
          }
      },
      { manual: true }
  );

  function getData(){
    try {
        fetchOtherEquipment({
           url: api_other_equipment_url
       });
    } catch (err) { }
  }

  useEffect(() => {
    getData();
  },[]);

  function handleAdditionalEquipmentChange(equipmentName, deleteFlag){
 
    //Remove from 'otherEquipment' array to undo add/remove action on an Additional Equipment
    if((!equipmentExists(props.additionalEquipments, equipmentName) && deleteFlag) ||
       (equipmentExists(props.additionalEquipments, equipmentName) && equipmentExists(otherEquipments, equipmentName) && !deleteFlag)){
      var index = otherEquipments.findIndex(i => i.equipmentName === equipmentName);
      otherEquipments.splice(index, 1);
      setOtherEquipments([...otherEquipments]);
    } 
    else{
      setOtherEquipments([
        ...otherEquipments,
        {
          equipmentName: equipmentName,
          deleteFlag: deleteFlag
        }
      ])
    }
  }

  function equipmentExists(equipmentArray, equipmentName) {
    return equipmentArray?.some(el => el.equipmentName === equipmentName);
  }

  useEffect(() => {
    props.handleChange("otherSafetyRequirements", otherEquipments);
  },[otherEquipments]);

  return (
    <div className="edit-details-body safety-requirements" style={{ paddingTop: "1%" }}>
        <div className="edit-details-body-padding">
            <div className="radio-label">Required Equipment</div>
            <div className="label-description">Which of the following are required at this location?</div>
            <div className="appointment-checkbox-container">             
                <div className="checkbox-margin-bottom">
                    <label className="option-check-container">Safety Glasses
                        <input type="checkbox" id="safetyGlassesRequired" name="safetyGlassesRequired" className="option-checkbox" checked={safetyRequirements.safetyGlassesRequired} onChange={(e) => handleChange(e.target)}></input>
                        <span className="option-checkmark"></span>
                    </label>
                </div>
                <div className="checkbox-margin-bottom">
                    <label className="option-check-container">Hard Hat
                        <input type="checkbox" id="hardHatRequired" name="hardHatRequired" className="option-checkbox" checked={safetyRequirements.hardHatRequired} onChange={(e) => handleChange(e.target)}></input>
                        <span className="option-checkmark"></span>
                    </label>
                </div>
                <div className="checkbox-margin-bottom">
                    <label className="option-check-container">Vest
                        <input type="checkbox" id="vestRequired" name="vestRequired" className="option-checkbox" checked={safetyRequirements.vestRequired} onChange={(e) => handleChange(e.target)}></input>
                        <span className="option-checkmark"></span>
                    </label>
                </div>
                <div className="checkbox-margin-bottom">
                    <label className="option-check-container">Closed Toe Shoes
                        <input type="checkbox" id="closedToeShoesRequired" name="closedToeShoesRequired" className="option-checkbox" checked={safetyRequirements.closedToeShoesRequired} onChange={(e) => handleChange(e.target)}></input>
                        <span className="option-checkmark"></span>
                    </label>
                </div>
                <div className="checkbox-margin-bottom">
                    <label className="option-check-container">Steel Toe Shoes
                        <input type="checkbox" id="steelToeShoesRequired" name="steelToeShoesRequired" className="option-checkbox" checked={safetyRequirements.steelToeShoesRequired} onChange={(e) => handleChange(e.target)}></input>
                        <span className="option-checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
        <div className="edit-details-body-padding" style={{ marginBottom: "2.5%"}}> 
            <div className="edit-details-title">Additional Required Equipment</div>
            <div className="label-description">Does this location have any other required equipment?</div>
            <Form>
                <FloatLabelTextBoxWithAddIcon 
                    inputLabel="EQUIPMENT"
                    inputAutoFocus={true}
                    inputType="text"
                    inputName="additional-equipment"
                    inputPlaceholder="Add Equipment"
                    inputAlreadyExistingErrorMessage="This equipment has already been added."
                    inputFieldsArray={additionalEquipmentsNameArray}
                    onFormChange={handleAdditionalEquipmentChange}
                    inputMaxLength={50}                    
                    isAutoComplete={true}
                    inputSearchOptions={otherEquipmentOptions}
                />
            </Form>
        </div>
        <div className="textarea-padding-mobile">
          <div className="edit-details-title">Covid Protocols</div>
          <div className="label-description">Please provide special instructions for covid protocols at this location.</div>
          <Form className="medium-form-group text-area-container">
              <FloatLabelTextBox
                  inputLabel=""
                  inputAutoFocus="true"
                  inputType="textarea"
                  inputName="covidProtocolInstructions"
                  inputValue={safetyRequirements.covidProtocolInstructions ? safetyRequirements.covidProtocolInstructions : ""}
                  inputClassName={`dynamic-form-text ${errorClass.covidProtocolInstructions}`}
                  inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.covidProtocolInstructions}</div><div style={{ float: "right" }}>{safetyRequirements.covidProtocolInstructions ? safetyRequirements.covidProtocolInstructions.length : 0}/500</div></div>]}
                  handleChangeProps={(e) => handleChange(e.target, 500)}
                  inputRows={10}
                  inputAs="textarea"
                  inputSpellCheck={true}
              />
          </Form>
        </div>
        {!isMobileView &&
          <EditLocationRadioButtons 
              radioLabel="Pets"
              radiolabelDescription="Are pets allowed?"
              radioName="petsAllowed"
              radioOptions={radioOptions}
              radioChecked={safetyRequirements.petsAllowed ? 1 : (safetyRequirements.petsAllowed === false) ? 0 : null}
              onRadioChange={handleChange}
          />
        }  
    </div>
  )
}