import React, { useState } from "react";
import "./LocationSearchMobileView.css";
import LocationSearchResults from "../../components/locationsearch/LocationSearchResults";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import FloatLabelTextBox from "../../components/FloatLabelTextBox";
import FloatLabelDropdown from "../../components/FloatLabelDropdown";
import { InputGroup } from "react-bootstrap";
import { FormGroup } from "@material-ui/core";
import USStates from '../../constants/USStates';
import { Button } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function LocationSearchMobileView(props){

    const [showNavBar, setShowNavBar] = useState(false);

    function handleLocationTypesMenuStateChange(isOpen){
        setShowNavBar(isOpen);
        if(isOpen){
            window.scrollTo(0,0);
        }
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }

    return(
        <div className="location-search-mobile">
            <div className="location-search-body-mobile" hidden={props.showNoResults}>
                <div className="location-search-title-mobile">Search</div>
                <div style={{ height: "30px" }}>
                    <div className="search-type-toggle-wrapper">
                        <input
                            type="radio"
                            className="search-type-toggle"
                            name="searchLocations"
                            value="name"
                            id="searchByName"
                            onChange={(e) => { props.handleToggle(e) }}
                            checked={props.searchType === "name"}
                        />
                        <label htmlFor="searchByName">Location Name</label>
                        <input
                            type="radio"
                            className="search-type-toggle"
                            name="searchLocations"
                            value="address"
                            id="searchByAddress"
                            onChange={(e) => { props.handleToggle(e) }}
                            checked={props.searchType === "address"}
                        />
                        <label htmlFor="searchByAddress">Address</label>
                    </div>
                    <Navbar collapseOnSelect expand="md" expanded={showNavBar}>
                        <button type="button" aria-label="Toggle navigation" className="navbar-toggler collapsed" onClick={() => handleLocationTypesMenuStateChange(true)}>
                            <TuneIcon style={{ color: "#68368B", transform: "scale(1.1)" }} />
                        </button>
                        <Navbar.Collapse className="navbar-items-container">
                            <Nav className="navbar-items">
                                <div className="navbar-items-header">
                                    <div className="location-types-filter-header">Filter By</div>
                                    <div className="close-icon location-types-filter-header" onClick={() => handleLocationTypesMenuStateChange(false)}><CloseIcon /></div>
                                </div>
                                <div className="location-types-filter-body">
                                {
                                    Object.keys(props.locationTypes).map((item, i) => 
                                        <div key={i} className="nav-link">
                                            <label key={i} className="filter-check-container">{props.locationTypes[item]}
                                                <input type="checkbox" name="filterOptions" value={item} onChange={(e) => { props.handleFilterToggle(e) }} className="filter-checkbox"></input>
                                                <span className="filter-checkmark"></span>
                                            </label>
                                        </div>
                                    )
                                }
                                </div>                         
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                <div className="location-search-inputbox-container">
                    <div className={props.searchType === "name" ? "input-container" : "input-container hide-box"}>
                        <InputGroup className="search-textbox">
                            <FloatLabelTextBox
                                inputLabel="LOCATION NAME"
                                inputAutoFocus="true"
                                inputType="text"
                                inputName="name"
                                inputPlaceholder="Location Name"
                                inputValue={props.searchCriteria.name}
                                handleChangeProps={props.handleSearchInputChange}
                            />
                        </InputGroup>
                    </div>
                    <div className={props.searchType === "address" ? "input-container" : "input-container hide-box"}>
                        <InputGroup className="search-textbox">
                            <FloatLabelTextBox
                                inputLabel="ADDRESS"
                                inputAutoFocus="true"
                                inputType="text"
                                inputName="address"
                                inputPlaceholder="Address"
                                inputValue={props.searchCriteria.address}
                                handleChangeProps={props.handleSearchInputChange}
                            />
                        </InputGroup>
                    </div>
                    <div className="input-container">
                        <InputGroup className="search-textbox">
                            <FloatLabelTextBox
                                inputLabel="CITY"
                                inputAutoFocus="true"
                                inputType="text"
                                inputName="city"
                                inputPlaceholder="City"
                                inputValue={props.searchCriteria.city}
                                handleChangeProps={props.handleSearchInputChange}
                            />
                        </InputGroup>
                    </div>
                    <div className="input-container">
                        <FormGroup className="search-dropdown">
                            <FloatLabelDropdown
                                inputLabel="STATE"
                                inputAutoFocus="false"
                                inputType="text"
                                inputName="state"
                                inputPlaceholder="State"
                                inputValue={props.searchCriteria.state}
                                handleChangeProps={props.handleSearchInputChange}
                                inputAs="select"
                                selectOptions={USStates}
                                defaultOption="State"
                                inputRequired={true}                               
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="btn-container-mobile">
                    <Button
                        type="submit"
                        className="btn-submit"
                        onClick={(e) => { props.handleSearch(e) }}
                        disabled={
                            ((props.searchType === "address") ?
                                ((props.searchCriteria.address.length === 0 || props.searchCriteria.city.length === 0 || props.searchCriteria.state.length === 0) ? true : false)
                                :
                                ((props.searchCriteria.name.length === 0 && props.searchCriteria.city.length === 0 && props.searchCriteria.state.length === 0) ? true : false)
                            )
                        }
                    >
                        Search
                    </Button>
                    <Button
                        type="submit"
                        className="btn-cancel"
                        onClick={(e) => { props.handleSearchInputClear(e) }}
                        disabled={(props.searchCriteria.address.length === 0 && props.searchCriteria.name.length === 0 && props.searchCriteria.city.length === 0 && props.searchCriteria.state.length === 0 && props.searchCriteria.locationTypeIds.length === 0) ? true : false}
                    >
                        Clear All
                    </Button>
                </div>
            </div>
            <div hidden={!props.showNoResults}>
                <div className="location-search-title-mobile results-header-mobile">                  
                    Results <span className="num-of-results-text"> displaying top {props.numberOfRecordsToShow} results </span>              
                </div>
                <div className="btn-back-mobile">
                    <ArrowBackIcon onClick={props.handleSearchInputClear} />
                    <span>Back</span>
                </div> 
                <LocationSearchResults
                    loading={props.loading}
                    locations={props.locations}
                    error={props.error}
                    numberOfRecordsToShow={props.numberOfRecordsToShow}
                    searchResultMessageToDisplay={props.searchResultMessageToDisplay}
                    setLocationDetailsButtonStyle={props.setLocationDetailsButtonStyle}
                    subNameMatch={props.subNameMatch}
                    locationTypes={props.locationTypes}
                    showNoResults={props.showNoResults}
                    handleSearchInputClear={props.handleSearchInputClear}
                />
            </div>
        </div>
    )

}