import React from "react";
import AmenitiesIcon from '../../images/location-details/Amenities.svg';
import Icon_Yes from '../../images/location-details/Icon_Yes.svg';
import Icon_No from '../../images/location-details/Icon_No.svg';
import Icon_Null from '../../images/location-details/Icon_Null.svg';
import "./LocationDetailsComponents.css";

export default function LocationAmenities(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img src={AmenitiesIcon} alt="" /></div>
            <div className="location-details-body"><p className='location-details-label'>Amenities</p>
                <div>
                {props.restrooms === true && <div style={{ marginRight: 30 }}><p><span className='amenity-label-yes'>Restrooms</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>}
                {props.restrooms === false && <div style={{ marginRight: 30 }}><p><span className='amenity-label-no'>Restrooms</span><img className='amenity-img' src={Icon_No} alt="" /></p></div>}
                {props.restrooms === null && <div style={{ marginRight: 30 }}><p><span className='amenity-label-null'>Restrooms</span><img className='amenity-img' src={Icon_Null} alt="" /></p></div>}

                {props.overnightParking === true && <div style={{ marginRight: 30 }}><p><span className='amenity-label-yes'>Overnight Parking</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>}
                {props.overnightParking === false && <div style={{ marginRight: 30 }}><p><span className='amenity-label-no'>Overnight Parking</span><img className='amenity-img' src={Icon_No} alt="" /></p></div>}
                {props.overnightParking === null && <div style={{ marginRight: 30 }}><p><span className='amenity-label-null'>Overnight Parking</span><img className='amenity-img' src={Icon_Null} alt="" /></p></div>}

                {props.breakroom === true && <div style={{ marginRight: 30 }}><p><span className='amenity-label-yes'>Breakroom</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>}
                {props.breakroom === false && <div style={{ marginRight: 30 }}><p><span className='amenity-label-no'>Breakroom</span><img className='amenity-img' src={Icon_No} alt="" /></p></div>}
                {props.breakroom === null && <div style={{ marginRight: 30 }}><p><span className='amenity-label-null'>Breakroom</span><img className='amenity-img' src={Icon_Null} alt="" /></p></div>}

                {props.vendingMachine === true && <div style={{ marginRight: 30 }}><p><span className='amenity-label-yes'>Vending Machines</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>}
                {props.vendingMachine === false && <div style={{ marginRight: 30 }}><p><span className='amenity-label-no'>Vending Machines</span><img className='amenity-img' src={Icon_No} alt="" /></p></div>}
                {props.vendingMachine === null && <div style={{ marginRight: 30 }}><p><span className='amenity-label-null'>Vending Machines</span><img className='amenity-img' src={Icon_Null} alt="" /></p></div>}

                {props.showers === true && <div style={{ marginRight: 30 }}><p><span className='amenity-label-yes'>Showers</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>}
                {props.showers === false && <div style={{ marginRight: 30 }}><p><span className='amenity-label-no'>Showers</span><img className='amenity-img' src={Icon_No} alt="" /></p></div>}
                {props.showers === null && <div style={{ marginRight: 30 }}><p><span className='amenity-label-null'>Showers</span><img className='amenity-img' src={Icon_Null} alt="" /></p></div>}

                {props.wiFi === true && <div style={{ marginRight: 30 }}><p><span className='amenity-label-yes'>Wi-Fi</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>}
                {props.wiFi === false && <div style={{ marginRight: 30 }}><p><span className='amenity-label-no'>Wi-Fi</span><img className='amenity-img' src={Icon_No} alt="" /></p></div>}
                {props.wiFi === null && <div style={{ marginRight: 30 }}><p><span className='amenity-label-null'>Wi-Fi</span><img className='amenity-img' src={Icon_Null} alt="" /></p></div>}
                </div>
                {props.additionalAmenities?.length > 0 ? (
                    <div className="sub-container" style={{ lineHeight: 'normal' }}>
                        <p className='sub-title' style={{ marginBottom: '2%' }}>Additional Amenity Information</p>
                        {props.additionalAmenities.map((item, index) => (
                            <div key={index} style={{ marginRight: 30 }}><p><span className='amenity-label-yes'>{item.amenityName}</span><img className='amenity-img' src={Icon_Yes} alt="" /></p></div>
                        ))}
                    </div>
                ) : <></>
                }
            </div>
        </div>
    )
}