import React from "react";
import "./EditLocationDetailsMobile.css";
import "../AddEntityModal.css";
import { Button } from "react-bootstrap";
import CreateIcon from '@material-ui/icons/Create';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditAmenities from "../editlocationdetails/EditAmenities";
import EditServices from "../editlocationdetails/EditServices";
import EditSafetyRequirements from "../editlocationdetails/EditSafetyRequirements";
import EditProcedures from "../editlocationdetails/EditProcedures";
import ConfirmationModal from "../ConfirmationModal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RateLocation from "./RateLocation";

export default function EditLocationDetailsMobile(props) {

    function handleSubmit(){
        window.scrollTo(0,0);
        props.handleSubmit();
    }

    return ( 
            <>
                <div className="location-search-title-mobile results-header-mobile">                  
                    {props.activeSection === "" ? "Review Location" : props.editContainerHeader}              
                </div>
                <div className="btn-back-mobile">
                    <ArrowBackIcon onClick={(e) => props.handleCancel(e)} />
                    <span>Back</span>
                </div>
                <div className="edit-container">
                    <div className="edit-container-body">
                        <div className="edit-sections" hidden={props.activeSection !== "none"}>
                            <div className={props.activeSection === "rateLocation" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("rateLocation")}>Rate Location<ArrowForwardIcon className="detail-arrow" /></div>
                            <div className={props.activeSection === "amenities" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("amenities")}>Amenities<ArrowForwardIcon className="detail-arrow" /></div>
                            <div className={props.activeSection === "services" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("services")}>Services<ArrowForwardIcon className="detail-arrow" /></div>
                            <div className={props.activeSection === "safetyRequirements" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("safetyRequirements")}>Safety Requirements<ArrowForwardIcon className="detail-arrow" /></div>
                            <div className={props.activeSection === "procedures" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("procedures")}>Procedures<ArrowForwardIcon className="detail-arrow" /></div>
                        </div>
                        <div className="edit-details-container" hidden={props.activeSection === "none"}>
                            <div className="edit-details-header">{props.editContainerHeader}</div>
                            {props.activeSection === "rateLocation" ? (<RateLocation review={props.edits.review} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "amenities" ? (<EditAmenities amenities={props.edits.amenities} additionalAmenities={props.originalData.additionalAmenities} additionalAmenitiesEdits={props.edits.otherAmenities} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "services" ? (<EditServices services={props.edits.services} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "safetyRequirements" ? (<EditSafetyRequirements safetyRequirements={props.edits.safetyRequirements} additionalEquipments={props.originalData.additionalSafetyRequirements} additionalEquipmentsEdits={props.edits.otherSafetyRequirements} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "procedures" ? (<EditProcedures procedures={props.edits.procedures} handleChange={props.handleEdits} />) : (<></>)}
                        </div>
                    </div>
                    <div className="btn-container-mobile" style={{ paddingBottom: "6%" }} hidden={props.activeSection === "none"}>
                        <Button
                            type="submit"
                            className="btn-submit"
                            disabled={props.disableSubmit} 
                            onClick={() => handleSubmit()}                        
                        >
                            Submit
                        </Button>
                        <Button
                            type="submit"
                            className="btn-cancel"
                            onClick={(e) => props.handleCancel(e)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <ConfirmationModal modalTitle={props.confirmationModalTitle} modalHeader={props.confirmationModalHeader} confirmationButtonName={props.confirmationButtonName} show={props.confirmationModalShow}
                        onHide={() => props.setConfirmationModalShow(false)} onConfirmation={props.handleConfirmation} />
                </div>
            </>
    )
}