import React from "react";
import LocationTypeIcon from '../../images/location-details/LocationTypeIcon.svg';
import "./LocationDetailsComponents.css";

export default function LocationType(props) {
    return (
        <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="location-details-icon"><img src={LocationTypeIcon} alt="" /></div>
            <div className="location-details-body"><p className='location-details-label'>Location Type</p>
                <p>{props.locationTypeDescription}</p></div>
        </div>
    )
}