import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import EditLocationRadioButtons from "../editlocationdetails/EditLocationRadioButtons";
import LocationProcedures from "../locationdetails/LocationProcedures";
import ApproveDenyAllToggle from "./ApproveDenyAllToggle";

export default function PendingProceduresEdits(props) {
  const pendingEdits = useMemo(() => { return props.pendingEdits }, [props]);

  function handleApproveDeny(target){
    props.handleApproveDeny(target, "procedures");
  }

  function handleReviewedAllCheck(checked){
    props.handleReviewedAllCheck(checked, "procedures");
  }
  
  return (
    <div className="edit-details-body">
      <div className="edit-details-title">
        Original Procedures
        <div className="original-data-body"> 
          {<LocationProcedures procedures={props.originalProcedures} />}
        </div>
      </div>     
      <div className="pending-edits-body-margin">
        {pendingEdits?.length > 0 ? (
            <>
            <div className="edit-details-title">Procedures Edits</div>
            <ApproveDenyAllToggle 
              handleApproveDenyAll={handleApproveDeny} 
              reviewedAll={props.reviewedAll.reviewedAll} 
              approveDenyAll={props.reviewedAll.approvedAll}
              handleReviewedAllCheck={handleReviewedAllCheck}
            />
            <div className="location-pending-edits-table">
                <Table className="floating-table edits-table-content-align-top">
                    <thead>
                        <tr>
                          <th className="edits-table-th-first">Editor Username</th>                           
                            <th>Time Stamp ({props.userTimeZone})</th>
                            <th className="edited-content">Procedures</th>                      
                            <th style={{ textAlign: "center"}}>Review Edits</th>
                        </tr>
                    </thead>               
                    <tbody>
                            {pendingEdits.map((item, index) =>
                            <tr key={item.editId}>
                                <td className="edits-table-td-first">{item.editAddedBy}</td>                                
                                <td>{props.convertTimeToLocal(item.editAddedDate)}</td>
                                <td className="edited-content">
                                  <LocationProcedures procedures={item} />
                                </td>
                                <td style={{ textAlign: "center"}}>
                                  <EditLocationRadioButtons 
                                      radioName={item.editId}
                                      radioIndex={index}
                                      radioOptions={props.toggleOptions}
                                      radioChecked={item.approved !== undefined ? item.approved : null}
                                      defaultValue={null}
                                      useToggle={true}
                                      onRadioChange={handleApproveDeny}
                                  />
                                </td>
                            </tr>
                        )}
                    </tbody>
                    </Table>
                </div>
            </>
        ) :
        (
            <div className="edit-details-title">
                *** No Edits to review ***
            </div>
        )}       
      </div>
    </div>
  );
}