import React from "react";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { useSessionContext } from "../libs/sessionLib";

export default function AdminRoutes({ children, ...props }) {
  const { session } = useSessionContext();
  return (
    <Route
      {...props}
      render={props =>(
        ((session !== undefined) && session.isInternalUser && session.roleId === 1) ?
          children :
          <Redirect to={{ pathname: '/' }} />
      )}
    />
  );
}
