import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useSessionContext } from "../libs/sessionLib";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import { onError } from "../libs/errorLib";
import useAxios from 'axios-hooks';
import "./Company.css";
import CompanyDetails from "../components/company/CompanyDetails";
import CompanyUsers from "../components/company/CompanyUsers";
import CompanyLocations from "../components/company/CompanyLocations";
import { useAmplitude } from '../services/Amplitude';

export default function Company() {

    const { session } = useSessionContext();
    let { id } = useParams();
    const amplitude = useAmplitude();

    const api_company_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/company/" + id : API_BASE_URL + "/company/" + id;
    const [{ data: company, loading, error }, fetchData] = useAxios(
        {
            url: api_company_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    useEffect(() => {
        getData();
        amplitude.logEvent("Company Page View");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getData() {
        try {
            fetchData();
        } catch (e) {
            onError("Error getting location details");
        }
    }

    return (
        company ? (
            <div className="company-container">
                <div className="company-info-container">
                    <div className='company-header'><p>{company.companyName}</p></div>
                    <CompanyDetails company={company}></CompanyDetails>
                    <div className='company-header'><p>Company Directory</p></div>
                    <CompanyUsers id={id}></CompanyUsers>
                </div>
                <div className="company-locations-container">
                    <CompanyLocations id={id}></CompanyLocations>
                </div>
            </div>
        ) : (<></>)
    );
}