export const API_BASE_URL = process.env.REACT_APP_LOCATION_INTEL_URL;
export const ACCESS_TOKEN_NAME = process.env.REACT_APP_LOCATION_INTEL_API_KEY;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const LOOKER_BASE_URL = process.env.REACT_APP_LOOKERSDK_BASE_URL;
export const LOOKER_VERIFY_SSL = process.env.REACT_APP_LOOKERSDK_VERIFY_SSL;
export const LOOKER_TIMEOUT = process.env.REACT_APP_LOOKERSDK_TIMEOUT;
export const LOOKER_CLIENT_ID = process.env.REACT_APP_LOOKERSDK_CLIENT_ID;
export const LOOKER_CLIENT_SECRET = process.env.REACT_APP_LOOKERSDK_CLIENT_SECRET;
export const AMPLITUDE_STG_API_KEY = process.env.REACT_APP_AMPLITUDESDK_STG_API_KEY;
export const AMPLITUDE_PROD_API_KEY = process.env.REACT_APP_AMPLITUDESDK_PROD_API_KEY;
export const LOCATION_DATA_API_URL = process.env.REACT_APP_LOCATION_DATA_API_URL;
export const LOCATION_DATA_API_KEY = process.env.REACT_APP_LOCATION_DATA_API_KEY;