import Cookies from "js-cookie";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSessionContext } from "../libs/sessionLib";

export default function Logout() {
    const history = useHistory();
    const { setSession } = useSessionContext();
    useEffect(() => {
        Cookies.remove("session");
        setSession(undefined);
        history.push("/login");
    });
    return null;
}