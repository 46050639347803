import React, { useEffect, useMemo, useState } from "react";
import { FormGroup } from "react-bootstrap";
import FloatLabelDropdown from "../FloatLabelDropdown";
import FloatLabelTextBox from "../FloatLabelTextBox";
import { validate } from 'react-email-validator';
import "./EditContacts.css";
import FloatLabelPhoneNumber from "../FloatLabelPhoneNumber";

export default function EditContacts(props) {
    const propsContacts = useMemo(() => { return props.contacts }, [props]);
    const [contacts, setContacts] = useState(propsContacts);
    const [validContact, setValidContact] = useState(propsContacts);
    const [contactType, setContactType] = useState(props.contactType);
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [phoneIsValid, setPhoneIsValid] = useState(true);
    const [helperText, setHelperText] = useState({
        emailAddress: "",
        phone: ""
    });
    const [errorClass, setErrorClass] = useState({
        emailAddress: "",
        phone: ""
    });
    const tempContactTypes = [
        {
            "id": 1,
            "name": "Main Contact"
        }
    ];    

    function handleChange(e) {
        //update this when allowing more than 1 contact
    }

    function onChangeFormGroupInput(e, length, type) {
        var inputtedValue = e.target.value;
        if (type === "phone") {
            inputtedValue = inputtedValue.replace(/\D/g, '');
        }
        if (inputtedValue.length <= length) {
            if (type === "emailAddress") {
                if (validate(inputtedValue) || inputtedValue.length === 0) {
                    setValidContact({
                        ...contacts,
                        "emailAddress": inputtedValue
                    });                    

                    setHelperText({
                        ...helperText,
                        "emailAddress": ""
                    });
                    setErrorClass({
                        ...errorClass,
                        "emailAddress": ""
                    });
                }
                else {
                    setHelperText({
                        ...helperText,
                        "emailAddress": "Please enter a valid email address"
                    });
                    setErrorClass({
                        ...errorClass,
                        "emailAddress": "bh-error"
                    });
                }
                setContacts({
                    ...contacts,
                    "emailAddress": inputtedValue
                });
            }
            else {
                if(validatePhoneNumber(inputtedValue) || inputtedValue.length === 0){
                    setValidContact({
                        ...contacts,
                        "phone": inputtedValue
                    });   

                    setHelperText({
                        ...helperText,
                        "phone": ""
                    });
                    setErrorClass({
                        ...errorClass,
                        "phone": ""
                    });
                }
                else {
                    setHelperText({
                        ...helperText,
                        "phone": "Please enter a valid phone number"
                    });
                    setErrorClass({
                        ...errorClass,
                        "phone": "bh-error"
                    });
                }

                setContacts({
                    ...contacts,
                    "phone": inputtedValue
                });              
            }
        }
        else {
            setErrorClass({
                ...errorClass,
                [type]: "bh-error"
            });
            setHelperText({
                ...helperText,
                [type]: "The max length of " + length + " characters is reached."
            });
        }
    }

    function validatePhoneNumber(elementValue) {
        var phonePattern = /^\+?([0-9]{0,2})\)?[- ]?([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$/;
        return phonePattern.test(elementValue);
    }

    useEffect(() => {
        //if (contacts && (contacts.emailAddress.length === 0 || emailIsValid) && (contacts.phone.length === 0 || phoneIsValid)){
            props.handleChange("contacts", [validContact]);
        //}        
    }, [contacts]);

    return (
        <div className="edit-details-body">
            <div className="edit-details-description" style={{ marginBottom: '10px' }}>
                Please select contact type, and fill out information below. Please fill in either email or phone, or both.
            </div>
            {contacts ? (
                <div className="edit-details-body-padding">
                    <div className="contacts-container">
                        <FormGroup className="contact-type-dropdown">
                            <FloatLabelDropdown
                                inputLabel="CONTACT TYPE"
                                inputAutoFocus="false"
                                inputType="text"
                                inputName="contactTypeId"
                                inputPlaceholder="Contact Type"
                                inputValue="1" //temp value until we allow multiple contacts
                                handleChangeProps={handleChange}
                                inputAs="select"
                                selectOptions={tempContactTypes}
                                //defaultOption="Main Contact"
                                inputDisabled={true}
                            />
                        </FormGroup>
                    </div>
                    <div className="contacts-container">
                        <FloatLabelTextBox
                            inputLabel="EMAIL"
                            inputAutoFocus="true"
                            inputType="text"
                            inputName="emailAddress"
                            inputValue={contacts.emailAddress}
                            inputPlaceholder="Email"
                            inputClassName={`${errorClass.emailAddress}`}
                            inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.emailAddress}</div><div style={{ float: "right" }}>{contacts.emailAddress ? contacts.emailAddress.length : 0}/50</div></div>]}
                            handleChangeProps={(e) => onChangeFormGroupInput(e, 50, "emailAddress")}
                        />
                    </div>
                    <div className="contacts-container">
                        <FloatLabelPhoneNumber
                            inputLabel="PHONE"
                            inputAutoFocus="true"
                            inputType="text"
                            inputName="phone"
                            inputValue={contacts.phone}
                            inputPlaceholder="Phone"
                            inputClassName={`${errorClass.phone}`}
                            inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.phone}</div><div style={{ float: "right" }}>{contacts.phone ? contacts.phone.length : 0}/10</div></div>]}
                            handleChangeProps={(e) => onChangeFormGroupInput(e, 10, "phone")}
                        />
                    </div>
                </div>
            ) : (<></>)}
        </div>
    )
}