import React, { useState } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import MainRoutes from "./routes/MainRoutes";
import "./App.css";
import OmniFooter from "./components/OmniFooter.js";
import { getSessionCookie, SessionContext } from "./libs/sessionLib";
import { ampInstance, AmplitudeContext } from './services/Amplitude';

function App() {
  
  const [session, setSession] = useState(getSessionCookie());
  
  return (
    <AmplitudeContext.Provider value={{ampInstance}}>
      <div className="app-container">
        <div className="App py-3 row content">
          <SessionContext.Provider value={{session, setSession}}>
            <Router>
              <MainRoutes />
            </Router>
          </SessionContext.Provider>
          <OmniFooter />
        </div>    
      </div>
    </AmplitudeContext.Provider>
  );
}
export default App;