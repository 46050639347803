import React from "react";
import { Route } from "react-router";
import { Redirect, useHistory } from "react-router-dom";
import { useSessionContext } from "../libs/sessionLib";

export default function ProtectedRoute({ children, ...props }) {
  const { session } = useSessionContext();
  const history = useHistory();
  
  return (
    <Route
    {...props}
      render={props => (
        (session !== undefined) 
          ?
            (history.location.pathname === "/") ? <Redirect to='/locationsearch' /> : children 
          :
            <Redirect to={{ pathname: (history.location.pathname === "/") ? '/home' : '/login', state: {from: props.location} }} />
      )}
    />
  );
}
