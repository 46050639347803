import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./AuthLayout.css";
import "./NavbarLayout.css";
import "./NavbarMobileLayout.css";
import { LinkContainer } from "react-router-bootstrap";
import OmnitracsLogo from '../images/LocationIntelligence.svg';

function AuthLayout({ children }) {
    return (
        <>
            <div className="AuthLayout container py-3">
                <Navbar collapseOnSelect expand="md" className="mb-3 login-nav">
                    <LinkContainer to="/">
                        <Navbar.Brand href="/" className="font-weight-bold text-muted">
                            <img src={OmnitracsLogo} alt="" />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="navbar-items-container">
                        <Nav className="navbar-items">
                            <LinkContainer to="/login">
                                <Nav.Link>Login</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="Auth-Layout-Body">
                    {children}
                </div>
            </div>
        </>
    );
}
export default AuthLayout;