import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setSessionCookie, useSessionContext } from "../libs/sessionLib";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks';
import NewIntegrationUserModal from "../components/NewIntegrationUserModal.js"
import { Alert } from "react-bootstrap";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function IntegrationAuthLander() {
    const history = useHistory();
    const { setSession } = useSessionContext();
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const recID = queryParams.get('recID');
    const environment = queryParams.get('environment');
    const appname = queryParams.get('appname');
    const [integrationID, setIntegrationID] = useState("");

    const [notAuthorized, setNotAuthorized] = useState(false);
    const [notAuthorizedMessage, setNotAuthorizedMessage] = useState("You are not authorized to view this page.");

    const [modalShow, setModalShow] = useState(false);

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");

    const api_auth_user_url = ENVIRONMENT === "development" ? "/api/user/token" : API_BASE_URL + "/user/token";
    const [{ data, loading, error }, authorizeUser] = useAxios(
        {
            url: api_auth_user_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    useEffect(() => {
        authorizeThirdPartyUser();
    }, []);

    async function authorizeThirdPartyUser() {

        try {
            const payload = {
                "token": token,
                "environment": environment,
                "appname": appname
            }

            const resp = await authorizeUser({
                data: {
                    ...payload
                }
            });

            if (resp) {
                if (resp.status === 200) {
                    setNotAuthorized(false);
                    if (resp.data) {
                        if (resp.data.id) {
                            //user exists
                            setSessionCookie(resp.data);
                            setSession(resp.data);
                            history.push("/locationdetails/" + recID);
                        }
                        else {
                            //user doesnt exist
                            setIntegrationID(resp.data);
                            openModal();
                        }
                    }
                }
                else {
                    // not authorized to be here
                    setNotAuthorized(true);
                }
            }
        } catch (e) {
            //console.log(e)

            let message = e.toString();

            // Auth errors
            if ((e instanceof Error) && e.message && e.response?.status === 400) {
                message = "The login information entered does not match our records. Please contact your integration provider.";
            } else {
                message = "Something went wrong. Please try again later."
            }
            setNotAuthorizedMessage(message);
            setNotAuthorized(true);
        }
    }

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                setNotAuthorizedMessage("You are not authorized to view this page.");
            } else if (error.request) {
                // client never received a response, or request never left
                setNotAuthorizedMessage("Error authorizing user");
            } else {
                // anything else
                setNotAuthorizedMessage("Error authorizing user");
            }
            setNotAuthorized(true);
        }
    }, [error]);

    function openModal() {
        setModalShow(true);
    }

    function redirectOnClose(userObj, successMessage) {
        setShowSuccessAlert(true);
        setSuccessAlertMessage(successMessage);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
        setModalShow(false);
        setSessionCookie(userObj);
        setSession(userObj);
        history.push("/locationdetails/" + recID);
    }

    return (
        <>
            {notAuthorized ? (
                <div className="text-center">
                    <h3>{notAuthorizedMessage}</h3>
                </div>
            ) : (
                <>
                    <NewIntegrationUserModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={redirectOnClose} integrationID={integrationID} appName={appname} />
                    <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                        <CheckCircleIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>User Submitted.</Alert.Heading>
                            <p>{successAlertMessage}</p>
                        </div>
                    </Alert>
                </>
            )}
        </>
    )
}