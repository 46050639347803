import React, { useEffect, useState } from "react";
import useAxios from 'axios-hooks'
import { Alert, Button, Form, FormControl, InputGroup, Table } from "react-bootstrap";
import { FormGroup } from "@material-ui/core";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../../constants/apiConstants.js';
import { onError } from "../../libs/errorLib";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "../../pages/InternalAdminPortal.css";
import USStates from '../../constants/USStates';
import ClearIcon from '@material-ui/icons/Clear';
import PendingEditsModal from "./PendingEditsModal";

export default function PendingLocationEditList(props) {
    const api_url = ENVIRONMENT === "development" ? "/api/location/edits/pending-edits" : API_BASE_URL + "/location/edits/pending-edits";
    const [{ data: locations, loading, error }, fetchData] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );
    const [locationsWithEdits, setLocationsWithEdits] = useState([]);
    const [displayMessage, setDisplayMessage] = useState("...loading");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [searchType, setSearchType] = useState("name");
    const [searchCriteria, setSearchCriteria] = useState({
        locationName: "",
        address: "",
        city: "",
        state: ""
    });
    const [modalShow, setModalShow] = useState(false);
    const [locationEditsInfo, setLocationEditsInfo] = useState({
        locationId: 0,
        totalPendingEdits: 0,
        editsCountBySection : null
    });

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        try {
            fetchData({
                url: api_url
            });

        } catch (e) {
            onError("Error getting pending location edit list");
            setDisplayMessage("Error getting pending location edit list");
        }
    };

    useEffect(() => {
        setLocationsWithEdits(locations);
        if(locations) props.renderLocationWithEditsCount(locations.length);
        else if(locations === null) setDisplayMessage("No pending edits to review.");
    }, [locations]);

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
                setDisplayMessage(message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error getting location list with pending edits.");
                setDisplayMessage("Error getting location list with pending edits.");
            } else {
                // anything else
                triggerErrorAlert(true, "Error getting location list with pending edits.");
                setDisplayMessage("Error getting location list with pending edits.");
            }
        }        
    }, [error]);

    const handleChange = (e) => {
        setSearchCriteria({
            ...searchCriteria,
            [e.target.id]: e.target.value
        });
    }

    function handleSearch(){
        if (searchCriteria.locationName.length > 2 || searchCriteria.address.length > 2 || searchCriteria.city.length > 2 || searchCriteria.state.length > 0){
            var filteredLocations = locations;
            if(searchCriteria.locationName.length > 2){
                filteredLocations = filteredLocations.filter(x => (x.locationName != null && x.locationName.toLowerCase().includes(searchCriteria.locationName.toLowerCase())));
            }
            else if(searchCriteria.address.length > 2){
                filteredLocations = filteredLocations.filter(x => (x.address.toLowerCase().includes(searchCriteria.address.toLowerCase())));
            }

            if(searchCriteria.city.length > 2){
                filteredLocations = filteredLocations.filter(x => (x.city.toLowerCase().includes(searchCriteria.city.toLowerCase())));
            }
            if(searchCriteria.state.length > 0){
                filteredLocations = filteredLocations.filter(x => (x.state.toLowerCase().includes(searchCriteria.state.toLowerCase())));
            }
            setLocationsWithEdits(filteredLocations);
            if(filteredLocations.length === 0 && locations) setDisplayMessage("No pending edits to review for given filter values.")
        }        
        else{
            setLocationsWithEdits(locations);
            triggerErrorAlert(true, "A minimum of 3 characters is required in at least one search field");
        }
    }
    
    function handleClear(){
        setLocationsWithEdits(locations);
        setSearchCriteria({
            locationName: "",
            address: "",
            city: "",
            state: ""
        });
    }

    function handleTextboxClear(id) {
        setSearchCriteria({
            ...searchCriteria,
            [id]: ""
        });        
    }

    function handleToggle(e) {
        setSearchType(e.target.value)
        handleClear();
    }

    function openModal(editsInfo) {
        mapPendingEditsCount(editsInfo);
        setModalShow(true);
    }

    let pendingEditsCountBySection = {
        'locationName' : 0,
        'geoFence': 0,
        'locationType': 0,
        'contacts': 0,
        'amenities': 0,
        'businessHours': 0,
        'closures': 0,
        'appointmentScheduling': 0,
        'services': 0,
        'safetyRequirements': 0,
        'procedures': 0,
    }
    function mapPendingEditsCount(editsInfo){
        editsInfo.editsBySection?.forEach(element => {
            switch(element.name){
                case 'Location Names' : pendingEditsCountBySection.locationName = element.pendingEdits; break;
                case 'Geofence': pendingEditsCountBySection.geoFence = element.pendingEdits; break;
                case 'Location Type': pendingEditsCountBySection.locationType = element.pendingEdits; break;
                case 'Contacts': pendingEditsCountBySection.contacts = element.pendingEdits; break;
                case 'Amenities': pendingEditsCountBySection.amenities = element.pendingEdits; break;
                case 'Business Hours': pendingEditsCountBySection.businessHours = element.pendingEdits; break;
                case 'Closures': pendingEditsCountBySection.closures = element.pendingEdits; break;
                case 'Appointment Scheduling': pendingEditsCountBySection.appointmentScheduling = element.pendingEdits; break;
                case 'Services': pendingEditsCountBySection.services = element.pendingEdits; break;
                case 'Safety Requirements': pendingEditsCountBySection.safetyRequirements = element.pendingEdits; break;
                case 'Procedures': pendingEditsCountBySection.procedures = element.pendingEdits; break;
                default: break;
            }
        });
        setLocationEditsInfo({
            ...locationEditsInfo,
            locationId: editsInfo.locationId,
            totalPendingEdits: editsInfo.totalPendingEdits,
            editsBySection: pendingEditsCountBySection
        })  
    }

    function handleModalClose(){
        setModalShow(false);
        setLocationEditsInfo(null);
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
        getData();
    }

    return (
        <>
            <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                <CheckCircleIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{successAlertMessage}</p>
                </div>
            </Alert>
            <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                <ErrorIcon />
                <div className="floating-alert-body">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{errorAlertMessage}</p>
                </div>
            </Alert>
            <div className="table-search-header pending-edits" style={{ height: "125px"}}>
                <div className="search-type-toggle-wrapper">
                    <input
                        type="radio"
                        className="search-type-toggle"
                        name="searchLocations"
                        value="name"
                        id="searchByName"
                        onChange={(e) => { handleToggle(e) }}
                        checked={searchType === "name"}
                    />
                    <label htmlFor="searchByName">Location Name</label>
                    <input
                        type="radio"
                        className="search-type-toggle"
                        name="searchLocations"
                        value="address"
                        id="searchByAddress"
                        onChange={(e) => { handleToggle(e) }}
                        checked={searchType === "address"}
                    />
                    <label htmlFor="searchByAddress">Address</label>
                </div>
                <Form inline>
                    <InputGroup className={searchType === "name" ? "search-box" : "search-box hide-box"}>
                        <FormControl id="locationName" placeholder="Location Name" value={searchCriteria.locationName} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.locationName.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={() => handleTextboxClear("locationName")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <InputGroup className={searchType === "address" ? "search-box" : "search-box hide-box"}>
                        <FormControl id="address" placeholder="Address" value={searchCriteria.address} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.address.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={() => handleTextboxClear("address")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <InputGroup className="search-box">
                        <FormControl id="city" placeholder="City" value={searchCriteria.city} onChange={(e) => handleChange(e)} />
                        {(searchCriteria.city.length > 0) ? (
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <ClearIcon style={{ color: '#292929', fontSize: 16 }}
                                        onClick={() => handleTextboxClear("city")} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        ) : (<></>)}
                    </InputGroup>
                    <FormGroup className="search-box">
                        <Form.Control
                            id="state"
                            type="text"
                            placeholder="State"
                            value={searchCriteria.state}
                            name="state"
                            onChange={(e) => handleChange(e)}
                            as="select"
                            style={{ width: '95%'}}
                        >
                            <option key="" value="">Select a state</option>
                            {USStates.map((item, index) =>
                                <option key={index} value={item.abbreviation}>{item.name}</option>
                            )}
                        </Form.Control>
                    </FormGroup>
                    <Button className="search-button" onClick={() => { handleSearch() }}>
                        <SearchIcon style={{ paddingRight: '5px' }} />Search
                    </Button>
                    <Button
                        variant="link"
                        className="clear-button"
                        onClick={() => { handleClear() }}
                        disabled={(searchCriteria.locationName.length === 0 && searchCriteria.city.length === 0 && searchCriteria.state.length === 0) ? true : false}
                    >
                        Clear Search
                    </Button>
                </Form>
            </div>
            <div className="list-body">
                <Table className="floating-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Location Name</th>
                            <th>Location ID</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State/Province</th>
                            <th>Zip/Postal</th>
                            <th style={{ textAlign: "center", paddingRight: '4%'}}>Review Edits</th>
                        </tr>
                    </thead>
                    <tbody>
                    {(loading || error || !locationsWithEdits || (locationsWithEdits && locationsWithEdits.length === 0)) ? ( 
                        <tr>
                            <td colSpan="8" style={{ textAlign: 'center', borderRadius: '8px' }}><div>{displayMessage}</div></td>
                        </tr>)
                    : (locationsWithEdits.map(item =>
                        <tr key={`${item.locationId}`}>
                            <td><MoreVertIcon className="moreVerIcon" /></td>
                            <td className="table-content-ellipsis">
                                {                                                    
                                    <a href={"/locationdetails/" + item.locationId} target="_blank" rel="noreferrer">
                                        {item.locationName ? (item.locationName).toLowerCase() : "No Name Available"}
                                    </a>
                                }
                            </td>
                            <td>{item.locationId}</td>
                            <td className="table-content-ellipsis">{item.address}</td>
                            <td>{item.city}</td>
                            <td>{USStates.find(f => f.id === item.state).name}</td>
                            <td>{item.zipCode}</td>
                            <td style={{ textAlign: "center", paddingRight: '4%'}}>
                                <Button className="small-action-button review" onClick={() => { openModal(item) }}>
                                    {"Review (" + (item.totalPendingEdits > 999 ? "999+" : item.totalPendingEdits) + ")"}
                                </Button>
                            </td>
                        </tr>))
                    }
                    </tbody>
                </Table>
            </div>
            <PendingEditsModal show={modalShow} locationEditsInfo={locationEditsInfo} onHide={() => handleModalClose()} onSuccess={triggerSuccessAlert} />
        </>
    );
}