import React, { useEffect, useState } from "react";
import { FormGroup, Form } from "react-bootstrap";
import "./EditAppointmentScheduling.css";
import FloatLabelDropdown from "../FloatLabelDropdown";
import FloatLabelTextBox from "../FloatLabelTextBox";
import FloatLabelPhoneNumber from "../FloatLabelPhoneNumber";

export default function EditAppointmentScheduling(props) {
    const originalAppointmentScheduling = props.originalData;
    const[appointmentScheduling, setAppointmentScheduling] = useState(props.updatedAppointmentScheduling);
    const[updatedAppointmentScheduling, setUpdatedAppointmentScheduling] = useState(props.updatedAppointmentScheduling);                                                                                                                                
    const[appointmentType, setAppointmentType] = useState(updatedAppointmentScheduling && updatedAppointmentScheduling.byAppointment !== null ? (+updatedAppointmentScheduling.byAppointment) : "");
    const visibleClassName = 'visible-container';
    const hideClassName = 'hide-container';
    const[schedulingMethodClassName, setSchedulingMethodClassName] = useState(appointmentType === 1 ? visibleClassName : hideClassName);
    const[websiteClassName, setWebsiteClassName] = useState(appointmentScheduling.websiteAddress?.length > 0 ? visibleClassName : hideClassName);
    const[phoneClassName, setPhoneClassName] = useState(appointmentScheduling.phoneNumber?.length > 0 ? visibleClassName : hideClassName);
    const[emailClassName, setEmailClassName] = useState(appointmentScheduling.emailAddress?.length > 0 ? visibleClassName : hideClassName);
    const typeOptions = [
        {
            "id": 0,
            "name": "FCFS"
        },
        {
            "id": 1,
            "name": "By Appointment Only"
        }
    ];
    const [helperText, setHelperText] = useState({
        websiteAddress: "",
        emailAddress: "",
        phoneNumber: "",
        specialInstructions: "",
    });
    const [errorClass, setErrorClass] = useState({
        websiteAddress: "",
        emailAddress: "",
        phoneNumber: "",
        specialInstructions: "",
    });

    function appointmentTypeChange(e){
        setAppointmentType(e.target.value);
        const byAppointment = (e.target.value === "") ? null : (parseInt(e.target.value) === 1 ? true : false);
        const specialInstructions = (e.target.value === "") ? null : appointmentScheduling.specialInstructions;
        setAppointmentScheduling({
                                    ...appointmentScheduling,
                                    byAppointment : byAppointment,
                                    specialInstructions : specialInstructions, 
                                    });
        if(byAppointment === true && checkedMethodCounter > 0 && helperText.websiteAddress === "" && helperText.phoneNumber === "" && helperText.emailAddress === ""){
            setUpdatedAppointmentScheduling({
                ...appointmentScheduling,
                byAppointment: byAppointment,
                specialInstructions : specialInstructions,
            });
        }
        else if(byAppointment === false){
            setUpdatedAppointmentScheduling({
                ...updatedAppointmentScheduling,
                byAppointment : byAppointment,
                specialInstructions : specialInstructions, 
            });
        }
        else{
            setUpdatedAppointmentScheduling(originalAppointmentScheduling);
        }
                                                                                        
        setSchedulingMethodClassName((parseInt(e.target.value) === 1 ? visibleClassName : hideClassName));
    }

    let checkedMethodCounter = setCheckedMethodCounter(0);
    function setCheckedMethodCounter(counter){
        appointmentScheduling.methodOnline && counter++;
        appointmentScheduling.methodPhone && counter++;
        appointmentScheduling.methodEmail && counter++;
        return counter;
    }

    function handleCheckboxChange(e){
        const target = e.target;
        let errorMsg = "";
        let errorCls = target.checked ? "bh-error" : "";
        checkedMethodCounter = (target.checked) ? ++checkedMethodCounter : --checkedMethodCounter ;
        switch(target.name)
        {
            case 'methodOnline':                                             
                            setAppointmentScheduling({
                                ...appointmentScheduling,
                                methodOnline: target.checked,
                                websiteAddress: !target.checked ? null : appointmentScheduling.websiteAddress
                            });                            
                            setWebsiteClassName(target.checked ? visibleClassName : hideClassName);
                            errorMsg = target.checked ? "Please enter a valid website address." : "";  
                            break;
            case 'methodPhone':          
                            setAppointmentScheduling({
                                ...appointmentScheduling,
                                methodPhone: target.checked,
                                phoneNumber: !target.checked ? null : appointmentScheduling.phoneNumber
                            });                           
                            setPhoneClassName(target.checked ? visibleClassName : hideClassName);
                            errorMsg = target.checked ? "Please enter a valid phone number" : "";                                
                            break;
            case 'methodEmail':          
                            setAppointmentScheduling({
                                ...appointmentScheduling,
                                methodEmail: target.checked,
                                emailAddress: !target.checked ? null : appointmentScheduling.emailAddress
                            });
                            setEmailClassName(target.checked ? visibleClassName : hideClassName);
                            errorMsg = target.checked ? "Please enter a valid email address." : "";
                            break;
            default:        break;
        }
        const isValid = !target.checked && (target.name === "methodOnline" ? helperText.phoneNumber === "" && helperText.emailAddress === "" :
                                            target.name === "methodPhone" ? helperText.websiteAddress === "" && helperText.emailAddress === "" :
                                                                              helperText.websiteAddress === "" && helperText.phoneNumber === "")
        if(isValid && checkedMethodCounter > 0){
            const validatedData = appointmentScheduling;
            setUpdatedAppointmentScheduling({
                byAppointment: true,
                methodOnline: target.name === "methodOnline" ? false : validatedData.methodOnline,
                methodPhone: target.name === "methodPhone" ? false : validatedData.methodPhone,
                methodEmail: target.name === "methodEmail" ? false : validatedData.methodEmail,
                websiteAddress: validatedData.websiteAddress,
                phoneNumber: validatedData.phoneNumber,
                emailAddress: validatedData.emailAddress,
                specialInstructions: validatedData.specialInstructions,
            });
        }
        else{
            setUpdatedAppointmentScheduling(originalAppointmentScheduling);
        }

        const originalHelperText = helperText;
        const originalErrorClass = errorClass;
        setHelperText({
            ...helperText,
            websiteAddress: target.name === "methodOnline" ? errorMsg : originalHelperText.websiteAddress,
            phoneNumber: target.name === "methodPhone" ? errorMsg : originalHelperText.phoneNumber,
            emailAddress: target.name === "methodEmail" ? errorMsg : originalHelperText.emailAddress,
        });
        setErrorClass({
            ...errorClass,
            websiteAddress: target.name === "methodOnline" ? errorCls : originalErrorClass.websiteAddress,
            phoneNumber: target.name === "methodPhone" ? errorCls : originalErrorClass.phoneNumber,
            emailAddress: target.name === "methodEmail" ? errorCls : originalErrorClass.emailAddress,
        });                  
    }

    function handleInputChange(e, maxLength){
        const target = e.target;
        var inputValue = target.value;
        let errorMsg = "";
        let errorCls = "";
        
        //Validate inputs and update data for edits object
        if (target.name === "phoneNumber") {
            inputValue = inputValue.replace(/\D/g, '');
        }
        if(inputValue?.length > maxLength)
        {           
            errorMsg = "The max length of " + maxLength + " characters is reached.";
            errorCls =  "bh-error";
        }
        else{
            setAppointmentScheduling({
                ...appointmentScheduling,
                [target.name]: inputValue?.length > 0 ? inputValue : null, 
            });

            let isValid = true;
            switch(target.name)
            {
                case 'websiteAddress':                                            
                                            isValid = validateURL(inputValue) ;
                                            errorMsg = !isValid ? "Please enter a valid website address." : "";                                   
                                            break;
                case 'phoneNumber':          
                                            isValid = validatePhoneNumber(inputValue);
                                            errorMsg = !isValid ? "Please enter a valid phone number" : "";                                               
                                            break;
                case 'emailAddress':          
                                            isValid = validateEmail(inputValue);
                                            errorMsg = !isValid ? "Please enter a valid email address." : "";                                               
                                            break;
                case "specialInstructions": 
                                            isValid = !(appointmentType.toString() === "1" && checkedMethodCounter === 0);
                                            break;
                default:                    break;
            }
            
            errorCls = target.name !== "specialInstructions" && !isValid ? "bh-error" : "";
            isValid = isValid && (target.name === "websiteAddress" ? errorCls === "" && helperText.phoneNumber === "" && helperText.emailAddress === "" :
                                  target.name === "phoneNumber" ? errorCls === "" && helperText.websiteAddress === "" && helperText.emailAddress === "" :
                                  target.name === "emailAddress" ? errorCls === "" && helperText.websiteAddress === "" && helperText.phoneNumber === "" : 
                                  helperText.websiteAddress === "" && helperText.phoneNumber === "" && helperText.emailAddress === "");
            if(isValid){
                inputValue = inputValue?.length > 0 ? inputValue : null;                
                setUpdatedAppointmentScheduling({
                    ...updatedAppointmentScheduling,
                    byAppointment: appointmentScheduling.byAppointment,
                    methodOnline: target.name === "websiteAddress" ? true : appointmentScheduling.methodOnline,
                    methodPhone: target.name === "phoneNumber" ? true : appointmentScheduling.methodPhone,
                    methodEmail: target.name === "emailAddress" ? true : appointmentScheduling.methodEmail,
                    websiteAddress: target.name === "websiteAddress" ? inputValue : appointmentScheduling.websiteAddress,
                    phoneNumber: target.name === "phoneNumber" ? inputValue : appointmentScheduling.phoneNumber,
                    emailAddress: target.name === "emailAddress" ? inputValue : appointmentScheduling.emailAddress,
                    specialInstructions: target.name === "specialInstructions" ? inputValue : appointmentScheduling.specialInstructions,
                }); 
            }
            else{
                setUpdatedAppointmentScheduling(originalAppointmentScheduling);
            }
        }

        setHelperText({
            ...helperText,
            [target.name]: errorMsg,
        });
        setErrorClass({
            ...errorClass,
            [target.name]: errorCls
        });
    }

    useEffect(() => {
        props.handleChange("appointmentScheduling", updatedAppointmentScheduling);
    },[updatedAppointmentScheduling]);

    function validatePhoneNumber(elementValue) {
        var regx = /^\+?([0-9]{0,2})\)?[- ]?([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$/;
        return regx.test(elementValue);
    }

    function validateURL(elementValue) {
        const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;    
        return regex.test(elementValue);
      };

    function validateEmail(elementValue){
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(elementValue);
    }

    return(
        <div className="edit-details-body edits-appointment-container">
            <div className="edit-details-description">
                Please select appointment type and preferred method(s) for scheduling.
            </div>
            <div className="edit-details-body-padding">
                <FormGroup className="medium-form-group" style={{width: "30vmin", marginTop: "1.5%"}}>
                    <FloatLabelDropdown
                        inputLabel="APPOINTMENT TYPE"
                        inputAutoFocus="false"
                        inputType="text"
                        inputName="appointmentType"
                        inputPlaceholder="Appointment Type"
                        inputValue={appointmentType}
                        handleChangeProps={appointmentTypeChange}
                        inputAs="select"
                        selectOptions={typeOptions !== undefined ? typeOptions : []}
                        defaultOption="Select Appointment Type"
                        disableDefaultOption={originalAppointmentScheduling.byAppointment !== null}
                    />
                </FormGroup>
                <div className={schedulingMethodClassName}>
                    <div className="edit-details-title">
                        Method for Scheduling
                    </div>
                    <div className="appointment-checkbox-container">             
                        <div style={{ marginBottom : "35px" }}>
                            <label className="option-check-container">Online
                                <input type="checkbox" id="online" name="methodOnline" className="option-checkbox" checked={appointmentScheduling.methodOnline} onChange={(e) => handleCheckboxChange(e)}></input>
                                <span className="option-checkmark"></span>
                            </label>
                            <Form className={`medium-form-group ${websiteClassName}`} style={{ width: "30vmin" }}>
                                <FloatLabelTextBox
                                    inputLabel="WEBSITE"
                                    inputAutoFocus="true"
                                    inputType="text"
                                    inputName="websiteAddress"
                                    inputValue={appointmentScheduling.websiteAddress ? appointmentScheduling.websiteAddress : ""}
                                    inputPlaceholder="Website"
                                    inputClassName={`dynamic-form-text ${errorClass.websiteAddress}`}
                                    inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.websiteAddress}</div><div style={{ float: "right" }}>{appointmentScheduling.websiteAddress ? appointmentScheduling.websiteAddress.length : 0}/50</div></div>]}
                                    handleChangeProps={(e) => handleInputChange(e, 50)}
                                />
                            </Form>                         
                        </div>                              
                        <div style={{ marginBottom : "35px" }}>
                            <label className="option-check-container">By Phone
                                <input type="checkbox" id="phone" name="methodPhone" className="option-checkbox" checked={appointmentScheduling.methodPhone} onChange={(e) => handleCheckboxChange(e)}></input>
                                <span className="option-checkmark"></span>
                            </label>
                            <Form className={`medium-form-group ${phoneClassName}`} style={{ width: "30vmin" }}>
                                <FloatLabelPhoneNumber
                                    inputLabel="PHONE"
                                    inputAutoFocus="true"
                                    inputType="text"
                                    inputName="phoneNumber"
                                    inputValue={appointmentScheduling.phoneNumber ? appointmentScheduling.phoneNumber : ""}
                                    inputPlaceholder="Phone"
                                    inputClassName={`dynamic-form-text ${errorClass.phoneNumber}`}
                                    inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.phoneNumber}</div><div style={{ float: "right" }}>{appointmentScheduling.phoneNumber ? appointmentScheduling.phoneNumber.length : 0}/10</div></div>]}
                                    handleChangeProps={(e) => handleInputChange(e, 10)}
                                />
                            </Form>                         
                        </div>
                        <div style={{ marginBottom : "35px" }}>
                            <label className="option-check-container">By Email
                                <input type="checkbox" id="email" name="methodEmail" className="option-checkbox" checked={appointmentScheduling.methodEmail} onChange={(e) => handleCheckboxChange(e)}></input>
                                <span className="option-checkmark"></span>
                            </label>
                            <Form className={`medium-form-group ${emailClassName}`} style={{ width: "30vmin" }}>
                                <FloatLabelTextBox
                                    inputLabel="EMAIL"
                                    inputAutoFocus="true"
                                    inputType="text"
                                    inputName="emailAddress"
                                    inputValue={appointmentScheduling.emailAddress ? appointmentScheduling.emailAddress : ""}
                                    inputPlaceholder="Email"
                                    inputClassName={`dynamic-form-text ${errorClass.emailAddress}`}
                                    inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.emailAddress}</div><div style={{ float: "right" }}>{appointmentScheduling.emailAddress ? appointmentScheduling.emailAddress.length : 0}/50</div></div>]}
                                    handleChangeProps={(e) => handleInputChange(e, 50)}
                                />
                            </Form>                         
                        </div>            
                    </div>
                </div>
                <div className="edit-details-title">Special Instructions</div>
                <div className="label-description edit-details-text-dark">Please provide any additional Information regarding appointment scheduling.</div>
                <Form className="medium-form-group text-area-container">
                    <FloatLabelTextBox
                        inputLabel=""
                        inputAutoFocus="true"
                        inputType="textarea"
                        inputName="specialInstructions"
                        inputValue={appointmentScheduling.specialInstructions ? appointmentScheduling.specialInstructions : ""}
                        inputClassName={`dynamic-form-text ${errorClass.specialInstructions}`}
                        inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.specialInstructions}</div><div style={{ float: "right" }}>{appointmentScheduling.specialInstructions ? appointmentScheduling.specialInstructions.length : 0}/500</div></div>]}
                        handleChangeProps={(e) => handleInputChange(e, 500)}
                        inputRows={10}
                        inputAs="textarea"
                        inputSpellCheck={true}
                        inputDisabled={appointmentScheduling.byAppointment === null}
                    />
                </Form>                
            </div>
        </div>
    )
}