import { useEffect, useState } from "react";

function checkWindowDimension(){
  return window.matchMedia("screen and (max-width: 1023px) and (orientation: portrait), (max-width: 1279px) and (orientation: landscape)").matches;
}

export default function IsMobileDevice() {
  const [isMobile, setIsMobile] = useState(checkWindowDimension());

  useEffect(() => {
    function handleResize() {
      window.scrollTo(0,0);
      setIsMobile(checkWindowDimension());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

    }, []);
  
  return isMobile;
}