import { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./AddEntityModal.css";
import FloatLabelDropdown from "./FloatLabelDropdown";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks';
import { useSessionContext } from "../libs/sessionLib";
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationModal from "./ConfirmationModal";
import ProgressSpinner from "../components/ProgressSpinner";

export default function EditRelationshipModal(props) {
    const { session } = useSessionContext();
    const [disableSetRelationshipButton, setDisableSetRelationshipButton] = useState(true);
    const [disableEditButton, setDisableEditButton] = useState(true);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [errorClass, setErrorClass] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [relationship, setRelationship] = useState({
        companyId: session ? session.companyId : null,
        locationId: props ? props.locationId : 0,
        relationshipId: 0,
        createdBy: session ? session.id : null,
        modifiedBy: session ? session.id : null
    });
    const [setRelationshipButton, setSetRelationshipButton] = useState(true);

    const api_get_relationship_types_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/company/location/relationshiptypes" : API_BASE_URL + "/company/location/relationshiptypes";
    const [{ data: relationshipTypes, error: relationshipTypesError, response: relationshipTypesResponse }, fetchRelationshipTypes] = useAxios(
        {
            url: api_get_relationship_types_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    useEffect(() => {
        fetchRelationshipTypes();
    }, [])

    const relationshipTypesArray = relationshipTypes ? relationshipTypes.map(item => ({ id: item.id, name: item.relationshipName })) : [];

    useEffect(() => {
        setRelationship({
            ...relationship,
            relationshipId: (props.locationRelationship !== undefined) ? props.locationRelationship.relationshipId : 0,
        })

        setSetRelationshipButton(!(props.locationRelationship && props.locationRelationship.relationshipId > 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.locationRelationship?.relationshipId])

    const api_relationship_url = ENVIRONMENT === "development" ? "/api/company/location" : API_BASE_URL + "/company/location";
    const [{ data: setLocationRelationship,  loading: setLocationRelationshipLoading, response: setLocationRelationshipResponse }, postData] = useAxios(
        {
            url: api_relationship_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    const api_update_relationship_url = ENVIRONMENT === "development" ? "/api/company/location" : API_BASE_URL + "/company/location";
    const [{ data: updateLocationRelationship, loading: updateRelationshipLoading, response: updateLocationRelationshipResponse }, updateData] = useAxios(
        {
            url: api_update_relationship_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "PUT"
        },
        { manual: true }
    );

    const companyLocationId = props.locationRelationship ? props.locationRelationship.id : 0;
    const api_delete_relationship_url = ENVIRONMENT === "development" ? "/api/company/location/" + companyLocationId : API_BASE_URL + "/company/location/" + companyLocationId;
    const [{ data: deleteLocationRelationship, loading: deleteLocationRelationshipLoading, error: deleteLocationRelationshipError, response: deleteLocationRelationshipResponse }, deleteData] = useAxios(
        {
            url: api_delete_relationship_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "DELETE"
        },
        { manual: true }
    );

    function handleChange(e) {
        setRelationship({
            ...relationship,
            [e.target.name]: e.target.value,
        })
        if(props.locationRelationship?.relationshipId > 0){
            (parseInt(e.target.value) > 0 && parseInt(e.target.value) !== props.locationRelationship.relationshipId) ? setDisableEditButton(false) : setDisableEditButton(true);
        }
        else{
            (parseInt(e.target.value) > 0) ? setDisableSetRelationshipButton(false) : setDisableSetRelationshipButton(true);
        }
    }

    function handleClose() {
        if(props.locationRelationship) {
            undoChangesOnClose();
        }
        else {
            clearRelationship();
        }
        props.onHide();
    }

    function handleConfirmationModalClose() {
        undoChangesOnClose();
        setConfirmationModalShow(false);       
    }

    function undoChangesOnClose() {
        setRelationship({
            ...relationship,
            relationshipId: props.locationRelationship.relationshipId,
        });
    }

    async function handleSubmit(e, method) {
        e.preventDefault();

        if(relationship.relationshipId) {
            setErrorClass("");
            setErrorMessage("");

            try {
                if(method === "post"){
                    await postData({
                        data: {
                            ...relationship,
                            relationshipId: parseInt(relationship.relationshipId),                       
                        }
                    });
                }
                else{
                    await updateData({
                        data: {
                            ...relationship,
                            relationshipId: parseInt(relationship.relationshipId),
                            id: props.locationRelationship.id,                       
                        }
                    });
                }
                clearRelationship();
                if(!(setLocationRelationshipLoading || updateRelationshipLoading)){
                    props.onSuccess(true, (props.locationName).toUpperCase() + "'s relationship to your company has been " + ((method === "post") ? "set." : "updated."));
                    props.onHide();
                }               
            } catch (err) {
                triggerErrorAlert(true, "Error " + ((method === "post") ? "saving" : "updating") + " location relationship.");
            }
         }
         else {
            setErrorClass("error");
            setErrorMessage("Please select Relationship Type.");
         }
    }

    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const confirmationModalTitle = "Remove Relationship";
    const confirmationModalHeader = "Are you sure you want to remove this location's relationship?";
    const confirmationModalBody = "Removing this relationship from your company will remove its visibility from your company page.";

    function openConfirmationModal(e) {
        e.preventDefault();
        props.onHide();
        setConfirmationModalShow(true);
    }

    function handleDelete(e) {
        e.preventDefault();
        setErrorClass("");
        setErrorMessage("");
        try {
                deleteData();
                clearRelationship();
                setDisableSetRelationshipButton(true);
                props.onSuccess(true, (props.locationName).toUpperCase() + "'s relationship from your company has been removed.");
                props.onHide();
        } catch (err) {
            triggerErrorAlert(true, "Error removing location relationship.");
        }
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function clearRelationship() {
        setRelationship({
            ...relationship,
            relationshipId: 0,
        });
        setDisableEditButton(true);
    }

    return (
        props.locationName ?
            (<>
                <Modal
                    {...props}
                    dialogClassName="modal-container"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            {props.locationRelationship?.relationshipId > 0 ? "Edit Relationship" : "Set Relationship"}
                            <div className="modal-close" onClick={() => handleClose()}><CloseIcon /></div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(setLocationRelationshipLoading || updateRelationshipLoading || deleteLocationRelationshipLoading) ? (
                        <div className="spinner-container modal-spinner-container">
                            <ProgressSpinner fontColor="#FFFFFF" />
                        </div>
                        ) : <></> }
                        <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                            <ErrorIcon />
                            <div className="floating-alert-body">
                                <Alert.Heading>Error</Alert.Heading>
                                <p>{errorAlertMessage}</p>
                            </div>
                        </Alert>
                        <div className="modal-description">
                            Setting a relationship with this location will link to your company.
                            <br/>
                            This can be viewed on your company page.
                        </div>
                        <div className="modal-form-label">Location Name</div>
                        <div className="modal-description">{props.locationName}</div>
                        <Form onSubmit={handleSubmit}>
                            <div className="modal-form-label">Relationship</div>
                            <div className="modal-description" style={{ paddingBottom: "3%", }}>
                                Please select this location's relationship to your company.
                            </div>
                            <Form.Group className="medium-form-group">
                                <FloatLabelDropdown
                                    inputLabel="RELATIONSHIP"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="relationshipId"
                                    inputPlaceholder="Select Relationship"
                                    inputValue={relationship.relationshipId}
                                    inputClassName={errorClass}
                                    handleChangeProps={handleChange}
                                    inputHelperText={errorMessage}
                                    inputAs="select"
                                    selectOptions={relationshipTypesArray !== undefined ? relationshipTypesArray : []}
                                    defaultOption="Select Relationship"
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    {setRelationshipButton ? (
                            <Button className="btn-submit" disabled={disableSetRelationshipButton} onClick={(e) => { handleSubmit(e,"post") }} type="submit">Set Relationship</Button>
                        ) : (
                            <>
                            <Button className="btn-cancel" onClick={(e) => { openConfirmationModal(e) }}>Remove Relationship</Button>
                            <Button className="btn-submit" disabled={disableEditButton} onClick={(e) => { handleSubmit(e,"put") }}>Save Edit</Button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
                <ConfirmationModal modalTitle={confirmationModalTitle} modalHeader={confirmationModalHeader} modalBody={confirmationModalBody} confirmationButtonName={confirmationModalTitle}
                                   loading={deleteLocationRelationshipLoading} show={confirmationModalShow} onHide={handleConfirmationModalClose} onConfirmation={handleDelete} />
            </>
            ) : <></>
    );
}