import React from "react";
import "./LocationSearchDesktopView.css";
import LocationSearchResults from "../../components/locationsearch/LocationSearchResults";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import { FormGroup } from "@material-ui/core";
import USStates from '../../constants/USStates';
import SearchIcon from '@material-ui/icons/Search';
import FloatLabelTextBox from "../../components/FloatLabelTextBox";
import FloatLabelDropdown from "../../components/FloatLabelDropdown";


export default function LocationSearchDesktopView(props){
    
    return(
        <div className="Search-Container">
                <div className="Search">
                    <Form>
                        <div className="search-header-top">
                            <div className="search-type-toggle-wrapper">
                                <input
                                    type="radio"
                                    className="search-type-toggle"
                                    name="searchLocations"
                                    value="name"
                                    id="searchByName"
                                    onChange={(e) => { props.handleToggle(e) }}
                                    checked={props.searchType === "name"}
                                />
                                <label for="searchByName">Location Name, City, State</label>
                                <input
                                    type="radio"
                                    className="search-type-toggle"
                                    name="searchLocations"
                                    value="address"
                                    id="searchByAddress"
                                    onChange={(e) => { props.handleToggle(e) }}
                                    checked={props.searchType === "address"}
                                />
                                <label for="searchByAddress">Address, City, State</label>

                            </div>
                            <div className={props.searchType === "name" ? "" : "hide-box"}>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="filter-toggle">
                                        Show Filter Options
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="filter-menu">
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <div className="filter-options left">
                                                <label class="filter-check-container">{props.locationTypes[1]}
                                                    <input type="checkbox" name="filterOptions" value="1" onChange={(e) => { props.handleFilterToggle(e) }} class="filter-checkbox"></input>
                                                    <span className="filter-checkmark"></span>
                                                </label>
                                                <label class="filter-check-container">{props.locationTypes[2]}
                                                    <input type="checkbox" name="filterOptions" value="2" onChange={(e) => { props.handleFilterToggle(e) }} class="filter-checkbox"></input>
                                                    <span className="filter-checkmark"></span>
                                                </label>
                                                <label class="filter-check-container">{props.locationTypes[3]}
                                                    <input type="checkbox" name="filterOptions" value="3" onChange={(e) => { props.handleFilterToggle(e) }} class="filter-checkbox"></input>
                                                    <span className="filter-checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="filter-options">
                                                <label class="filter-check-container">{props.locationTypes[4]}
                                                    <input type="checkbox" name="filterOptions" value="4" onChange={(e) => { props.handleFilterToggle(e) }} class="filter-checkbox"></input>
                                                    <span className="filter-checkmark"></span>
                                                </label>
                                                <label class="filter-check-container">{props.locationTypes[5]}
                                                    <input type="checkbox" name="filterOptions" value="5" onChange={(e) => { props.handleFilterToggle(e) }} class="filter-checkbox"></input>
                                                    <span className="filter-checkmark"></span>
                                                </label>
                                                <label class="filter-check-container">{props.locationTypes[6]}
                                                    <input type="checkbox" name="filterOptions" value="6" onChange={(e) => { props.handleFilterToggle(e) }} class="filter-checkbox"></input>
                                                    <span className="filter-checkmark"></span>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="search-header">
                            <div style={{ display: 'inline-flex' }}>
                                <div className={props.searchType === "name" ? "input-container" : "input-container hide-box"}>
                                    <InputGroup className="search-textbox">
                                        <FloatLabelTextBox
                                            inputLabel="LOCATION NAME"
                                            inputAutoFocus="true"
                                            inputType="text"
                                            inputName="name"
                                            inputPlaceholder="Location Name"
                                            inputValue={props.searchCriteria.name}
                                            handleChangeProps={props.handleSearchInputChange}
                                        />
                                    </InputGroup>
                                </div>
                                <div className={props.searchType === "address" ? "input-container" : "input-container hide-box"}>
                                    <InputGroup className="search-textbox">
                                        <FloatLabelTextBox
                                            inputLabel="ADDRESS"
                                            inputAutoFocus="true"
                                            inputType="text"
                                            inputName="address"
                                            inputPlaceholder="Address"
                                            inputValue={props.searchCriteria.address}
                                            handleChangeProps={props.handleSearchInputChange}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="input-container">
                                    <InputGroup className="search-textbox">
                                        <FloatLabelTextBox
                                            inputLabel="CITY"
                                            inputAutoFocus="true"
                                            inputType="text"
                                            inputName="city"
                                            inputPlaceholder="City"
                                            inputValue={props.searchCriteria.city}
                                            handleChangeProps={props.handleSearchInputChange}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="input-container">
                                    <FormGroup className="search-dropdown">
                                        <FloatLabelDropdown
                                            inputLabel="STATE"
                                            inputAutoFocus="false"
                                            inputType="text"
                                            inputName="state"
                                            inputPlaceholder="State"
                                            inputValue={props.searchCriteria.state}
                                            handleChangeProps={props.handleSearchInputChange}
                                            inputAs="select"
                                            selectOptions={USStates}
                                            defaultOption="State"
                                        />
                                    </FormGroup>
                                </div>
                                <Button
                                    type="submit"
                                    className="location-search-btn"
                                    onClick={(e) => { props.handleSearch(e) }}
                                    disabled={
                                        ((props.searchType === "address") ?
                                            ((props.searchCriteria.address.length === 0 || props.searchCriteria.city.length === 0 || props.searchCriteria.state.length === 0) ? true : false)
                                            :
                                            ((props.searchCriteria.name.length === 0 && props.searchCriteria.city.length === 0 && props.searchCriteria.state.length === 0) ? true : false)
                                        )
                                    }
                                >
                                    <SearchIcon />
                                </Button>
                                <Button
                                    type="submit"
                                    variant="link"
                                    className="clear-button"
                                    onClick={(e) => { props.handleSearchInputClear(e) }}
                                    disabled={(props.searchCriteria.address.length === 0 && props.searchCriteria.name.length === 0 && props.searchCriteria.city.length === 0 && props.searchCriteria.state.length === 0 && props.searchCriteria.locationTypeIds.length === 0) ? true : false}
                                >
                                    Clear All
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
                <LocationSearchResults
                    loading={props.loading}
                    locations={props.locations}
                    error={props.error}
                    numberOfRecordsToShow={props.numberOfRecordsToShow}
                    searchResultMessageToDisplay={props.searchResultMessageToDisplay}
                    setLocationDetailsButtonStyle={props.setLocationDetailsButtonStyle}
                    subNameMatch={props.subNameMatch}
                    locationTypes={props.locationTypes}
                    showNoResults={props.showNoResults}
                    handleSearchInputClear={props.handleSearchInputClear}
                />
        </div>
    )
}