import React from "react";
import "./EditLocationDetailsDesktop.css";
import "../AddEntityModal.css";
import { Button } from "react-bootstrap";
import CreateIcon from '@material-ui/icons/Create';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditLocationType from "../editlocationdetails/EditLocationType";
import EditAmenities from "../editlocationdetails/EditAmenities";
import EditClosures from "../editlocationdetails/EditClosures";
import EditLocationNames from "../editlocationdetails/EditLocationNames";
import EditBusinessHours from "../editlocationdetails/EditBusinessHours";
import EditAppointmentScheduling from "../editlocationdetails/EditAppointmentScheduling";
import EditServices from "../editlocationdetails/EditServices";
import EditSafetyRequirements from "../editlocationdetails/EditSafetyRequirements";
import EditProcedures from "../editlocationdetails/EditProcedures";
import EditContacts from "../editlocationdetails/EditContacts.js";
import EditGeofence from "../editlocationdetails/EditGeofence.js";
import ConfirmationModal from "../ConfirmationModal.js";

export default function EditLocationDetailsDesktop(props) {

    return (
            <>
                <div className="edit-header">
                    <div className="edit-title">{props.locationName}</div>
                    <div>
                        <Button type="submit" className="btn-cancel" onClick={(e) => props.handleCancel(e)}>
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '20px' }} type="submit" className="btn-submit" disabled={props.disableSubmit} onClick={() => props.handleSubmit()}>
                            Submit
                        </Button>
                    </div>
                </div>
                <div className="edit-container">
                    <div className="edit-body-title">
                        <CreateIcon />
                        <div style={{ paddingLeft: "1%", }}>Edit Location Details</div>
                    </div>
                    <div className="edit-container-body">
                        <div className="edit-sections">
                            <div className={props.activeSection === "locationName" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("locationName")}>Location Names<ArrowForwardIcon className={props.activeSection === "locationName" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "geofence" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("geofence")}>Geofence<ArrowForwardIcon className={props.activeSection === "geofence" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "locationType" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("locationType")}>Location Type<ArrowForwardIcon className={props.activeSection === "locationType" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "contacts" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("contacts")}>Contacts<ArrowForwardIcon className={props.activeSection === "contacts" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "businessHours" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("businessHours")}>Hours<ArrowForwardIcon className={props.activeSection === "businessHours" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "closures" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("closures")}>Closures<ArrowForwardIcon className={props.activeSection === "closures" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "appointmentScheduling" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("appointmentScheduling")}>Appointment Scheduling<ArrowForwardIcon className={props.activeSection === "appointmentScheduling" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "amenities" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("amenities")}>Amenities<ArrowForwardIcon className={props.activeSection === "amenities" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "services" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("services")}>Services<ArrowForwardIcon className={props.activeSection === "services" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "safetyRequirements" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("safetyRequirements")}>Safety Requirements<ArrowForwardIcon className={props.activeSection === "safetyRequirements" ? "detail-arrow" : "hide"} /></div>
                            <div className={props.activeSection === "procedures" ? "edit-section-box active" : "edit-section-box"} onClick={() => props.setActiveSection("procedures")}>Procedures<ArrowForwardIcon className={props.activeSection === "procedures" ? "detail-arrow" : "hide"} /></div>
                        </div>
                        <div className="edit-details-container">
                            <div className="edit-details-header">{props.editContainerHeader}</div>
                            {props.activeSection === "locationName" ? (<EditLocationNames mainName={props.edits.locationName ? props.edits.locationName.locationName : ""} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "geofence" ? (<EditGeofence geofence={props.edits.geoFence1} originalGeofence={props.originalData.geoFence1} latitude={props.edits.latitude} longitude={props.edits.longitude} handleChange={props.handleEdits} triggerErrorAlert={props.triggerErrorAlert} setDisableSubmit={props.setDisableSubmit} />) : (<></>)}
                            {props.activeSection === "locationType" && (<EditLocationType locationType={props.edits.locationType} locationTypes={props.locationTypes} handleChange={props.handleEdits} />)}
                            {props.activeSection === "contacts" ? (<EditContacts contactTypes={props.contactTypes} contacts={props.edits.contactInfo ? props.edits.contactInfo[0] : null} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "businessHours" ? (<EditBusinessHours hours={props.edits.businessHours?.length > 0 ? props.edits.businessHours : props.emptyHours} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "closures" ? (<EditClosures mainClosures={props.editsMade ? props.edits.mainClosures : props.originalData.mainClosures} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "appointmentScheduling" ? (<EditAppointmentScheduling updatedAppointmentScheduling={props.edits.appointmentScheduling} originalData={props.originalData.appointmentScheduling} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "amenities" ? (<EditAmenities amenities={props.edits.amenities} additionalAmenities={props.originalData.additionalAmenities} additionalAmenitiesEdits={props.edits.otherAmenities} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "services" ? (<EditServices services={props.edits.services} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "safetyRequirements" ? (<EditSafetyRequirements safetyRequirements={props.edits.safetyRequirements} additionalEquipments={props.originalData.additionalSafetyRequirements} additionalEquipmentsEdits={props.edits.otherSafetyRequirements} handleChange={props.handleEdits} />) : (<></>)}
                            {props.activeSection === "procedures" ? (<EditProcedures procedures={props.edits.procedures} handleChange={props.handleEdits} />) : (<></>)}
                        </div>
                    </div>
                    <ConfirmationModal modalTitle={props.confirmationModalTitle} modalHeader={props.confirmationModalHeader} confirmationButtonName={props.confirmationButtonName} show={props.confirmationModalShow}
                        onHide={() => props.setConfirmationModalShow(false)} onConfirmation={props.handleConfirmation} />
                </div>
            </>
    )
}