import React, { useEffect, useMemo, useState } from "react";
import "./EditBusinessHours.css";
import FloatLabelTextBox from "../FloatLabelTextBox";
import FloatLabelTimePicker from "../FloatLabelTimePicker";
import { Form } from "react-bootstrap";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { isFalse } from "@looker/sdk-rtl";

export default function EditBusinessHours(props) {
    const weekdays = {
        1: "Sunday",
        2: "Monday",
        3: "Tuesday",
        4: "Wednesday",
        5: "Thursday",
        6: "Friday",
        7: "Saturday"
    }

    const [hours, setHours] = useState(props.hours);
    const [helperText, setHelperText] = useState([]);
    const [errorClass, setErrorClass] = useState([]);
    const [canAddNewHoursSet, setCanAddNewHoursSet] = useState(false);

    function timeChange(timeType, e, index, dayIndex) {
        if (e) {

            const splitTime = e.target.value.split(":");
            const hour = parseInt(splitTime[0]) > 12 ? (parseInt(splitTime[0]) - 12).toString() : (parseInt(splitTime[0]) === 0 ? "12" : splitTime[0]);
            const minutes = splitTime[1]
            const amOrPM = parseInt(splitTime[0]) >= 12 ? "PM" : "AM";
            let open24Hours = false;

            let items = [...hours];
            let item = {...items[index]};
            let updatedDays = [...item.days];

            if(timeType === "opening"){
                open24Hours = (hours === "12" && minutes === "00" && amOrPM === "AM") && 
                                updatedDays[dayIndex].closingHours === "11" &&
                                updatedDays[dayIndex].closingMinutes === "59" &&
                                updatedDays[dayIndex].closingHoursAMPM === "PM" ? true : false;
            }
            else{
                open24Hours = (hours === "11" && minutes === "59" && amOrPM === "PM") && 
                                updatedDays[dayIndex].openingHours === "12" &&
                                updatedDays[dayIndex].openingMinutes === "00" &&
                                updatedDays[dayIndex].openingHoursAMPM === "AM" ? true : false;
            }

            updatedDays[dayIndex] = {
                ...updatedDays[dayIndex],
                [timeType + "Hours"]: hour,
                [timeType + "Minutes"]: minutes,
                [timeType + "HoursAMPM"]: amOrPM,
                open24Hours : open24Hours,
            }
            item.days = updatedDays;
            item.open24By7 = updatedDays.some(f => open24Hours && !(f.weekdayId !== (dayIndex+1) && f.open24Hours === false)) ? true : false;
            items[index] = item; 
            setHours(items);
        }           
    }

    function handleDayCheck(targetName, targetValue, index, dayIndex) {
        let items = [...hours];
        let item = {...items[index]}
        let updatedDays = [...item.days];
        let open24By7 = false;
        if(targetName === "open24Hours") {
            updatedDays[dayIndex] = {
                ...updatedDays[dayIndex],
                "openOnWeekday": true,
                "openingHours": "12",
                "openingMinutes": "00",
                "openingHoursAMPM": "AM",
                "closingHours": "11",
                "closingMinutes": "59",
                "closingHoursAMPM": "PM",
                "open24Hours": targetValue,
            }
            if(targetValue){
                open24By7 = updatedDays.filter(f => f.weekdayId !== (dayIndex+1) && f.open24Hours === false).length > 0 ? false : true;
            }
            else{
                open24By7 = false;
            }
        }
        else if(targetName === "openOnWeekday"){
            updatedDays[dayIndex] = {
                ...updatedDays[dayIndex],
                "openOnWeekday": targetValue,
                "openingHours": "",
                "openingMinutes": "",
                "openingHoursAMPM": "",
                "closingHours": "",
                "closingMinutes": "",
                "closingHoursAMPM": "",
                "open24Hours": false,
            }
        }
        
        item.days = updatedDays;
        item.open24By7 = open24By7;
        items[index] = item;
        setHours(items);
    }

    function handleCheck(e, index){
        let items = [...hours];
        let item = {...items[index]};
        let updatedDays = [...item.days];
        if(e.target.checked){
            updatedDays.map((item, dayIndex) => 
            updatedDays[dayIndex] = {
                    ...updatedDays[dayIndex],
                    "openOnWeekday": true,
                    "openingHours": "12",
                    "openingMinutes": "00",
                    "openingHoursAMPM": "AM",
                    "closingHours": "11",
                    "closingMinutes": "59",
                    "closingHoursAMPM": "PM",
                    "open24Hours": true,
                }
            );
        }
        else{
            updatedDays.map((item, dayIndex) => 
            updatedDays[dayIndex] = {
                    ...updatedDays[dayIndex],
                    "openOnWeekday": true,
                    "openingHours": "",
                    "openingMinutes": "",
                    "openingHoursAMPM": "",
                    "closingHours": "",
                    "closingMinutes": "",
                    "closingHoursAMPM": "",
                    "open24Hours": false,
                }
            );
        }
        item.days = updatedDays;
        item.open24By7 = e.target.checked;
        items[index] = item;
        setHours(items);
    }

    function onChangeFormGroupInput(e, index) {
        let errors = [...errorClass];
        let updatedErrors = { ...errors[index] };
        let helperMsgs = [...helperText];
        let updatedHelperMsg = { ...helperMsgs[index] };
        if (e.target.value.length <= 25) {

            let newItems = [...hours];
            let updatedHours = { ...newItems[index] };

            updatedHours = { ...updatedHours, [e.target.name]: e.target.value };
            newItems[index] = updatedHours;
            setHours(newItems);

            updatedErrors = "";
            setErrorClass(updatedErrors);
            updatedHelperMsg = "";
            setHelperText(updatedHelperMsg);
        }
        else {
            updatedErrors = "bh-error";
            setErrorClass(updatedErrors);
            updatedHelperMsg = "The max length of 25 characters is reached.";
            setHelperText(updatedHelperMsg);
        }
    }

    // function addNewHoursAllowed() {
    //     let lastItem = hours.at(-1);
    //     return lastItem.businessHoursTypeName.length > 0
    // }

    // function addNewHours() {
    //     setHours([...hours, emptyHours]);
    // }

    useEffect(() => {
        //setCanAddNewHoursSet(addNewHoursAllowed());
        var canSave = validateHours();
        if (canSave) {
            props.handleChange("businessHours", hours);
        }
        else {
            props.handleChange("businessHours", []);
        }

    }, [hours]);

    function validateHours() {
        //check all approproate things are changed
        var allowedToSave = true;
        if (hours === undefined || hours.length === 0) {
            allowedToSave = false;
        }
        else {
            let hourSetToCheck = { ...hours[0] };

            if (hourSetToCheck !== undefined) {
                if (hourSetToCheck.businessHoursTypeName.length === 0) {
                    allowedToSave = false;
                }
                if (!hourSetToCheck.open24By7) {
                    hourSetToCheck.days.forEach(function (day, dayIndex) {
                        if (day === undefined) {
                            allowedToSave = false;
                        }
                        if (!day.open24Hours && day.openOnWeekday) {
                            if (day.openingHours.length === 0 || day.openingMinutes.length === 0 || day.openingHoursAMPM.length === 0) {
                                allowedToSave = false;
                            }
                            if (day.closingHours.length === 0 || day.closingMinutes.length === 0 || day.closingHoursAMPM.length === 0) {
                                allowedToSave = false;
                            }
                        }
                    })
                }
            }
            else {
                allowedToSave = false;
            }
        }
        return allowedToSave;
    }

    return (
        <div className="edit-details-body">
            <div className="edit-details-description">
                Set this location’s hours of operation. Multiple schedules to reflect different parts of your business for this location.
            </div>
            <div className="edit-details-body-padding">
                <Form>
                    {hours && hours.length > 0 ? (hours.map((item, index) => (
                        <Form.Group id={index} key={index}>
                            <div style={{ width: "27vmin", paddingBottom: "15px", fontSize: "14px", marginTop: "10px" }}>
                                <FloatLabelTextBox
                                    inputLabel="HOURS OF OPERATION"
                                    inputAutoFocus="true"
                                    inputType="text"
                                    inputName="businessHoursTypeName"
                                    inputValue={item.businessHoursTypeName}
                                    inputPlaceholder="Hours of Operation"
                                    inputClassName={`${errorClass[index]}`}
                                    inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{(helperText[index] && helperText[index].length > 0) ? helperText[index] : "Eg. Receiving, Shipping, Dry Hours"}</div><div style={{ float: "right" }}>{item.businessHoursTypeName.length}/25</div></div>]}
                                    handleChangeProps={(e) => onChangeFormGroupInput(e, index)}
                                    inputDisabled={true}
                                />
                            </div>
                            <div style={{ marginTop: "18px", width: "fit-content" }}>
                                <label className="option-check-container">Open 24/7 Hours
                                    <input type="checkbox" id="open24By7" name="open24By7" className="option-checkbox" checked={item.open24By7} onChange={(e) => handleCheck(e, index)}></input>
                                    <span className="option-checkmark"></span>
                                </label>
                            </div>
                            {item.days !== undefined && item.days.map((day, dayIndex) => (
                                <div key={index + "_" + dayIndex} className="day-row">
                                    <div style={{ display: "grid" }}>
                                        {weekdays[day.weekdayId]}
                                        <div style={{ display: "inline-flex", paddingTop: "7px", columnGap: "3.8vw" }}>
                                            <div>
                                                <label className="option-check-container">Open 24 Hours
                                                    <input type="checkbox" name="open24Hours" className="option-checkbox" checked={day.open24Hours} onChange={(e) => handleDayCheck("open24Hours", e.target.checked, index, dayIndex)}></input>
                                                    <span className="option-checkmark"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="option-check-container">Closed
                                                    <input type="checkbox" name="openOnWeekday" className="option-checkbox" checked={!day.openOnWeekday} onChange={(e) => handleDayCheck("openOnWeekday", !e.target.checked, index, dayIndex)}></input>
                                                    <span className="option-checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-flex", marginLeft: "3.8vw", marginTop: "5px", columnGap: "30px" }}>
                                        <div className={!day.openOnWeekday ? "hidden-timepickers" : "time-picker-container"}>
                                            <FloatLabelTimePicker
                                                inputLabel="OPEN TIME"
                                                inputAutoFocus="true"
                                                inputName="OpenHours"
                                                inputDisabled={day.open24Hours}
                                                inputValue={
                                                    (day.openingHours.length > 0 && day.openingMinutes.length > 0)
                                                        ? (day.openingHoursAMPM == "PM" && day.openingHours < 12
                                                            ? ((parseInt(day.openingHours) + 12).toString() + ":" + day.openingMinutes)
                                                            : (day.openingHoursAMPM == "AM" && day.openingHours === "12" 
                                                                ? ("00" + ":" + day.openingMinutes) 
                                                                : (day.openingHours + ":" + day.openingMinutes)))
                                                        : ""
                                                }
                                                inputOnChange={(e) => timeChange("opening", e, index, dayIndex)}
                                            />
                                            
                                        </div>
                                        <div className={!day.openOnWeekday ? "hidden-timepickers" : "time-picker-container"}>
                                            <FloatLabelTimePicker
                                                inputLabel="CLOSE TIME"
                                                inputAutoFocus="true"
                                                inputName="CloseHours"
                                                inputDisabled={day.open24Hours}
                                                inputValue={
                                                    (day.closingHours.length > 0 && day.closingMinutes.length > 0) 
                                                    ? (day.closingHoursAMPM == "PM" && day.closingHours < 12
                                                        ? ((parseInt(day.closingHours) + 12).toString() + ":" + day.closingMinutes)
                                                        : (day.closingHoursAMPM == "AM" && day.closingHours === "12" 
                                                            ? ("00" + ":" + day.closingMinutes) 
                                                            : (day.closingHours + ":" + day.closingMinutes)))
                                                : ""
                                                }
                                                inputOnChange={(e) => timeChange("closing", e, index, dayIndex)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Form.Group>
                    ))) : (<></>)}
                    {/* <div className={canAddNewHoursSet ? "add-hours-container" : "add-hours-container add-hours-disabled"} onClick={() => addNewHours()}>
                        <AddCircleOutlineIcon />
                        <AddCircleRoundedIcon />
                        Add another section for type of hours
                    </div> */}
                </Form>
            </div>
        </div>
    );
}