import React from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.css";

export default function ToggleSwitch(props) {
  
    function toggleChange(e) {
        props.onToggleChange(e);
    }

    function handleKeyPress(e) {
        if (e.keyCode !== 32) return;
    
        e.preventDefault();
        toggleChange(e);
      }

    return (
    <div className="toggle-switch small-switch">
        <input
        type="checkbox"
        name={props.name}
        className="toggle-switch-checkbox"
        id={props.id}
        checked={props.checked}
        onChange={toggleChange}
        />
        {props.id ? (
        <label className="toggle-switch-label" onKeyDown={(e) => handleKeyPress(e)} htmlFor={props.id}>
            <span className="toggle-switch-inner"/>
            <span className="toggle-switch-switch"/>
        </label>
        ) : null}
    </div>
    )
}