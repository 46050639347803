import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import IsMobileDevice from "../IsMobileDevice";
import "./EditProcedures.css";
import FloatLabelTextBox from "../FloatLabelTextBox";
import EditLocationRadioButtons from "./EditLocationRadioButtons";

export default function EditProcedures(props) {

  const isMobileView = IsMobileDevice();
  const[procedures, setProcedures] = useState(props.procedures);
  const radioOptions =  isMobileView ? [ {id: 1, name: "Yes"}, {id: 0, name: "No"}, {id: 2, name: "Not Sure"}] : [ {id: 1, name: "Yes"}, {id: 0, name: "No"}];
  const [helperText, setHelperText] = useState({
    equipmentName: "",
    covidProtocolInstructions: "",
    });
  const [errorClass, setErrorClass] = useState({
    drivingDirections: ""
  }); 

  function handleChange(target, maxLength){
    let inputValue = null;
    let isValid = true;
    switch(target.name){
        case 'reportToGuardShack':
        case 'driverUnload':
        case 'dockAssist':
            inputValue = parseInt(target.value) > 1 ? null : (parseInt(target.value) === 1);;
            break;

        case 'drivingDirections': let errorMsg = "";
                                          let errorCls = "";
                                          if(target.value.length <= maxLength){
                                            inputValue = target.value.length > 0 ? target.value : null;
                                          }
                                          else{
                                              isValid = false;                                           
                                              errorMsg = "The max length of " + maxLength + " characters is reached.";
                                              errorCls = "bh-error";
                                          }

                                          setHelperText({
                                            ...helperText,
                                            drivingDirections: errorMsg,
                                          });
                                          setErrorClass({
                                            ...errorClass,
                                            drivingDirections: errorCls,
                                          });
                                          break;

        default:                          inputValue = target.checked;
                                          break;
    }

    if(isValid){
        setProcedures({
            ...procedures,
            [target.name]: inputValue,
        });
    }    
  }

  useEffect(() => {
    props.handleChange("procedures", procedures);
  },[procedures]);

  return (
    <div className="edit-details-body procedures" style={{ paddingTop: "1%" }}>
        <EditLocationRadioButtons 
            radioLabel="Report to guard shack"
            radiolabelDescription="Do drivers need to report to the guard shack?"
            radioName="reportToGuardShack"
            radioOptions={radioOptions}
            radioChecked={procedures.reportToGuardShack ? 1 : (procedures.reportToGuardShack === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        <EditLocationRadioButtons 
            radioLabel="Driver load/unload"
            radiolabelDescription="Does this facility allow driver load or unload?"
            radioName="driverUnload"
            radioOptions={radioOptions}
            radioChecked={procedures.driverUnload ? 1 : (procedures.driverUnload === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        <EditLocationRadioButtons 
            radioLabel="Dock Assist"
            radiolabelDescription="Does this facility offer dock assist?"
            radioName="dockAssist"
            radioOptions={radioOptions}
            radioChecked={procedures.dockAssist ? 1 : (procedures.dockAssist === false) ? 0 : null}
            defaultValue={2}
            onRadioChange={handleChange}
        />
        <div className="textarea-padding-mobile">
          <div className="edit-details-title" style={{ marginTop: "2%" }}>Additional Driver Directions</div>
          <div className="label-description">Please specify directions for drivers to navigate around your facility.</div>
          <Form className="medium-form-group text-area-container">
              <FloatLabelTextBox
                  inputLabel=""
                  inputAutoFocus="true"
                  inputType="textarea"
                  inputName="drivingDirections"
                  inputValue={procedures.drivingDirections ? procedures.drivingDirections : ""}
                  inputClassName={`dynamic-form-text ${errorClass.drivingDirections}`}
                  inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.drivingDirections}</div><div style={{ float: "right" }}>{procedures.drivingDirections ? procedures.drivingDirections.length : 0}/500</div></div>]}
                  handleChangeProps={(e) => handleChange(e.target, 500)}
                  inputRows={10}
                  inputAs="textarea"
                  inputSpellCheck={true}
              />
          </Form>
        </div>
    </div>
  )
}