import React, { useState } from "react";
import "./LocationDetailsDesktopView.css";
import NoTruckPathImage from '../../images/location-details/NoTruckPathImage.svg'
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import BingMap from "../../components/BingMap.js";
import L from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import GeoJSONCluster from "../../components/GeoJSONCluster";
import ReactTooltip from "react-tooltip";
import Looks from "../../components/looks/Looks";
import "../../components/elements/button/AppButton.css";
import EditCompanyLocation from "../../components/EditCompanyLocation";
import LocationAddress from "../../components/locationdetails/LocationAddress";
import LocationType from "../../components/locationdetails/LocationType";
import LocationContacts from "../../components/locationdetails/LocationContacts";
import LocationBusinessHours from "../../components/locationdetails/LocationBusinessHours";
import LocationAmenities from "../../components/locationdetails/LocationAmenities";
import LocationServices from "../../components/locationdetails/LocationServices";
import LocationSafetyRequirements from "../../components/locationdetails/LocationSafetyRequirements";
import LocationProcedures from "../../components/locationdetails/LocationProcedures";
import LocationClosures from "../../components/locationdetails/LocationClosures";
import LocationAppointmentSchedulingDesktop from "../../components/locationdetails/LocationAppointmentSchedulingDesktop";
import LocationID from "../../components/locationdetails/LocationID";

export default function LocationDetailsDesktopView(props) {
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div>
                {value === index && (
                    <div p={3}>
                        <div>{children}</div>
                    </div>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }));

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const geoJsonStyles = {
        fontFamily: "sans-serif",
        textAlign: "center",
        height: "100%"
    };

    const geoJsonMapStyle = {
        height: "470px"
    };

    const nullPoint = "{\"type\": \"Polygon\", \"coordinates\": [[[0, 0], [0, 0.000000001], [0.000000001, 0], [0, 0]]]}";

    return (
        <div>
            {(props.location !== undefined && props.location !== null) ? (
                <>
                    <div className="location-details-main">
                        <div className="location-details-side-bar">
                            <div className="details-container">
                                <LocationAddress
                                    address={props.location.address}
                                    city={props.location.city}
                                    state={props.location.state}
                                    zipCode={props.location.zipCode}
                                ></LocationAddress>
                            </div>
                            <div className="details-container">
                                <LocationType
                                    locationTypeDescription={props.location.locationTypeDescription}
                                ></LocationType>
                            </div>
                            <div className="details-container">
                                <LocationID
                                    id={props.location.id}
                                ></LocationID>
                            </div>
                            <div className="details-container">
                                <LocationContacts
                                    contactInfo={props.location.contactInfo}
                                    formatPhoneNumber={props.formatPhoneNumber}
                                ></LocationContacts>
                            </div>
                            <div className="details-container">
                                <LocationBusinessHours
                                    timeZoneAbbreviation={props.location.timeZoneAbbreviation}
                                    businessHours={props.location.businessHours}
                                >
                                </LocationBusinessHours>
                                <LocationClosures
                                    mainClosures={props.location.mainClosures}
                                ></LocationClosures>
                                <LocationAppointmentSchedulingDesktop
                                    appointmentScheduling={props.location.appointmentScheduling}
                                    formatPhoneNumber={props.formatPhoneNumber}
                                >
                                </LocationAppointmentSchedulingDesktop>
                            </div>
                            <div className="details-container">
                                <LocationAmenities
                                    restrooms={props.location.restrooms}
                                    overnightParking={props.location.overnightParking}
                                    breakroom={props.location.breakroom}
                                    vendingMachine={props.location.vendingMachine}
                                    showers={props.location.showers}
                                    wiFi={props.location.wiFi}
                                    additionalAmenities={props.location.additionalAmenities}
                                ></LocationAmenities>
                            </div>
                            <div className="details-container">
                                <LocationServices
                                    services={props.location.services}
                                ></LocationServices>
                            </div>
                            <div className="details-container">
                                <LocationSafetyRequirements
                                    safetyRequirements={props.location.safetyRequirements}
                                    additionalSafetyRequirements={props.location.additionalSafetyRequirements}
                                ></LocationSafetyRequirements>
                            </div>
                            <div className="details-container">
                                <LocationProcedures
                                    procedures={props.location.procedures}
                                ></LocationProcedures>
                            </div>
                        </div>
                        <div className="location-details-right-container">
                            {props.session?.roleName !== "Viewer" &&
                            <EditCompanyLocation companyId={props.session.companyId} location={props.location}></EditCompanyLocation>}
                            <div style={{ marginLeft: '1.5%', marginRight: '5%' }}>
                                <p className='location-details-name'>{props.location.name}</p>
                                <div>
                                    {props.location.additionalCompanyNames !== null && props.location.additionalCompanyNames !== undefined && props.location.additionalCompanyNames.length !== 0 && props.location.additionalCompanyNames[0].companyName !== null && props.location.additionalCompanyNames[0].companyName !== undefined &&
                                        <div style={{ marginBottom: '20px' }}>There are other names or companies associated with this address. <a className='additional-names-link'
                                            data-for="additional-names"
                                            data-tip
                                            data-event="click focus"
                                            data-multiline={true}
                                            data-place='bottom'
                                            data-type='light'>
                                            Click here
                                        </a>
                                            <ReactTooltip id="additional-names" globalEventOff="click" className='additional-names' getContent={() => <div className='additional-names-div'><b>Other Names at this location</b><hr />
                                                {props.location.additionalCompanyNames.map((item, index) => <span className='additional-name' key={index}>{item.companyName}<br /></span>)}</div>} /> to view.</div>}
                                    <div className={classes.root}>
                                        {/* <AppBar position="static"> */}
                                        <Tabs TabIndicatorProps={{ style: { background: '#00A131' } }} className="location-details-desktop-tabs" value={value} onChange={handleChange}>
                                            <Tab label="Location" {...a11yProps(0)} />
                                            <Tab label="Geofence" {...a11yProps(1)} />
                                            <Tab label="Truck Paths" {...a11yProps(2)} />
                                        </Tabs>
                                        {/* </AppBar> */}
                                        <TabPanel value={value} index={0}>
                                            <div className='location-details-map'>
                                                <BingMap
                                                    height="100%"
                                                    width="100%"
                                                    pins={[props.location]}
                                                />
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <div className='location-details-map'>
                                                {(props.location.geoFence1 && props.location.geoFence1 !== nullPoint) ?
                                                    <div style={geoJsonStyles}>
                                                        <MapContainer
                                                            style={geoJsonMapStyle}
                                                            bounds={L.geoJSON(JSON.parse(props.location.geoFence1)).getBounds()}
                                                            key="map"
                                                            scrollWheelZoom={true}
                                                            zoom={13}>
                                                            <TileLayer
                                                                url={"http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"}
                                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                maxZoom='20'
                                                                minZoom='2'
                                                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                                            />
                                                            <GeoJSONCluster data={JSON.parse(props.location.geoFence1)} />
                                                        </MapContainer>
                                                    </div>
                                                    : <div className='no-geofence-data-div'>
                                                        <div className='no-geofence-data-center'>
                                                            <p style={{ fontFamily: 'Roboto-Medium', fontSize: 18, height: 20 }}>NO DATA AVAILABLE FOR GEOFENCE YET.</p>
                                                            <img style={{ marginTop: 50 }} src={NoTruckPathImage} alt="" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <div className='location-details-map'>
                                                {(props.location.geoFence2 && props.location.geoFence2 !== nullPoint) ?
                                                    <div style={geoJsonStyles}>
                                                        <MapContainer
                                                            style={geoJsonMapStyle}
                                                            bounds={L.geoJSON(JSON.parse(props.location.geoFence2)).getBounds()}
                                                            key="map"
                                                            scrollWheelZoom={true}
                                                            zoom={13}>
                                                            <TileLayer
                                                                url={"http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"}
                                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                maxZoom='20'
                                                                minZoom='2'
                                                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                                            />
                                                            <GeoJSONCluster data={JSON.parse(props.location.geoFence2)} />
                                                        </MapContainer>
                                                    </div>
                                                    : <div className='no-geofence-data-div'>
                                                        <div className='no-geofence-data-center'>
                                                            <p style={{ fontFamily: 'Roboto-Medium', fontSize: 18, height: 20 }}>NO DATA AVAILABLE FOR TRUCK PATHS YET.</p>
                                                            {/* <p style={{fontFamily: 'Roboto-Light', fontSize: 18, height: 20}}>Are you the owner of this company?</p>
                                            <p style={{fontFamily: 'Roboto-Light', fontSize: 18, height: 20}}>Claim this location to add additional information to this page.</p> */}
                                                            <img style={{ marginTop: 50 }} src={NoTruckPathImage} alt="" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </TabPanel>
                                    </div>
                                </div>
                                <div className='word-cloud-main' style={{ marginTop: "40px" }}>
                                    <div className='word-cloud-label'>What Drivers Are Saying</div>
                                    {(props.location.wordCloud !== null && props.location.wordCloud !== undefined) ?
                                        <div className='word-cloud-section'>{Object.keys(props.location.wordCloud).map((key, index) =>
                                            <div className="word-cloud-child" key={index}><span className='word'>{key}</span><span className='count' style={{ marginLeft: '15px' }}>{props.location.wordCloud[key]}</span></div>)}
                                        </div> : <div className='word-cloud-section'><p style={{ fontFamily: 'Roboto-Medium', fontSize: 18, height: 20 }}>NO DATA AVAILABLE YET FOR THIS LOCATION.</p></div>}
                                </div>
                                <div className='analytics-section' style={{ marginTop: "40px" }}>
                                    {props.session.isInternalUser === true ?
                                        <>
                                            {props.location.hideAnalyticsToExternalUser === true ? <div className='analytics-label analytics-label-internal'>Analytics - This is not shown to external users, does not meet carrier threshold.</div> : <div className='analytics-label'>Analytics</div>}
                                            <Looks locationId={props.location.id} timeZone={props.location.timeZone} />
                                        </>
                                        :
                                        <>
                                            <div className='analytics-label'>Analytics</div>
                                            {props.location.hideAnalyticsToExternalUser === true ? <div className='word-cloud-section'><p style={{ fontFamily: 'Roboto-Medium', fontSize: 18, height: 20 }}>NO DATA AVAILABLE YET FOR THIS LOCATION.</p></div> :
                                                <Looks locationId={props.location.id} timeZone={props.location.timeZone} />}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (<></>)}
        </div>
    );
}