import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from 'react-bootstrap/Dropdown'
import "./MainLayout.css";
import "./NavbarMobileLayout.css";
import "./NavbarLayout.css";
import { LinkContainer } from "react-router-bootstrap";
import HeaderImageMobileLandscape from '../images/Main-Layout-Header-Image-Landscape-Mobile.png';
import HeaderImageTabletLandscape from '../images/Main-Layout-Header-Image-Landscape-Tablet.png';
import HeaderImageMobilePortrait from '../images/Main-Layout-Header-Image-Mobile.png';
import HeaderImageTabletPortrait from '../images/Main-Layout-Header-Image-Tablet.png';
import OmnitracsLogo from '../images/LocationIntelligence.svg';
import SoleraLogoWhite from '../images/solera-logo-white.svg';
import LocIntelligenceLogoWhite from '../images/location-intelligence-logo-white.svg';
import SettingsIcon from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { useSessionContext } from "../libs/sessionLib";
import IsMobileDevice from "../components/IsMobileDevice";
import SearchIcon from '@mui/icons-material/Search';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  smallblue: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText('#215BA6'),
    backgroundColor: '#215BA6',
    display: 'inline-flex',
    margin: '0 0 0 15px',
    fontSize: '0.9rem',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function MainLayout({ children }) {
  const isMobileView = IsMobileDevice();
  const { session } = useSessionContext();
  const classes = useStyles();
  const [showNavBar, setShowNavBar] = useState(false);
  const searchPage = "/locationsearch";

  function redirectToSearchPage() {
    window.location.href = searchPage;
  }

  function handleNavBarStateChange(isOpen, e) {
    setShowNavBar(isOpen);
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    // if the search screen is in the 'results screen' state, need to reload search page to set it back to the default state (filter screen)
    if (e && e.target.pathname === searchPage) redirectToSearchPage();
  }

  return (
    <>
      <div className="MainLayout container py-3">
        <Navbar collapseOnSelect expand="md" className="mb-3 main-nav" expanded={showNavBar}>
          <LinkContainer to="/locationsearch">
            <Navbar.Brand href="/locationsearch" onClick={isMobileView ? () => redirectToSearchPage() : undefined} className="font-weight-bold text-muted">
              <img src={OmnitracsLogo} alt="" />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle onClick={() => handleNavBarStateChange(true)} />
          <Navbar.Collapse className="navbar-items-container">
            {isMobileView ?
              <Nav className="navbar-items mobile-navbar" >
                <div className="navbar-items-header">
                  <img id="solera-logo" src={SoleraLogoWhite} alt="" />
                  <img id="loc-intel-logo" src={LocIntelligenceLogoWhite} alt="" />
                  <div className="close-icon" onClick={() => handleNavBarStateChange(false)}><CloseIcon /></div>
                </div>
                <LinkContainer to={"/locationsearch"}>
                  <Nav.Link onClick={(e) => handleNavBarStateChange(false, e)}><SearchIcon />Search Locations</Nav.Link>
                </LinkContainer>
                <LinkContainer to={"/updatepassword"}>
                  <Nav.Link onClick={(e) => { handleNavBarStateChange(false, e); }}><LockResetIcon />Update Password</Nav.Link>
                </LinkContainer>
                <LinkContainer to={"/logout"}>
                  <Nav.Link onClick={() => handleNavBarStateChange(false)}><LogoutIcon />Log Out</Nav.Link>
                </LinkContainer>
              </Nav>
              :
              <Nav className="navbar-items">
                <LinkContainer to="/locationsearch">
                  <Nav.Link>Search</Nav.Link>
                </LinkContainer>
                {(session !== undefined) ? (
                  <LinkContainer to={"/company/" + session.companyId}>
                    <Nav.Link>My Company</Nav.Link>
                  </LinkContainer>
                ) : (<></>)}
              </Nav>
            }
            <div className="right-align-nav">
              {(session !== undefined) && session.isInternalUser && session.roleId === 1 ? (
                <Dropdown className="inline-dropdown" id="settings-dropdown">
                  <Dropdown.Toggle as={CustomToggle}>
                    <SettingsIcon style={{ color: '#215BA6' }} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right">
                    <Dropdown.Item href='/internaladminportal'>Internal Admin</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (<></>)}
              <Dropdown className="inline-dropdown" id="account-dropdown">
                <Dropdown.Toggle as={CustomToggle}>
                  <Avatar className={classes.smallblue}>{session !== undefined ? session.initials : ""}</Avatar>
                </Dropdown.Toggle>
                {(session !== undefined) ? (
                  <Dropdown.Menu align="right">
                    <Dropdown.Item href='/updatepassword'>Update Password</Dropdown.Item>
                    <Dropdown.Item href='/logout'>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                ) : (<></>)}
              </Dropdown>
            </div>
          </Navbar.Collapse>
        </Navbar>
        {isMobileView &&
          <div className="main-layout-header-image-mobile">
            <img className="header-image-mobile-portrait" src={HeaderImageMobilePortrait} alt="" />
            <img className="header-image-mobile-landscape" src={HeaderImageMobileLandscape} alt="" />
            <img className="header-image-tablet-portrait" src={HeaderImageTabletPortrait} alt="" />
            <img className="header-image-tablet-landscape" src={HeaderImageTabletLandscape} alt="" />
          </div>}
        <div className="Main-Layout-Body">
          {children}
        </div>
      </div>
    </>
  );
}
export default MainLayout;