import React from "react";
import Form from "react-bootstrap/Form";
import "./FixedLabel.css";

export default function FixedLabelDropdown(props) {
    let optionsList;
    if (props.selectOptions !== undefined && props.selectOptions.length) {
        optionsList = props.selectOptions.map((item, index) =>
            <option key={index} value={item.id}>{item.name}</option>
        );
    }
    else {
        optionsList = <></>;
    }
    return (
        <>
            <div className="fixed-label" style={{ display: "block" }}>
                <label className="dropdown-fixed-label" htmlFor={props.inputName}>{props.inputLabel}</label>
                <Form.Control
                    data-testid={props.inputName}
                    id={props.inputName}
                    autoFocus={props.inputAutofocus}
                    type={props.inputType}
                    placeholder={props.inputPlaceholder}
                    value={props.inputValue}
                    name={props.inputName}
                    onChange={(e) => props.handleChangeProps(e)}
                    className={props.inputClassName}
                    disabled={props.inputDisabled}
                    as="select"
                    required={props.inputRequired}
                >
                    {props.disableDefaultOption ? (<option key="" value="" disabled>{props.defaultOption}</option>) : (<option key="" value="" >{props.defaultOption}</option>)}
                    {optionsList}
                </Form.Control>
            </div>
            {props.inputHelperText !== undefined && props.inputHelperText.length > 0 ? (
                <Form.Text className={props.inputClassName}>{props.inputHelperText}</Form.Text>
            ) : (
                <></>
            )}
        </>
    );
}