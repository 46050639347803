import React, { useEffect, useState } from "react";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../../constants/apiConstants';
import useAxios from 'axios-hooks';
import { onError } from "../../libs/errorLib";
import "./MobileAnalytics.css";

export default function MobileAnalytics(props) {
    const current = new Date();
    const api_search_url = ENVIRONMENT === "development" ? "/api/location/locationvisitmetrics" + "?locationid=" + props.locationid + "&retrieveLatestMonthMetrics=true" : API_BASE_URL + "/location/locationvisitmetrics" + "?locationid=" + props.locationid + "&retrieveLatestMonthMetrics=true";
    const [{ data: analytics, loading, error }, fetchData] = useAxios(
        {
            url: api_search_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        try {
            fetchData();
        } catch (e) {
            onError("Error getting location analytics");
            setNoAnalyticsAlert("No analytics available.");
        }
    };

    const [avgVisitDuration, setAvgVisitDuration] = useState("");
    const [visitsOver2Hours, setVisitOver2Hours] = useState("");
    const [busiestDayOfWeek, setBusiestDayOfWeek] = useState([]);
    const [busiestHourOfDay, setBusiestHourOfDay] = useState("");
    const [noAnalyticsAlert, setNoAnalyticsAlert] = useState("");

    useEffect(() => {
        if (analytics && analytics.length > 0) {
            setNoAnalyticsAlert("");
            if (analytics[0]) {
                setAvgVisitDuration(analytics[0].averageDurationInMins);
                setVisitOver2Hours(Math.round((analytics[0].visitsLongerThan2Hours / analytics[0].totalVisits) * 100));
                setBusiestHourOfDay(analytics[0].busiestHourForWeek);

                const highestCount = Math.max(analytics[0].mondayVisits, analytics[0].tuesdayVisits, analytics[0].wednesdayVisits, analytics[0].thursdayVisits, analytics[0].fridayVisits, analytics[0].saturdayVisits, analytics[0].sundayVisits);

                setBusiestDayOfWeek([]);
                if (analytics[0].mondayVisits === highestCount) {
                    setBusiestDayOfWeek([...busiestDayOfWeek, "Monday"]);
                }
                if (analytics[0].tuesdayVisits === highestCount) {
                    setBusiestDayOfWeek([...busiestDayOfWeek, "Tuesday"]);
                }
                if (analytics[0].wednesdayVisits === highestCount) {
                    setBusiestDayOfWeek([...busiestDayOfWeek, "Wednesday"]);
                }
                if (analytics[0].thursdayVisits === highestCount) {
                    setBusiestDayOfWeek([...busiestDayOfWeek, "Thursday"]);
                }
                if (analytics[0].fridayVisits === highestCount) {
                    setBusiestDayOfWeek([...busiestDayOfWeek, "Friday"]);
                }
                if (analytics[0].saturdayVisits === highestCount) {
                    setBusiestDayOfWeek([...busiestDayOfWeek, "Saturday"]);
                }
                if (analytics[0].sundayVisits === highestCount) {
                    setBusiestDayOfWeek([...busiestDayOfWeek, "Sunday"]);
                }
            }
            else {
                setNoAnalyticsAlert("No analytics available.");
            }
        }
        else {
            setNoAnalyticsAlert("No analytics available.");
        }
    }, [analytics]);
    return (
        <>
            {(loading) ? (<p className="no-analytics">Gathering data...</p>) : (
                (noAnalyticsAlert && noAnalyticsAlert.length > 0) ? (
                    <p className="no-analytics">{noAnalyticsAlert}</p>
                ) : (
                    <>
                        <div className="mobile-analytics-container">
                            <p className="mobile-analytics-title">AVG. DWELL TIME FOR LAST MONTH</p>
                            {(avgVisitDuration > 0) ? (
                                <>
                                    <p className="mobile-analytics-data">
                                        {avgVisitDuration}
                                    </p>
                                    <p className="mobile-analytics-data-units">MINUTES</p>
                                </>
                            ) : (<p className="no-data">Oops, we don't have the data for this metric.</p>)}
                        </div>
                        <div className="mobile-analytics-container">
                            <p className="mobile-analytics-title">VISIT DURATIONS LONGER THAN 2 HOURS FOR LAST MONTH</p>
                            {(visitsOver2Hours > 0) ? (
                                <>
                                    <p className="mobile-analytics-data">
                                        {visitsOver2Hours}%
                                    </p>
                                </>
                            ) : (<p className="no-data">Oops, we don't have the data for this metric.</p>)}
                        </div>
                        <div className="mobile-analytics-container">
                            <p className="mobile-analytics-title">BUSIEST DAY OF WEEK FOR LAST MONTH</p>
                            {(busiestDayOfWeek && busiestDayOfWeek.length > 0) ? (
                                busiestDayOfWeek.map((day) =>
                                    <p className="mobile-analytics-data">
                                        {day}
                                    </p>
                                )
                            ) : (<p className="no-data">Oops, we don't have the data for this metric.</p>)}
                        </div>
                        <div className="mobile-analytics-container" style={{ marginBottom: "20px" }}>
                            <p className="mobile-analytics-title">BUSIEST TIME OF DAY FOR LAST MONTH</p>
                            {(busiestHourOfDay.length > 0) ? (
                                <>
                                    <p className="mobile-analytics-data">
                                        {busiestHourOfDay}
                                    </p>
                                </>
                            ) : (<p className="no-data">Oops, we don't have the data for this metric.</p>)}
                        </div>
                    </>
                )
            )}
        </>
    );
}