import React from "react";
import AddressIcon from '../../images/a-icon-map-location-on.svg'
import "./LocationDetailsComponents.css";

export default function LocationAddress(props) {
    return (
        <div style={{display: "inline-flex", width: "100%"}}>
            <div className="location-details-icon"><img src={AddressIcon} alt="" /></div>
            <div className="location-details-body"><p className='location-details-label'>Address</p>
                <p>{props.address} </p><p>{props.city}, {props.state} {props.zipCode}</p></div>
        </div>
    )
}