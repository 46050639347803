import React, { useEffect, useState } from "react";
import EditLocationRadioButtons from "./EditLocationRadioButtons";

export default function EditAmenities(props) {

  const[locationType, setLocationType] = useState(props.locationType);

  function handleChange(target){
      const value = parseInt(target.value);
      setLocationType({
        ...locationType,
        locationTypeId: value, 
      });
  }

  useEffect(() => {
    props.handleChange("locationType", locationType);
  },[locationType]);

  return (
    props.locationTypes ? (
    <div className="edit-details-body">
        <div className="edit-details-description">
            Select the option that best describes this location type.
        </div>
        <div className="edit-details-body-padding">
          <EditLocationRadioButtons 
              radioName="locationType"
              radioOptions={props.locationTypes}
              radioChecked={props.locationType?.locationTypeId}
              onRadioChange={handleChange}
          />
        </div>
    </div>
    ) : <></>
  );
}