import React from "react";
import "./OmniFooter.css";
import { Link } from "react-router-dom";

export default function OmniFooter() {
  return (
    <div className="row footer">
      <p>© {new Date().getFullYear()} Solera | Omnitracs. All rights reserved</p>
      <a href="https://www.omnitracs.com/terms-use" target="_blank">Terms of use</a>
      {/* <a href="" target="_blank">Privacy Policy</a> */}
    </div>
  );
}