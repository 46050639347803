import React, { useEffect, useState } from "react";
import "./RateLocation.css";
import Form from "react-bootstrap/Form";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FloatLabelTextBox from "../FloatLabelTextBox";

export default function RateLocation(props) {

    const [review, setReview] = useState(props.review);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    const [helperText, setHelperText] = useState({
        reviewText: "",
    });
    const [errorClass, setErrorClass] = useState({
        reviewText: "",
    });

    function handleChange(target, value, maxLength) {
        let inputValue = null;
        let isValid = true;
        switch (target) {
            case 'starReview':
                inputValue = parseInt(value);
                break;

            case 'reviewText':
                let errorMsg = "";
                let errorCls = "";
                if (value.length <= maxLength) {
                    inputValue = value.length > 0 ? value : null;
                }
                else {
                    isValid = false;
                    errorMsg = "The max length of " + maxLength + " characters is reached.";
                    errorCls = "bh-error";
                }

                setHelperText({
                    ...helperText,
                    covidProtocolInstructions: errorMsg,
                });
                setErrorClass({
                    ...errorClass,
                    covidProtocolInstructions: errorCls,
                });
                break;
            default:
                break;
        }
        if (isValid) {
            setReview({
                ...review,
                [target]: inputValue,
            });
        }
    }

    useEffect(() => {
        props.handleChange("rateLocation", review);
    }, [review]);

    useEffect(() => {
        handleChange("starReview", rating, 0);
    }, [rating]);

    return (
        <div className="edit-details-body rate-location-container" style={{ paddingTop: "1%" }}>
            <p className="rating-title">Write a Review</p>
            <p className="rating-description">How would you rate your overall experience at this location?</p>
            <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            type="button"
                            key={index}
                            name="starReview"
                            id="starReview"
                            className={index <= (hover || rating) ? "on" : "off"}
                            onClick={() => setRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span className="filled-star"><StarIcon /></span>
                            <span className="empty-star"><StarBorderIcon /></span>
                        </button>
                    );
                })}
                <p>Tap a star to rate</p>
            </div>
            <Form className="medium-form-group text-area-container">
                <FloatLabelTextBox
                    inputLabel=""
                    inputAutoFocus="true"
                    inputType="textarea"
                    inputName="reviewText"
                    inputValue={(review && review.reviewText) ? review.reviewText : ""}
                    inputClassName={`dynamic-form-text ${errorClass.reviewText}`}
                    inputHelperText={[<div key={-1} style={{ paddingBottom: "10px" }}><div style={{ float: "left" }}>{helperText.reviewText}</div><div style={{ float: "right" }}>{(review && review.reviewText) ? review.reviewText.length : 0}/2000</div></div>]}
                    handleChangeProps={(e) => handleChange(e.target.name, e.target.value, 2000)}
                    inputRows={10}
                    inputAs="textarea"
                    inputSpellCheck={true}
                />
            </Form>
        </div>
    )
}