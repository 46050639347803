import React, { useState } from "react";
import './Home.css';
import DataPointIcon from '../images/homepage/multi_icon.svg';
import UsersIcon from '../images/homepage/users_icon.svg';
import LocationsIcon from '../images/homepage/locations_icon.svg';
import AddressImage from '../images/homepage/address_image.png';
import AmenitiesImage from '../images/homepage/amenities_image.png';
import GeofenceImage from '../images/homepage/geofence_image.png';
import ServicingImage from '../images/homepage/servicing_image.png';
import VisitImage from '../images/homepage/visit_image.png';
import AddressImageMobile from '../images/homepage/address_image_mobile.png';
import AmenitiesImageMobile from '../images/homepage/amenities_image_mobile.png';
import GeofenceImageMobile from '../images/homepage/geofence_image_mobile.png';
import ServicingImageMobile from '../images/homepage/servicing_image_mobile.png';
import VisitImageMobile from '../images/homepage/visit_image_mobile.png';
import ShipperImage from '../images/homepage/shipper_receiver.png';
import DriverImage from '../images/homepage/driver_carrier.png';
import ThirdPartyImage from '../images/homepage/third_party.png';
import TabPanelContainer from '../components/elements/tab/TabPanelContainer.js';

export default function Home(props) {

    const isMobileView = window.matchMedia("screen and (max-width: 428px) and (orientation: portrait)").matches;

    const locationIntelFeautures = [
        {
            id: "address-contact",
            title: "Address & Contact Info",
            description: "USPS standardized addresses and location contact information.",
            image: isMobileView ? AddressImageMobile : AddressImage
        },
        {
            id: "amenities",
            title: "Amenities",
            description: "Restrooms? Overnight parking? Use this data to help with shipment planning & scheduling.",
            image: isMobileView ? AmenitiesImageMobile : AmenitiesImage
        },
        {
            id: "geofence",
            title: "Geofence & Truck Paths",
            description: "Visualizations for location boundaries and transportation flow.",
            image: isMobileView ? GeofenceImageMobile : GeofenceImage
        },
        {
            id: "visit-trends",
            title: "Visit Trend Analytics",
            description: "Insight into location performance, peak hours and daily traffic flow.",
            image: isMobileView ? VisitImageMobile : VisitImage
        },
        {
            id: "servicing",
            title: "Servicing Requirements",
            description: "Understanding what is required when servicing a particular location.",
            image: isMobileView ? ServicingImageMobile : ServicingImage
        }
    ]

    const shipperReceiver = 
            <div className="customer-type-tab-body">
                <div className="customer-type-tab-text">
                    <span className="tab-text-description" style={{ marginTop: "-10px"}}>Our Customers</span>
                    <div className="tab-text-title">Shippers / Receivers</div>
                    <div className="tab-text-description">Insights into a location’s operational performance (avg dwell, peak hours, visit counts).</div>
                    <div className="tab-text-description">Centralized location to communicate shipping procedures & requirements with carriers/drivers/customers.</div> 
                    <div className="tab-text-description">The ability to contribute to and enrich location data promoting accuracy and potentially increasing location efficiency.</div>
                    <div className="tab-text-description">Geofence and truck path visualizations to ease congestion and traffic flow.</div>
                </div>
                <div style={{ alignSelf: "center" }}><img src={ShipperImage} alt="" /></div>
            </div>;

    const carrier = 
    <div className="customer-type-tab-body">
        <div className="customer-type-tab-text">
            <span className="tab-text-description" style={{ marginTop: "-10px"}}>Our Customers</span>
            <div className="tab-text-title">Carrier / Driver</div>
            <div className="tab-text-description">Access to information that will aid shipment planning efforts (parking, restrooms, amenities).</div>
            <div className="tab-text-description">Insights into a location’s operational performance (avg dwell, peak hours, visit counts), allowing you to better assess cost to serve.</div> 
            <div className="tab-text-description">Centralized location for location shipping procedures & requirements.</div>
            <div className="tab-text-description">Geofence & truck path visualization to ease last-mile navigation challenges.</div>
            <div className="tab-text-description">Use real-life experiences to enrich/contribute to the dataset.</div>
        </div>
        <div style={{ alignSelf: "center" }}><img src={DriverImage} alt="" /></div>
    </div>;

    const thirdParty = 
    <div className="customer-type-tab-body">
        <div className="customer-type-tab-text">
            <span className="tab-text-description" style={{ marginTop: "-10px"}}>Our Customers</span>
            <div className="tab-text-title">3rd Party</div>
            <div className="tab-text-description">Access to information that will aid shipment planning / transportation efforts (parking, restrooms, amenities).</div>
            <div className="tab-text-description">Insights into a location’s operational performance (avg dwell, peak hours, visit counts), allowing you to better assess cost to serve.</div> 
            <div className="tab-text-description">Geofence and truck path visualizations to reduce congestion and ease last-mile navigation challenges.</div>
        </div>
        <div style={{ alignSelf: "center" }}><img src={ThirdPartyImage} alt="" /></div>
    </div>;

    const elements = [["Shipper/Receiver", shipperReceiver], ["Carrier/Driver", carrier], ["3rd Party", thirdParty]];

    window.addEventListener('orientationchange', function () {
        this.window.location.reload();
    });

    return(
        <div className="home-page-container">
            <div className="home-page-header-container">
                <div className="home-page-header">
                    <div className="home-page-title">Location Intelligence</div>
                    <div className="home-page-title-description">
                    Leveraging the strength of its network of over 1 million assets, Omnitracs by Solera has created a centralized database of nearly 3 million locations in the United States. Using anonymized, aggregated data we are solving a pervasive industry problem in transportation - the lack of centralized intelligence for shipping/receiving locations.
                    </div>                   
                </div>
            </div>
            <div className="header-bottom-container">
                <div className="header-bottom-sections">
                    <img src={DataPointIcon} alt="" />
                    <p>We provide valuable industry data points for each location, creating transparency around servicing and performance.</p>        
                </div>
                <div className="header-bottom-sections">
                    <img src={UsersIcon} alt="" />
                    <p>Customers can consume and contribute to the dataset creating a more industry enriched dataset over time.</p>
                </div>
                <div className="header-bottom-sections">
                    <img src={LocationsIcon} alt="" />
                    <p style={{ margin: "0 4.5vw" }}>Location Intelligence provides all parties in the supply chain with a competitive edge.</p>
                </div>
            </div>
            {isMobileView ?
                (<div className= "home-page-body-container-mobile">
                    {
                        locationIntelFeautures.map(element  => (
                            <div key={element.id} className="location-details-description-mobile">
                                <img src={element.image} alt="" />
                                <div className="text">
                                    <p className="text-title">{element.title}</p>
                                    <p className="text-description">{element.description}</p>
                                </div>
                            </div>
                        ))
                    }
                    <div className="customer-types-container">
                        <TabPanelContainer
                                elements = {elements}
                        /> 
                    </div>
                </div>)
                :
                (<div className="home-page-body-container">
                    <div className="location-details-description">
                    {
                        locationIntelFeautures.map(element  => (
                            <div key={element.id} className="location-details-description-sections">
                                <img src={element.image} alt="" />
                                <div className={`default-overlay  bottom-border-${element.id}`}>
                                    <div className="text">
                                        <p className="text-description">{element.title}</p>
                                    </div>
                                </div>
                                <div className={`overlay  ${element.id}`}>
                                    <div className="text">
                                        <p className="text-title">{element.title}</p>
                                        <p className="text-description">{element.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                    <div className="customer-types-container">
                        <TabPanelContainer
                                elements = {elements}
                        /> 
                    </div>
                </div>)
            }
        </div>
    );
}