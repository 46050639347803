import React from "react";
import Form from "react-bootstrap/Form";
import NumberFormat from 'react-number-format';
import "./FloatLabel.css";

export default function FloatLabelPhoneNumber(props) {
    return (
        <>
            <div className="floating-label" style={{ height: "33px" }}>
                <NumberFormat
                    style={{ padding: "0.375rem 0.75rem" }}
                    data-testid={props.inputName}
                    id={props.inputName}
                    autoFocus={props.inputAutofocus}
                    name={props.inputName}
                    onChange={(e) => props.handleChangeProps(e)}
                    className={props.inputClassName}
                    disabled={props.inputDisabled}
                    placeholder={props.inputPlaceholder}
                    value={props.inputValue}
                    type="tel"
                    format="###-###-####"
                    mask=" "
                    allowEmptyFormatting={true}
                />
                <label className="textbox-floating-label" htmlFor={props.inputName}>{props.inputLabel}</label>
            </div>
            {props.inputHelperText !== undefined && props.inputHelperText.length > 0 ? (
                <Form.Text className={props.inputClassName}>{props.inputHelperText}</Form.Text>
            ) : (
                <></>
            )}
        </>
    );
}