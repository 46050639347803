import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./AddEntityModal.css";
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import ProgressSpinner from "../components/ProgressSpinner";

export default function ConfirmationModal(props)
{

    async function handleConfirmationSubmit(e) {
        e.preventDefault();
        props.onConfirmation(e);
        props.onHide();
    }

    function handleClose() {
        props.onHide();
    }

    return (
        <Modal
        {...props}
        dialogClassName="modal-container modal-confirmation"
        centered
    >
        <Modal.Header>
            <Modal.Title>
                <div className="modal-img"><WarningIcon/></div>
                <div>{props.modalTitle}</div>
                <div className="modal-close" onClick={() => handleClose()}><CloseIcon/></div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "20px", }}>
            {(props.loading) ? (
                    <div className="spinner-container modal-spinner-container">
                        <ProgressSpinner fontColor="#FFFFFF" />
                    </div>
                ) : <></> }
            <div className="modal-description">
                <div>{props.modalHeader}</div>               
                <div>{props.modalBody}</div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <>
            <Button className="btn-cancel" onClick={() => handleClose()}>Cancel</Button>
                <Button className="btn-submit" onClick={(e) => { handleConfirmationSubmit(e) }} type="submit">{props.confirmationButtonName}</Button>
            </>
        </Modal.Footer>
    </Modal>
    );
}