import React, { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import EditLocationRadioButtons from "../editlocationdetails/EditLocationRadioButtons";
import LocationClosures from "../locationdetails/LocationClosures";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ApproveDenyAllToggle from "./ApproveDenyAllToggle";

export default function PendingClosuresEdits(props) {
  const pendingMainClosuresEdits = useMemo(() => { return props.pendingMainClosuresEdits }, [props]);
  const [panel1Expanded, setPanel1Expanded] = useState(true);

  function handleMainClosuresApproveDeny(target){
    props.handleApproveDeny(target, "mainClosures");
  }

  function handleReviewedAllMainClosuresCheck(checked){
    props.handleReviewedAllCheck(checked, "mainClosures");
  }
  
  return (
    <div className="edit-details-body">
      <div className="edit-details-title">
        Original Closures
        <div className="original-data-body"> 
          {<LocationClosures mainClosures={props.originalMainClosures} />}
        </div>
      </div>     
      <div className="pending-edits-body-margin">
        {pendingMainClosuresEdits?.length > 0 ? (   
          <Accordion expanded={panel1Expanded} onChange={() => setPanel1Expanded(!panel1Expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <div className="edit-details-title">Main Closures Edits ({pendingMainClosuresEdits?.length})</div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{paddingLeft: "16px"}}>
                <ApproveDenyAllToggle 
                  handleApproveDenyAll={handleMainClosuresApproveDeny} 
                  reviewedAll={props.reviewedAll.reviewedAll} 
                  approveDenyAll={props.reviewedAll.approvedAll}
                  handleReviewedAllCheck={handleReviewedAllMainClosuresCheck}
                />
              </div>
              <div className="location-pending-edits-table">
                <Table className="floating-table edits-table-content-align-top">
                    <thead>
                        <tr>
                          <th className="edits-table-th-first">Editor Username</th>                           
                            <th>Time Stamp ({props.userTimeZone})</th>
                            <th style={{ width: "200px"}}>Main Closures</th>                      
                            <th style={{ textAlign: "center"}}>Review Edits</th>
                        </tr>
                    </thead>               
                    <tbody>
                            {pendingMainClosuresEdits.map((item, index) =>
                            <tr key={item.editId}>
                                <td className="edits-table-td-first">{item.editAddedBy}</td>                                
                                <td>{props.convertTimeToLocal(item.editAddedDate)}</td>
                                <td style={{ width: "200px"}}><LocationClosures mainClosures={item} /></td>
                                <td style={{ textAlign: "center"}}>
                                  <EditLocationRadioButtons 
                                      radioName={item.editId}
                                      radioIndex={index}
                                      radioOptions={props.toggleOptions}
                                      radioChecked={item.approved !== undefined ? item.approved : null}
                                      defaultValue={null}
                                      useToggle={true}
                                      onRadioChange={handleMainClosuresApproveDeny}
                                  />
                                </td>
                            </tr>
                        )}
                    </tbody>
                  </Table>
              </div>
            </AccordionDetails>
          </Accordion>                       
        ) :
        (
            <div className="edit-details-title">
                *** No Edits to review ***
            </div>
        )}       
      </div>
    </div>
  );
}