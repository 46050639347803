import React, { useEffect, useMemo, useState } from "react";
import ToggleSwitch from "../../components/elements/checkbox/ToggleSwitch";
import "./EditClosures.css";

export default function EditClosures(props) {
    const propsMainClosures = useMemo(() => {return props.mainClosures}, [props]);
    const[mainClosures, setMainClosures] = useState(propsMainClosures);
    const[checked, setChecked] = useState();

    function handleChange(e){
        const target = e.target;
        switch(target.name)
        {
          case 'closedNewYearsEve':
                                  setMainClosures({
                                    ...mainClosures,
                                    closedNewYearsEve: target.checked, 
                                  });
                                  break;
          case 'closedNewYearsDay':
                                  setMainClosures({
                                    ...mainClosures,
                                    closedNewYearsDay: target.checked, 
                                  });
                                  break;
          case 'closedIndependenceDay':
                                  setMainClosures({
                                    ...mainClosures,
                                    closedIndependenceDay: target.checked, 
                                  });
                                  break;
          case 'closedVeteransDay':
                                  setMainClosures({
                                    ...mainClosures,
                                    closedVeteransDay: target.checked, 
                                  });
                                  break;
          case 'closedChristmasEve':
                                  setMainClosures({
                                    ...mainClosures,
                                    closedChristmasEve: target.checked, 
                                  });
                                  break;
          case 'closedChristmasDay':
                                  setMainClosures({
                                    ...mainClosures,
                                    closedChristmasDay: target.checked, 
                                  });
                                  break;
          default: break;
        }
    }

    useEffect(() => {
        props.handleChange("closures", mainClosures);
    },[mainClosures]);

    return(
        <div className="edit-details-body">
            <div className="edit-details-title">
                Facility Closures
            </div>
            <div className="edit-details-description" style={{ marginBottom: '10px' }}>
                Select holiday and facility closures for this location.
            </div>
            <div className="main-closure-toggle">
                <ToggleSwitch id="new-years-eve" checked={mainClosures?.closedNewYearsEve} onToggleChange={handleChange} name="closedNewYearsEve"/>
                <label className="main-closure-toggle-label" htmlFor="new-years-eve">New Year's Eve: <span className="main-closure-toggle-label-bold">December 31<sup>st</sup></span></label>
            </div>
            <div className="main-closure-toggle">    
                <ToggleSwitch id="new-years-day" checked={mainClosures?.closedNewYearsDay} onToggleChange={handleChange} name="closedNewYearsDay"/>
                <label className="main-closure-toggle-label" htmlFor="new-years-day">New Year's Day: <span className="main-closure-toggle-label-bold">January 1<sup>st</sup></span></label>
            </div>
            <div className="main-closure-toggle">    
                <ToggleSwitch id="independence-day" checked={mainClosures?.closedIndependenceDay} onToggleChange={handleChange} name="closedIndependenceDay"/>
                <label className="main-closure-toggle-label" htmlFor="independence-day">Independence Day: <span className="main-closure-toggle-label-bold">July 4<sup>th</sup></span></label>
            </div>
            <div className="main-closure-toggle">    
                <ToggleSwitch id="veterans-day" checked={mainClosures?.closedVeteransDay} onToggleChange={handleChange} name="closedVeteransDay"/>
                <label className="main-closure-toggle-label" htmlFor="veterans-day">Veterans Day: <span className="main-closure-toggle-label-bold">November 11<sup>th</sup></span></label>
            </div>
            <div className="main-closure-toggle">    
                <ToggleSwitch id="christmas-eve" checked={mainClosures?.closedChristmasEve} onToggleChange={handleChange} name="closedChristmasEve"/>
                <label className="main-closure-toggle-label" htmlFor="christmas-eve">Christmas Eve: <span className="main-closure-toggle-label-bold">December 24<sup>th</sup></span></label>
            </div>
            <div className="main-closure-toggle">    
                <ToggleSwitch id="christmas-day" checked={mainClosures?.closedChristmasDay} onToggleChange={handleChange} name="closedChristmasDay"/>
                <label className="main-closure-toggle-label" htmlFor="christmas-day">Christmas Day: <span className="main-closure-toggle-label-bold">December 25<sup>th</sup></span></label>
            </div>        
        </div>
    )
}