import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT, LOOKER_CLIENT_ID, LOOKER_CLIENT_SECRET } from './../../constants/apiConstants'
import useAxios from 'axios-hooks'
import { useEffect } from 'react'
import { useSessionContext } from './../../libs/sessionLib';

export default function Looker(props){
    const { session } = useSessionContext();
    const api_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/looker/lookurl" : API_BASE_URL + "/looker/lookurl";
    const [{ data: embedurl, loading, error, response }, getData] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );
    useEffect(() => {
        getURL()
    }, [])      
    async function getURL(){
        try {
            const payload = {
                "clientId": LOOKER_CLIENT_ID,
                "clientSecret": LOOKER_CLIENT_SECRET,
                "targetUrl": props.targetURL,
                "externalUserId": session.userName
            }
            const resp = await getData({
                data: {
                    ...payload
                }
            });
        } catch (e) {
            console.error(error);
        }
    }
return (
     <iframe id={props.id} title='looker-frame' src={embedurl}></iframe>
    )
}

