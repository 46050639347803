import React, { useEffect, useState } from "react";
import IsMobileDevice from "../IsMobileDevice";
import "./EditServices.css";
import EditLocationRadioButtons from "./EditLocationRadioButtons";
import Form from "react-bootstrap/Form";
import FloatLabelCurrency from "../FloatLabelCurrency";

export default function EditServices(props) {

    const isMobileView = IsMobileDevice();
    const[services, setServices] = useState(props.services);
    const[lumperFee, setLumperFee] = useState({ 
        lumperCost: (props.services.lumper && props.services.lumperCost !== null) ? (props.services.lumperCost).toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2}) : "", 
        currencyType : props.services?.currencyType ? props.services.currencyType : "USD" });
    const[helperText, setHelperText] = useState(props.services?.lumper ? "" : "Select Yes before entering amount");
    const radioOptions = isMobileView ? [ {id: 1, name: "Yes"}, {id: 0, name: "No"}, {id: 2, name: "Not Sure"}] : [ {id: 1, name: "Yes"}, {id: 0, name: "No"}];
    const currencyOptions = [
        {
            "id": "USD",
            "name": "USD"
        },
        {
            "id": "CAD",
            "name": "CAD"
        }
    ];

    function handleRadioChange(target){
        const value = parseInt(target.value) > 1 ? null : (parseInt(target.value) === 1);
        if(value){
            setServices({
                ...services,
                lumper: value,
            });
            setHelperText("");
        }
        else{
            setServices({
                lumper: value,
                lumperCost: null,
                currencyType: null,
                paymentTypeCash: false,
                paymentTypeCreditCard: false,
                paymentTypeCheck: false,
                paymentTypeCOMCheck: false,
            });
            setLumperFee({
                ...lumperFee,
                lumperCost: "",
                currencyType: "USD",
            });
            setHelperText("Select Yes before entering amount");
        }
    }

    function handleInputChange(e){
        setLumperFee({
            ...lumperFee,
            [e.target.name]: e.target.value,
        });
        if(e.target.name === "currencyType" && services.lumperCost !== null){           
            setServices({
                ...services,
                currencyType: e.target.value,
            });            
        }
        else if(e.target.name === "lumperCost"){
            let inputValue = e.target.value;           
            setServices({
                ...services,
                lumperCost: inputValue.length > 0 ? parseFloat(inputValue.replace(/,/g, '')) : null,
                currencyType: inputValue.length > 0 ? lumperFee.currencyType : null,
            });                    
        }
    }

    function handleCheckboxChange(e){
        setServices({
            ...services,
            [e.target.name]: e.target.checked,
        })
    }

    useEffect(() => {
        props.handleChange("services", services);
    },[services]);

    return(
        <div className="edit-details-body services-container" style={{ paddingTop: "1%" }}>
            <EditLocationRadioButtons 
                    radioLabel="Lumper Service"
                    radiolabelDescription="Does this location offer lumper service?"
                    radioName="lumperService"
                    radioOptions={radioOptions}
                    radioChecked={services.lumper ? 1 : (services.lumper === false) ? 0 : null}
                    defaultValue={2}
                    onRadioChange={handleRadioChange}
            />
            <div className="edit-details-body-padding">
                <div className="radio-label">Lumper Fee</div>
                <div className="label-description">Please enter the lumper fee amount.</div>
                <Form>
                    <FloatLabelCurrency 
                        inputLabel="LUMPER FEE"
                        inputAutoFocus="true"
                        inputType="text"
                        inputName="lumperCost"
                        inputValue={lumperFee.lumperCost}
                        inputMAXCost={10000}
                        inputPlaceholder="Amount"
                        inputClassName={`dynamic-form-text`}
                        selectOptions={currencyOptions}
                        inputHelperText={[<div key={-1} style={{ paddingBottom: "8px", marginTop: "-2px" }}><div style={{ float: "left" }}>{helperText}</div></div>]}
                        handleChangeProps={(e) => handleInputChange(e)}
                        inputDisabled={!services.lumper}
                        inputDecimalScale={2}
                        inputCurrencyType={lumperFee.currencyType}                    
                    />
                </Form>
            </div>
            <div className="edit-details-body-padding">
            <div className="radio-label">Payment Options</div>
                <div className="label-description">Which payment options are accepted at this location?</div>
                <div className="appointment-checkbox-container">             
                    <div className="checkbox-margin-bottom">
                        <label className="option-check-container">Cash
                            <input type="checkbox" id="paymentTypeCash" name="paymentTypeCash" disabled={!services.lumper} className="option-checkbox" checked={services.paymentTypeCash} onChange={(e) => handleCheckboxChange(e)}></input>
                            <span className="option-checkmark"></span>
                        </label>
                    </div>
                    <div className="checkbox-margin-bottom">
                        <label className="option-check-container">Credit Card
                            <input type="checkbox" id="paymentTypeCreditCard" name="paymentTypeCreditCard" disabled={!services.lumper} className="option-checkbox" checked={services.paymentTypeCreditCard} onChange={(e) => handleCheckboxChange(e)}></input>
                            <span className="option-checkmark"></span>
                        </label>
                    </div>
                    <div className="checkbox-margin-bottom">
                        <label className="option-check-container">Check
                            <input type="checkbox" id="paymentTypeCheck" name="paymentTypeCheck" disabled={!services.lumper} className="option-checkbox" checked={services.paymentTypeCheck} onChange={(e) => handleCheckboxChange(e)}></input>
                            <span className="option-checkmark"></span>
                        </label>
                    </div>
                    <div className="checkbox-margin-bottom">
                        <label className="option-check-container">Comcheck
                            <input type="checkbox" id="paymentTypeCOMCheck" name="paymentTypeCOMCheck" disabled={!services.lumper} className="option-checkbox" checked={services.paymentTypeCOMCheck} onChange={(e) => handleCheckboxChange(e)}></input>
                            <span className="option-checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>          
        </div>
    )
}