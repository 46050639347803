import React, { useEffect } from "react";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../../constants/apiConstants.js';
import { onError } from "../../libs/errorLib";
import useAxios from 'axios-hooks';
import "./CompanyDetails.css";
import AddressIcon from '../../images/a-icon-map-location-on.svg';
import WebsiteIcon from '../../images/a-icon-globe.svg';

export default function CompanyDetails(props) {

    const website_url = (props.company && props.company.website) ? ((props.company.website.includes('http://') || props.company.website.includes('https://')) ?
        props.company.website : "https://" + props.company.website) :
        "";

    return (
        props.company ? (
            <div className="company-details-container">
                <div className="company-details-item">
                    <img className="icon" src={AddressIcon} alt="" />
                    <div className="company-details address">
                        <p className="label">Main City & State</p>
                        <p>{props.company.city}, {props.company.stateName} </p>
                    </div>
                </div>
                <div className="company-details-item">
                    <img className="icon" src={WebsiteIcon} alt="" />
                    <div className="company-details">
                        <p className="label">Website</p>
                        {website_url && (
                            <p>
                                <a href={website_url} target="_blank" rel="noreferrer">{(new URL(website_url)).hostname}</a>
                            </p>
                        )
                        }
                    </div>
                </div>
            </div>
        ) : <></>
    );
}
