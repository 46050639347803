import Amplitude, { Identify } from 'amplitude-js';
import React, { createContext, useContext } from 'react';
import { AMPLITUDE_STG_API_KEY, AMPLITUDE_PROD_API_KEY, ENVIRONMENT } from '../constants/apiConstants'

export default function InitAmplitude(){

    const api_key =  ENVIRONMENT === "staging" ? AMPLITUDE_STG_API_KEY : AMPLITUDE_PROD_API_KEY;
    const amplitudeInstance = Amplitude.getInstance();
    amplitudeInstance.init(api_key);

    return amplitudeInstance;
};

export const ampInstance = InitAmplitude();

const AmplitudeInstanceContext = createContext(ampInstance);

export const identifyInst = new Identify();

export function useAmplitude() {
  const amplitude = useContext(AmplitudeInstanceContext);
  if (!amplitude) {
    throw new Error('The component must be wrapped in AmplitudeProvider to use useAmplitude');
  }

  return amplitude;
};

export const AmplitudeContext = React.createContext(AmplitudeInstanceContext);