import L from "leaflet";
import { useEffect, useState } from "react";
import { useMapEvents, TileLayer, LayersControl, GeoJSON } from "react-leaflet";
import markerIcon from "../images/location-details/marker.png";

export default function GeoJSONCluster(props) {
  return (
    <>
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="Basic Map">
        </LayersControl.BaseLayer>        
            <GeoJSON data={props.data} pathOptions={{ color: 'blue' }} />        
      </LayersControl>
    </>
  )
}
