import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import IsMobileDevice from "../components/IsMobileDevice";
import useAxios from 'axios-hooks';
import { useSessionContext } from "../libs/sessionLib";
import { useAmplitude } from '../services/Amplitude';
import { onError } from "../libs/errorLib";
import { Alert } from "react-bootstrap";
import "./EditLocationDetails.css";
import "./AddEntityModal.css";
import { isEqual } from "lodash";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ProgressSpinner from "../components/ProgressSpinner";
import { useHistory } from "react-router-dom";
import EditLocationDetailsDesktop from "./editlocationdetails/EditLocationDetailsDesktop";
import EditLocationDetailsMobile from "./editlocationdetails/EditLocationDetailsMobile";

export default function EditLocationDetails(props) {
    const isMobileView = IsMobileDevice();
    const history = useHistory();
    const [activeSection, setActiveSection] = useState(isMobileView ? "none" : "locationName");
    const [editContainerHeader, setEditContainerHeader] = useState("Location Names");
    const { session } = useSessionContext();
    let { id } = useParams();
    const api_location_by_id_url = ENVIRONMENT === "development" ? "/api/location/" + id + "?isExternalUser=" + !session.isInternalUser : API_BASE_URL + "/location/" + id + "?isExternalUser=" + !session.isInternalUser;
    const [{ data: location, loading, error: getLocationDetailsError }, fetchData] = useAxios(
        {
            url: api_location_by_id_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );
    const api_post_location_edits_url = ENVIRONMENT === "development" ? "/api/location/edits" : API_BASE_URL + "/location/edits";
    const [{ data: postEditsData, loading: postEditsLoading, error: postEditsError }, postData] = useAxios(
        {
            url: api_post_location_edits_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    const api_location_types_url = ENVIRONMENT === "development" ? "/api/location/locationTypes" : API_BASE_URL + "/location/locationTypes";
    const [{ data: locationTypes, error: getLocationTypesError }, fetchLocationTypes] = useAxios(
        {
            url: api_location_types_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const api_contact_types_url = ENVIRONMENT === "development" ? "/api/location/contactTypes" : API_BASE_URL + "/location/contactTypes";
    const [{ data: contactTypes, error: getContactTypesError }, fetchContactTypes] = useAxios(
        {
            url: api_contact_types_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const emptyContact = {
        contactTypeId: 1,
        contactTypeName: "",
        emailAddress: "",
        phone: "",

    };

    const emptyHours = [{
        "businessHoursTypeName": "Business Hours", //hardcoded for now until we allow the save of multiple sets
        "open24By7": false,
        "days": [
            {
                "weekdayId": 1,
                "openOnWeekday": true,
                "openingHours": "",
                "openingMinutes": "",
                "openingHoursAMPM": "",
                "closingHours": "",
                "closingMinutes": "",
                "closingHoursAMPM": "",
                "open24Hours": false
            },
            {
                "weekdayId": 2,
                "openOnWeekday": true,
                "openingHours": "",
                "openingMinutes": "",
                "openingHoursAMPM": "",
                "closingHours": "",
                "closingMinutes": "",
                "closingHoursAMPM": "",
                "open24Hours": false
            },
            {
                "weekdayId": 3,
                "openOnWeekday": true,
                "openingHours": "",
                "openingMinutes": "",
                "openingHoursAMPM": "",
                "closingHours": "",
                "closingMinutes": "",
                "closingHoursAMPM": "",
                "open24Hours": false
            },
            {
                "weekdayId": 4,
                "openOnWeekday": true,
                "openingHours": "",
                "openingMinutes": "",
                "openingHoursAMPM": "",
                "closingHours": "",
                "closingMinutes": "",
                "closingHoursAMPM": "",
                "open24Hours": false
            },
            {
                "weekdayId": 5,
                "openOnWeekday": true,
                "openingHours": "",
                "openingMinutes": "",
                "openingHoursAMPM": "",
                "closingHours": "",
                "closingMinutes": "",
                "closingHoursAMPM": "",
                "open24Hours": false
            },
            {
                "weekdayId": 6,
                "openOnWeekday": true,
                "openingHours": "",
                "openingMinutes": "",
                "openingHoursAMPM": "",
                "closingHours": "",
                "closingMinutes": "",
                "closingHoursAMPM": "",
                "open24Hours": false
            },
            {
                "weekdayId": 7,
                "openOnWeekday": true,
                "openingHours": "",
                "openingMinutes": "",
                "openingHoursAMPM": "",
                "closingHours": "",
                "closingMinutes": "",
                "closingHoursAMPM": "",
                "open24Hours": false
            }
        ]
    }];

    const originalData = useMemo(() => {
        return {
            locationName: {
                locationName: location ? location.name : null,
                mainName: true
            },
            locationId: location ? location.id : null,
            editAddedBy: session ? session.userName : null,
            locationType: {
                locationTypeId: location ? location.locationTypeId : null,
            },
            geoFence1: location ? (location.geoFence1 && location.geoFence1 !== null ? location.geoFence1.replace(/\s/g, '') : "") : "",
            latitude: location ? location.latitude : null,
            longitude: location ? location.longitude : null,
            contactInfo: location ? (location.contactInfo[0] ? [location.contactInfo[0]] : [emptyContact]) : [emptyContact],
            amenities: {
                restroom: location ? location.restrooms : null,
                overnightParking: location ? location.overnightParking : null,
                showers: location ? location.showers : null,
                wiFi: location ? location.wiFi : null,
                breakroom: location ? location.breakroom : null,
                vendingMachine: location ? location.vendingMachine : null,
            },
            additionalAmenities: location && location.additionalAmenities.length > 0 ? location.additionalAmenities : null,
            otherAmenities: [],
            businessHours: location ? location.businessHours : [],
            mainClosures:
            {
                closedNewYearsEve: (location && location.mainClosures) ? location.mainClosures.closedNewYearsEve : false,
                closedNewYearsDay: (location && location.mainClosures) ? location.mainClosures.closedNewYearsDay : false,
                closedIndependenceDay: (location && location.mainClosures) ? location.mainClosures.closedIndependenceDay : false,
                closedVeteransDay: (location && location.mainClosures) ? location.mainClosures.closedVeteransDay : false,
                closedChristmasEve: (location && location.mainClosures) ? location.mainClosures.closedChristmasEve : false,
                closedChristmasDay: (location && location.mainClosures) ? location.mainClosures.closedChristmasDay : false
            },
            appointmentScheduling: (location && location.appointmentScheduling) ? location.appointmentScheduling : {
                byAppointment: null,
                methodOnline: false,
                websiteAddress: null,
                methodPhone: false,
                phoneNumber: null,
                emailAddress: null,
                methodEmail: false,
                specialInstructions: null,
            },
            services: (location && location.services) ? location.services : {
                lumper: null,
                lumperCost: null,
                currencyType: null,
                paymentTypeCash: false,
                paymentTypeCreditCard: false,
                paymentTypeCheck: false,
                paymentTypeCOMCheck: false,
            },
            safetyRequirements: (location && location.safetyRequirements) ? location.safetyRequirements : {
                safetyGlassesRequired: false,
                hardHatRequired: false,
                vestRequired: false,
                closedToeShoesRequired: false,
                steelToeShoesRequired: false,
                petsAllowed: null,
                covidProtocolInstructions: null,
            },
            additionalSafetyRequirements: location && location.additionalSafetyRequirements?.length > 0 ? location.additionalSafetyRequirements : null,
            otherSafetyRequirements: [],
            procedures: (location && location.procedures) ? location.procedures : {
                reportToGuardShack: null,
                driverUnload: null,
                dockAssist: null,
                drivingDirections: null
            },
            review: {
                starReview: 0,
                reviewText: ""
            }
        }
    }, [location, session]);

    const [edits, setEdits] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [editsMade, setEditsMade] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        (location) && setEdits(originalData);
    }, [location, originalData])

    function getData() {
        try {
            fetchData();
            fetchLocationTypes();
            fetchContactTypes();
        } catch (e) {
            onError("Error getting location details");
        }
    }

    useEffect(() => {
        setEditContainerHeader(renderSwitchHeader);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection])

    function renderSwitchHeader() {
        switch (activeSection) {
            case 'geofence': return "Geofence";
            case 'locationType': return "Location Type";
            case 'contacts': return "Contacts";
            case 'amenities': return "Amenities";
            case 'businessHours': return "Business Hours";
            case 'closures': return "Closures";
            case 'appointmentScheduling': return "Appointment Scheduling";
            case 'services': return "Services";
            case 'safetyRequirements': return "Safety Requirements";
            case 'procedures': return "Procedures";
            case 'rateLocation': return "Rate Location";
            case 'none': return "Review Location";
            default: return "Location Names";
        }
    }

    function handleEdits(className, editsOfClass) {
        if (editsOfClass !== null || editsOfClass !== undefined) {
            switch (className) {
                case 'locationName':
                    setEdits({
                        ...edits,
                        locationName: {
                            locationName: editsOfClass,
                            mainName: true
                        },
                    });
                    break;
                case 'locationType':
                    setEdits({
                        ...edits,
                        locationType: editsOfClass,
                    });
                    break;
                case 'contacts':
                    setEdits({
                        ...edits,
                        contactInfo: editsOfClass,
                    });
                    break;
                case 'geofence':
                    setEdits({                        
                        ...edits,
                        geoFence1: editsOfClass,                        
                    });
                    break;
                case 'amenities':
                    setEdits({
                        ...edits,
                        amenities: editsOfClass,
                    });
                    break;
                case 'otherAmenities':
                    setEdits({
                        ...edits,
                        otherAmenities: editsOfClass,
                    });
                    break;
                case 'businessHours':
                    setEdits({
                        ...edits,
                        businessHours: editsOfClass,
                    });
                    break;
                case 'closures':
                    setEdits({
                        ...edits,
                        mainClosures: editsOfClass,
                    });
                    break;
                case 'appointmentScheduling':
                    setEdits({
                        ...edits,
                        appointmentScheduling: editsOfClass,
                    });
                    break;
                case 'services':
                    setEdits({
                        ...edits,
                        services: editsOfClass,
                    });
                    break;
                case 'safetyRequirements':
                    setEdits({
                        ...edits,
                        safetyRequirements: editsOfClass,
                    });
                    break;
                case 'otherSafetyRequirements':
                    setEdits({
                        ...edits,
                        otherSafetyRequirements: editsOfClass,
                    });
                    break;
                case 'procedures':
                    setEdits({
                        ...edits,
                        procedures: editsOfClass,
                    });
                    break;
                case 'rateLocation':
                    setEdits({
                        ...edits,
                        review: editsOfClass,
                    });
                    break;
                default: break;
            }
        }
    }

    useEffect(() => {
        const originalDataAndEditsMatch = isEqual(edits, originalData);
        setEditsMade(!originalDataAndEditsMatch && (edits.locationId !== undefined));
        setDisableSubmit(originalDataAndEditsMatch);
    }, [edits]);

    async function handleSubmit() {
        if (edits) {
            if (isEqual(edits.locationName, originalData.locationName)) edits.locationName = null;
            if (isEqual(edits.locationType, originalData.locationType)) edits.locationType = null;
            if (isEqual(edits.contactInfo, originalData.contactInfo)) edits.contactInfo = null;
            if (isEqual(edits.geoFence1, originalData.geoFence1)) edits.geoFence1 = "";
            if (isEqual(edits.businessHours, originalData.businessHours)) edits.businessHours = null;
            if (isEqual(edits.mainClosures, originalData.mainClosures)) edits.mainClosures = null;
            if (isEqual(edits.amenities, originalData.amenities)) edits.amenities = null;
            if (edits.otherAmenities.length === 0) edits.otherAmenities = null;
            // Appointment Scheduling
            if (isEqual(edits.appointmentScheduling, originalData.appointmentScheduling) || edits.appointmentScheduling.byAppointment === null) {
                edits.appointmentScheduling = null;
            }
            else if (edits.appointmentScheduling && !edits.appointmentScheduling.byAppointment) {
                edits.appointmentScheduling.methodOnline = false;
                edits.appointmentScheduling.websiteAddress = null;
                edits.appointmentScheduling.methodPhone = false;
                edits.appointmentScheduling.phoneNumber = null;
                edits.appointmentScheduling.methodEmail = false;
                edits.appointmentScheduling.emailAddress = null;
            }
            if (isEqual(edits.services, originalData.services)) edits.services = null;
            if (isEqual(edits.safetyRequirements, originalData.safetyRequirements)) {
                edits.safetyRequirements = null;
            }
            else if (isMobileView){
                edits.safetyRequirements.petsAllowed = originalData.safetyRequirements.petsAllowed;
            }
            if (edits.otherSafetyRequirements.length === 0) edits.otherSafetyRequirements = null;
            if (isEqual(edits.procedures, originalData.procedures)) edits.procedures = null;
            if (isEqual(edits.review, originalData.review)) edits.review = null;

            try {
                await postData({
                    data: {
                        ...edits,
                    }
                });
                //Log Amplitude Event
                LogAmplitudeEvent();
                if (isMobileView) {
                    handleBackInMobileView();
                    triggerSuccessAlert(true, "Thank you. Your edits are being processed.");
                    getData();
                }
                else {
                    history.push(`/locationdetails/${location.id}/success`);
                }
            } catch (err) {
                triggerErrorAlert(true, "Error saving the Location Details.");
            }
        }
    }

    useEffect(() => {
        if (getLocationTypesError) {
            handleError(getLocationTypesError, "Error getting Location Types.");
        }
    }, [getLocationTypesError]);

    useEffect(() => {
        if (getContactTypesError) {
            handleError(getContactTypesError, "Error getting Contact Types.");
        }
    }, [getContactTypesError]);

    useEffect(() => {
        if (postEditsError) {
            handleError(postEditsError, "Error Saving the Location Details.");
        }
    }, [postEditsError]);

    function handleError(error, errorMessage) {
        if (error?.response) {
            // client received an error response (5xx, 4xx)
            var message = ""
            if (error.response.data.value) {
                message = error.response.data.value
            }
            else {
                message = error.response.data
            }
            triggerErrorAlert(true, message);
        } else if (error?.request) {
            // client never received a response, or request never left
            triggerErrorAlert(true, errorMessage);
        } else {
            // anything else
            triggerErrorAlert(true, errorMessage);
        }
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function triggerSuccessAlert(show, message) {
        setShowSuccessAlert(show);
        setSuccessAlertMessage(message);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
    }

    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const confirmationModalTitle = "Edits Not Saved";
    const confirmationModalHeader = "Are you sure you want to go back to this location's page without saving your edits?";
    const confirmationButtonName = "Yes, Don't Save"

    function handleCancel(e) {
        e.preventDefault();
        if (!(isEqual(edits, originalData))) {
            setConfirmationModalShow(true);
        }
        else {
            if (isMobileView && activeSection !== "none") {
                handleBackInMobileView();
            }
            else {
                history.push(`/locationdetails/${location.id}`);
            }
        }
    }

    function handleConfirmation() {
        if (isMobileView) {
            handleBackInMobileView();
        }
        else {
            history.push(`/locationdetails/${location.id}`);
        }
    }

    function handleBackInMobileView() {
        setEdits(originalData);
        setActiveSection("none");
    }

    const amplitude = useAmplitude();
    function LogAmplitudeEvent() {
        try {
            var editedDetails = edits;
            Object.keys(editedDetails).forEach(key => {
                if (editedDetails[key] === null) {
                    delete editedDetails[key];
                }
            })

            amplitude.logEvent("Location Details Edited", {
                "Location Id": editedDetails.locationId,
                "Edited Components": Object.keys(editedDetails).filter(key => (key !== "locationId" && key !== "editAddedBy")),
                "Edited By": editedDetails.editAddedBy,
            });

            //Log Events for each edited components
            Object.keys(editedDetails).filter(key => (key !== "locationId" && key !== "editAddedBy")).forEach(key => {
                //Romove unchanged elements
                if (!Array.isArray(editedDetails[key])) {
                    Object.keys(editedDetails[key]).forEach(k => {
                        if (originalData[key] && editedDetails[key][k] === originalData[key][k])
                            delete editedDetails[key][k];
                    });
                }
                else {
                    (editedDetails[key]).forEach((element, index) => {
                        Object.keys(element).forEach(k => {
                            if (originalData[key] && originalData[key].length > 0 && k !== "contactTypeName" && k !== "businessHoursTypeName" && element[k] === originalData[key][index][k])
                                delete element[k];
                        });
                    });
                }
                amplitude.logEvent(key.charAt(0).toUpperCase() + key.slice(1) + " Edited", {
                    "Location Id": editedDetails.locationId,
                    "Edited Details": JSON.stringify(editedDetails[key]),
                    "Edited By": editedDetails.editAddedBy,
                });
            });
        }
        catch (err) {
            console.log("Edits has been saved. Error Logging Amplitude events.");
        }
    }

    return (
        session?.roleName === "Viewer" ? <div className="access-denied">Access Denied: Your account does not have editing permissions.</div> :
        edits.locationId ? (
            <div className="edit-container-main">
                <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                    <ErrorIcon />
                    <div className="floating-alert-body">
                        <Alert.Heading>Error</Alert.Heading>
                        <p>{errorAlertMessage}</p>
                    </div>
                </Alert>
                {isMobileView &&
                    <Alert className="floating-alert" variant="success" show={showSuccessAlert} onClose={() => setShowSuccessAlert(false)} dismissible>
                        <CheckCircleIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Edits Submitted.</Alert.Heading>
                            <p>{successAlertMessage}</p>
                        </div>
                    </Alert>
                }
                    {((postEditsLoading || loading) && (
                        <div className="spinner-container spinner-black">
                        <ProgressSpinner fontColor="#FFFFFF" />
                    </div>
                    ))}
                    {isMobileView ?
                        <EditLocationDetailsMobile 
                          locationName={location.name}
                          locationTypes={locationTypes}
                          contactTypes={contactTypes}
                          emptyHours={emptyHours}
                          activeSection={activeSection}
                          setActiveSection={setActiveSection}
                          originalData={originalData}
                          edits={edits}
                          editContainerHeader={editContainerHeader}
                          editsMade={editsMade}
                          handleEdits={handleEdits}                  
                          handleCancel={handleCancel}
                          handleSubmit={handleSubmit}
                          disableSubmit={disableSubmit}
                          confirmationModalTitle={confirmationModalTitle}
                          confirmationModalHeader={confirmationModalHeader}
                          confirmationButtonName={confirmationButtonName}
                          confirmationModalShow={confirmationModalShow}
                          setConfirmationModalShow={setConfirmationModalShow}
                          handleConfirmation={handleConfirmation}
                          handleBackInMobileView={handleBackInMobileView}
                        />
                        :
                        <EditLocationDetailsDesktop 
                          locationName={location.name}
                          locationTypes={locationTypes}
                          contactTypes={contactTypes}
                          emptyHours={emptyHours}
                          activeSection={activeSection}
                          setActiveSection={setActiveSection}
                          originalData={originalData}
                          edits={edits}
                          editContainerHeader={editContainerHeader}
                          editsMade={editsMade}
                          handleEdits={handleEdits}                  
                          handleCancel={handleCancel}
                          handleSubmit={handleSubmit}
                          disableSubmit={disableSubmit}
                          setDisableSubmit={setDisableSubmit}
                          confirmationModalTitle={confirmationModalTitle}
                          confirmationModalHeader={confirmationModalHeader}
                          confirmationButtonName={confirmationButtonName}
                          confirmationModalShow={confirmationModalShow}
                          setConfirmationModalShow={setConfirmationModalShow}
                          handleConfirmation={handleConfirmation}
                          triggerErrorAlert={triggerErrorAlert}
                        />
                    }
                </div>
            ) : <></>
    );
}