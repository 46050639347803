import { Fragment, useEffect, useState, useRef } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import "./AddEntityModal.css"
import FloatLabelTextBox from "./FloatLabelTextBox";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT, LOCATION_DATA_API_URL, LOCATION_DATA_API_KEY } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks'
import { useSessionContext } from "../libs/sessionLib";
import ErrorIcon from '@material-ui/icons/Error';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import FloatLabelDropdown from "./FloatLabelDropdown";

export default function AddUserModal(props) {
    const { session } = useSessionContext();
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [errorClasses, setErrorClasses] = useState({
        companyId: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        title: "",
        phoneNumber: "",
        userRoleId: "",
        externalUserTypeId: "",
        locationId: "",
        subscriptionId: "",
        apiAuthorizationKey: ""
    });
    const [companySearchError, setCompanySearchError] = useState("");
    const [hideExternalUser, setHideExternalUser] = useState("hide-box");
    const [hideInternalUser, setHideInternalUser] = useState("");
    const [hideApiUser, setHideApiUser] = useState("");
    const [apiKeyError, setApiKeyError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newUser, setNewUser] = useState({
        companyId: "",
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        password: "",
        title: "",
        phoneNumber: "",
        userRoleId: 1,
        externalUserTypeId: 1,
        isInternalUser: false,
        createdById: (session !== undefined ? session.id : null),
        modifiedById: (session !== undefined ? session.id : null),
        locationId: null,
        subscriptionId: "",
        apiAuthorizationKey: ""
    });
    const [internalUser, setInternalUser] = useState("external");

    const api_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/" : API_BASE_URL + "/user/";
    const [{ data, loading, error, response }, postNewUser] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );
    const newApiUserUrl = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/api-user" : API_BASE_URL + "/user/api-user";
    const [{ }, postNewApiUser] = useAxios(
        {
            url: newApiUserUrl,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );
    const filterBy = () => true;
    const [compOptions, setCompOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const api_company_search_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/company/search" : API_BASE_URL + "/company/search";
    const [{ data: companies, loading: compLoading, error: compError, response: compResponse }, fetchCompanies] = useAxios(
        {
            url: api_company_search_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const api_get_static_types_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/statictypes?types=UserTypes&types=UserRoles" : API_BASE_URL + "/user/statictypes?types=UserTypes&types=UserRoles";
    const [{ data: staticTypes, error: staticTypesError, response: staticTypesResponse }, fetchStaticTypes] = useAxios(
        {
            url: api_get_static_types_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const api_get_subscription_types_url = LOCATION_DATA_API_URL + "/location/subscription-types";
    const [{ data: subscriptionTypes }, fetchSubscriptionTypes] = useAxios(
        {
            url: api_get_subscription_types_url,
            headers: {
                'x-api-key': LOCATION_DATA_API_KEY
            },
            method: "GET"
        },
        { manual: true }
    );
    useEffect(() => {
        fetchStaticTypes();
        fetchSubscriptionTypes();
    }, [])

    function handleChange(e) {
        setNewUser({
            ...newUser,
            [e.target.name]: e.target.value,
        })
    }
    function handlePhoneNumberChange(e) {
        const phoneNumber = e.target.value.replace(/[^\d]/g, "");
        setNewUser({
            ...newUser,
            phoneNumber: phoneNumber,
        })
    }
    const companyTypeaheadRef = useRef(null);
    const [companyText, setCompanyText] = useState("");
    function handleCompanyChange(e) {
        setCompanySearchError("");
        setLocationSearchError("");
        setErrorClasses({
            ...errorClasses,
            locationId: (""),
            companyId: ("")
        });
        setCompanyText(e[0]?.companyName);

        if (e[0] !== undefined) {
            setNewUser({
                ...newUser,
                companyId: e[0].id
            });
        } else {
            setNewUser({
                ...newUser,
                companyId: ""
            });
        }
    }

    function handleSubscriptionChange(e) {
        setNewUser({
            ...newUser,
            subscriptionId: e.target.value,
            subscriptionName: e.target[e.target.value] !== undefined ? e.target[e.target.value].text : null
        });
    }

    function updateInternalUser(e) {
        setInternalUser(e.target.value);
        setHideExternalUser("");
        setHideInternalUser("");
        setHideApiUser("");

        if (e.target.value === "internal") {
            setHideInternalUser("hide-box");
        }
        else if (e.target.value === "api") {
            setHideApiUser("hide-box");
        }
        else if (e.target.value === "external") {
            setHideExternalUser("hide-box");
        }
        clearUser()
        clearErrors()
    }
    function handleClose() {
        clearUser();
        clearErrors();
        props.onHide();
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);
        var missingFields = false;
        var invalidEmail = false;
        var invalidPhoneNumber = false;
        var errors = {};

        if (internalUser === "external") {
            if (newUser.companyId.length === 0 || !companyTypeaheadRef.current.state.text || companyTypeaheadRef.current.state.text === "") {
                errors["companyId"] = "error";
                missingFields = true;
            }
            else {
                errors["companyId"] = "";
            }
            if (newUser.phoneNumber.length === 0) {
                errors["phoneNumber"] = "error";
                missingFields = true;
            }
            else {
                if (validatePhoneNumber(newUser.phoneNumber)) {
                    errors["phoneNumber"] = "";
                }
                else {
                    errors["phoneNumber"] = "error";
                    invalidPhoneNumber = true;
                }
            }
            if (newUser.title.length === 0) {
                errors["title"] = "error";
                missingFields = true;
            }
            else {
                errors["title"] = "";
            }
            if (newUser.userRoleId <= 1) {
                errors["userRoleId"] = "error";
                missingFields = true;
            }
            else {
                errors["userRoleId"] = "";
            }
            if (newUser.externalUserTypeId <= 1) {
                errors["externalUserTypeId"] = "error";
                missingFields = true;
            }
            else {
                errors["externalUserTypeId"] = "";
            }
            if (newUser.firstName.length === 0) {
                errors["firstName"] = "error";
                missingFields = true;
            }
            else {
                errors["firstName"] = "";
            }

            if (newUser.lastName.length === 0) {
                errors["lastName"] = "error";
                missingFields = true;
            }
            else {
                errors["lastName"] = "";
            }

            if (newUser.password.length === 0) {
                errors["password"] = "error";
                missingFields = true;
            }
            else {
                errors["password"] = "";
            }
            if (locationAdmin && (newUser.locationId === null || newUser.locationId === 0)) {
                errors["locationId"] = "error";
                missingFields = true;
            }
            else {
                errors["locationId"] = "";
            }

        } else if (internalUser === "internal") {
            if (newUser.firstName.length === 0) {
                errors["firstName"] = "error";
                missingFields = true;
            }
            else {
                errors["firstName"] = "";
            }

            if (newUser.lastName.length === 0) {
                errors["lastName"] = "error";
                missingFields = true;
            }
            else {
                errors["lastName"] = "";
            }

            if (newUser.password.length === 0) {
                errors["password"] = "error";
                missingFields = true;
            }
            else {
                errors["password"] = "";
            }

        } else if (internalUser === "api") {
            if (newUser.companyId.length === 0 || !companyTypeaheadRef.current.state.text || companyTypeaheadRef.current.state.text === "") {
                errors["companyId"] = "error";
                missingFields = true;
            }
            else {
                errors["companyId"] = "";
            }

            if (!newUser.subscriptionId || newUser.subscriptionId.length === 0) {
                errors["subscriptionId"] = "error";
                missingFields = true;
            }
            else {
                errors["subscriptionId"] = "";
            }

            if (!newUser.apiAuthorizationKey || newUser.apiAuthorizationKey.length !== 40 || !validateApiKey(newUser.apiAuthorizationKey)) {
                errors["apiAuthorizationKey"] = "error";
                setApiKeyError("API Key must be 40 alphanumeric characters");
                missingFields = true;
            }
            else {
                errors["apiAuthorizationKey"] = "";
                setApiKeyError("");
            }
        }

        if (newUser.email.length === 0) {
            errors["email"] = "error";
            missingFields = true;
        }
        else {
            if (validateEmail(newUser.email)) {
                errors["email"] = "";
            }
            else {
                errors["email"] = "error";
                invalidEmail = true;
            }
        }

        setErrorClasses({
            companyId: ("companyId" in errors ? errors["companyId"] : ""),
            firstName: ("firstName" in errors ? errors["firstName"] : ""),
            lastName: ("lastName" in errors ? errors["lastName"] : ""),
            email: ("email" in errors ? errors["email"] : ""),
            password: ("password" in errors ? errors["password"] : ""),
            phoneNumber: ("phoneNumber" in errors ? errors["phoneNumber"] : ""),
            title: ("title" in errors ? errors["title"] : ""),
            userRoleId: ("userRoleId" in errors ? errors["userRoleId"] : ""),
            externalUserTypeId: ("externalUserTypeId" in errors ? errors["externalUserTypeId"] : ""),
            locationId: ("locationId" in errors ? errors["locationId"] : ""),
            subscriptionId: ("subscriptionId" in errors ? errors["subscriptionId"] : ""),
            apiAuthorizationKey: ("apiAuthorizationKey" in errors ? errors["apiAuthorizationKey"] : "")
        });

        if (!missingFields && !invalidEmail && !invalidPhoneNumber) {
            try {
                if (internalUser === "api") {
                    await postNewApiUser({
                        data: {
                            ...newUser,
                            companyId: (newUser.companyId),
                            createdBy: (session !== undefined ? session.id : ""),
                            subscriptionId: (newUser.subscriptionId.length > 0 ? parseInt(newUser.subscriptionId) : null),
                            apiAuthorizationKey: (newUser.apiAuthorizationKey)
                        }
                    }).then(_ => { setIsSubmitting(false); });
                    clearUser();
                    clearErrors();
                    props.onSuccess(true, "'" + newUser.subscriptionName + "' API User has been saved and added.");
                    props.onHide();
                    return;
                }
                await postNewUser({
                    data: {
                        ...newUser,
                        companyId: (internalUser === "internal" ? "00000000-0000-0000-0000-000000000000" : newUser.companyId),
                        userName: newUser.email,
                        createdById: (session !== undefined ? session.id : ""),
                        modifiedById: (session !== undefined ? session.id : ""),
                        userRoleId: (newUser.userRoleId.length > 0 ? parseInt(newUser.userRoleId) : 1),
                        externalUserTypeId: (newUser.externalUserTypeId.length > 0 ? parseInt(newUser.externalUserTypeId) : 1),
                        isInternalUser: (internalUser === "internal"),
                        locationId: ((newUser.locationId !== null && newUser.locationId > 0) ? parseInt(newUser.locationId) : null)
                    }
                }).then(_ => { setIsSubmitting(false); });
                clearUser();
                clearErrors();
                props.onSuccess(true, "'" + newUser.firstName + " " + newUser.lastName + "' has been saved and added.");
                props.onHide();
            } catch (err) {
                triggerErrorAlert(true, "Error saving user");
                setIsSubmitting(false);
            }
        } else {
            if (missingFields) {
                triggerErrorAlert(true, "Required fields missing.");
            }
            if (invalidEmail) {
                triggerErrorAlert(true, "Email is invalid.");
            }
            if (invalidPhoneNumber) {
                triggerErrorAlert(true, "Phone number is invalid. Format XXXXXXXXXXXX");
            }
            setIsSubmitting(false);
        }
    }
    useEffect(() => {
        if (companies !== undefined) {
            const options = companies.map((i) => ({
                id: i.id,
                companyName: i.companyName,
            }));
            setCompOptions(options);
        }
    }, [companies]);

    useEffect(() => {
        if (compError) {
            if (compError.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (compError.response.data) {
                    if (compError.response.data.value) {
                        message = compError.response.data.value
                    }
                    else {
                        message = compError.response.data
                    }
                }
                else {
                    message = "Error getting company list"
                }
                setCompanySearchError(message);
                setErrorClasses({
                    ...errorClasses,
                    companyId: ("error")
                });
            } else if (compError.request) {
                // client never received a response, or request never left
                setCompanySearchError("Error getting company list");
                setErrorClasses({
                    ...errorClasses,
                    companyId: ("error")
                });
            } else {
                // anything else
                setCompanySearchError("Error getting company list");
                setErrorClasses({
                    ...errorClasses,
                    companyId: ("error")
                });
            }
        }
    }, [compError]);

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error adding user");
            } else {
                // anything else
                triggerErrorAlert(true, "Error adding user");
            }
        }
    }, [error]);

    async function handleSearch(query) {
        try {
            setCompanySearchError("");
            setLocationSearchError("");
            setErrorClasses({
                ...errorClasses,
                locationId: (""),
                companyId: ("")
            });
            if (query.length > 2) {
                await fetchCompanies({
                    url: api_company_search_url + "?name=" + query
                });
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status !== 404) {
                    setCompanySearchError("Error getting companies");
                    setErrorClasses({
                        ...errorClasses,
                        companyId: ("error")
                    });
                }
            }
            else {
                setCompanySearchError("Error getting companies");
                setErrorClasses({
                    ...errorClasses,
                    companyId: ("error")
                });
            }
        }
    }

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function clearUser() {
        setNewUser({
            companyId: "",
            firstName: "",
            lastName: "",
            email: "",
            userName: "",
            password: "",
            title: "",
            phoneNumber: "",
            userRoleId: 1,
            externalUserTypeId: 1,
            isInternalUser: false,
            createdById: null,
            modifiedById: null,
            locationId: null,
            subscriptionId: "",
            apiAuthorizationKey: ""
        });
        companyTypeaheadRef.current.clear();
        setCompanySearchError("");
        setLocationSearchError("");
        setApiKeyError("");
    }

    function clearErrors() {
        setErrorClasses({
            companyId: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            title: "",
            phoneNumber: "",
            userRoleId: "",
            externalUserTypeId: "",
            locationId: "",
            subscriptionId: "",
            apiAuthorizationKey: ""
        });
    }

    function validateEmail(elementValue) {
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(elementValue);
    }

    function validatePhoneNumber(elementValue) {
        var phonePattern = /^\+?([0-9]{0,2})\)?[- ]?([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$/;
        return phonePattern.test(elementValue);
    }

    function validateApiKey(elementValue) {
        const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
        return regex.test(elementValue);
    };

    function generatePassword(e) {
        e.preventDefault();

        var generator = require('generate-password');

        var randomPass = generator.generate({
            length: 10,
            numbers: true,
            symbols: true,
            uppercase: true,
            lowercase: true,
            strict: true
        });
        setNewUser({
            ...newUser,
            password: randomPass,
        })
    }

    const filterByLocation = () => true;
    const [locationAdmin, setLocationAdmin] = useState(false);
    const [disableLocationSearch, setDisableLocationSearch] = useState(true);

    function handleRoleChange(e) {
        setLocationSearchError("");
        setErrorClasses({
            ...errorClasses,
            locationId: ("")
        });

        setLocationAdmin(false);
        if (e.target.value === '4') {
            setLocationAdmin(true);
            if (newUser.companyId === undefined || newUser.companyId.length === 0) {
                setDisableLocationSearch(true);
                setCompanySearchError("Please select a company");
                setErrorClasses({
                    ...errorClasses,
                    companyId: ("error")
                });
            } else {
                setDisableLocationSearch(false);
                setCompanySearchError("");
                setErrorClasses({
                    ...errorClasses,
                    companyId: ("")
                });
            }
        } else {
            setDisableLocationSearch(false);
            setCompanySearchError("");
            setErrorClasses({
                ...errorClasses,
                companyId: ("")
            });
        }
        setNewUser({
            ...newUser,
            [e.target.name]: e.target.value,
        })
    }

    const api_company_locations_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/company/location/all/" + (newUser.companyId !== undefined ? newUser.companyId : "") : API_BASE_URL + "/company/location/all/" + (newUser.companyId !== undefined ? newUser.companyId : "");
    const [{ data: locations, loading: locsLoading, locationIdsError }, fetchLocations] = useAxios(
        {
            url: api_company_locations_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    const [locationSearchError, setLocationSearchError] = useState("");
    const [locationQuery, setLocationQuery] = useState("");
    const locationTypeaheadRef = useRef(null);
    function handleCompanyBlur(e) {
        if (locationAdmin && e.target.value !== companyText) {
            locationTypeaheadRef.current.clear();
            resetLocation();
        }
    }

    async function handleLocationSearch(query) {
        try {
            setLocationSearchError("");
            setErrorClasses({
                ...errorClasses,
                locationId: ("")
            });
            if (newUser.companyId === undefined || newUser.companyId.length === 0) {
                setCompanySearchError("Please select a company");
                setErrorClasses({
                    ...errorClasses,
                    locationId: ("error"),
                    companyId: ("error")
                });
                return;
            }
            setLocationQuery(query);
            if (query.length > 2) {
                await fetchLocations({
                    url: api_company_locations_url
                });
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status === 404) {
                    setLocationSearchError("No records found");
                    setErrorClasses({
                        ...errorClasses,
                        locationId: ("error")
                    });
                }
                else {
                    setLocationSearchError("Error getting locations");
                    setErrorClasses({
                        ...errorClasses,
                        locationId: ("error")
                    });
                }
            }
            else {
                setLocationSearchError("Error getting locations");
                setErrorClasses({
                    ...errorClasses,
                    locationId: ("error")
                });
            }
        }
    }

    useEffect(() => {
        if (locations !== null && locations !== undefined && locations.length > 0) {
            let options = locations.map((i) => ({
                id: i.id,
                locationName: i.locationName
            }));

            options = options.filter(function (option) {
                return option.locationName !== undefined && option.locationName !== null && option.locationName.toUpperCase().indexOf(locationQuery.toUpperCase()) !== -1
            })

            setLocationOptions(options);
            return;
        }
        setLocationOptions([]);
    }, [locations]);

    function handleLocationChange(e) {
        if (e[0] !== undefined) {
            setNewUser({
                ...newUser,
                locationId: e[0].id,
            });
        }
    }

    useEffect(() => {
        if (locationIdsError) {
            if (locationIdsError.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (locationIdsError.response.data) {
                    if (locationIdsError.response.data.value) {
                        message = locationIdsError.response.data.value
                    }
                    else {
                        message = locationIdsError.response.data
                    }
                }
                else {
                    message = "Error getting location list"
                }
                setLocationSearchError(message);
                setErrorClasses({
                    ...errorClasses,
                    locationId: ("error")
                });
            } else if (locationIdsError.request) {
                // client never received a response, or request never left
                setLocationSearchError("Error getting location list");
                setErrorClasses({
                    ...errorClasses,
                    locationId: ("error")
                });
            } else {
                // anything else
                setLocationSearchError("Error getting location list");
                setErrorClasses({
                    ...errorClasses,
                    locationId: ("error")
                });
            }
        }
    }, [locationIdsError]);

    useEffect(() => {
        if ((newUser.companyId === undefined || newUser.companyId === null) && locationAdmin && (locationTypeaheadRef.current !== null || locationTypeaheadRef.current !== undefined)) {
            locationTypeaheadRef.current.clear();
            resetLocation();
            setDisableLocationSearch(true);
            return;
        }
        if (locationAdmin && (locationTypeaheadRef.current !== null || locationTypeaheadRef.current !== undefined)) {
            locationTypeaheadRef.current.clear();
            resetLocation();
            setDisableLocationSearch(false);
            return;
        }
        setDisableLocationSearch(false);
    }, [newUser.companyId]);

    function resetLocation() {
        setNewUser({
            ...newUser,
            locationId: null,
        });
    }

    return (
        <>
            <Modal
                {...props}
                dialogClassName="modal-container"
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        Add User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                        <ErrorIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>{errorAlertMessage}</p>
                        </div>
                    </Alert>
                    <Form onSubmit={handleSubmit} style={{ padding: '0' }}>
                        User Type
                        <div className="radio-button-toggle-wrapper">
                            <input
                                type="radio"
                                className="radio-button-toggle"
                                name="isInternalUser"
                                value="internal"
                                id="internal"
                                onChange={(e) => { updateInternalUser(e) }}
                                checked={internalUser === "internal"}
                            />
                            <label htmlFor="internal">Internal</label>
                            <input
                                type="radio"
                                className="radio-button-toggle"
                                name="isInternalUser"
                                value="external"
                                id="external"
                                onChange={(e) => { updateInternalUser(e) }}
                                checked={internalUser === "external"}
                            />
                            <label htmlFor="external">External</label>
                            <input
                                type="radio"
                                className="radio-button-toggle"
                                name="isInternalUser"
                                value="api"
                                id="api"
                                onChange={(e) => { updateInternalUser(e) }}
                                checked={internalUser === "api"}
                            />
                            <label htmlFor="api">API</label>
                        </div>
                        <div className={hideInternalUser + hideApiUser}>
                            <Form.Group className="medium-form-group" style={{ maxWidth: "170px" }}>
                                <FloatLabelDropdown
                                    inputLabel="USER TYPE"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="externalUserTypeId"
                                    inputPlaceholder="Select"
                                    inputValue={newUser.externalUserTypeId}
                                    inputClassName={errorClasses.externalUserTypeId}
                                    handleChangeProps={handleChange}
                                    inputAs="select"
                                    selectOptions={staticTypes !== undefined ? (staticTypes.userTypes !== undefined ? staticTypes.userTypes : []) : []}
                                    defaultOption="Select"
                                />
                            </Form.Group>
                        </div>
                        <div className={hideApiUser} >
                            Personal Information
                            <Row>
                                <Form.Group className="medium-form-group inline-left">
                                    <FloatLabelTextBox
                                        inputLabel="FIRST NAME"
                                        inputAutoFocus="true"
                                        inputType="text"
                                        inputName="firstName"
                                        inputPlaceholder="First Name"
                                        inputValue={newUser.firstName}
                                        inputClassName={errorClasses.firstName}
                                        handleChangeProps={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="medium-form-group inline-right">
                                    <FloatLabelTextBox
                                        inputLabel="LAST NAME"
                                        inputAutoFocus="false"
                                        inputType="text"
                                        inputName="lastName"
                                        inputPlaceholder="Last Name"
                                        inputValue={newUser.lastName}
                                        inputClassName={errorClasses.lastName}
                                        handleChangeProps={handleChange}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                        Contact Information
                        <Row>
                            <Form.Group className="medium-form-group">
                                <FloatLabelTextBox
                                    inputLabel="EMAIL"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="email"
                                    inputPlaceholder="Email"
                                    inputValue={newUser.email}
                                    inputClassName={errorClasses.email}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className={hideInternalUser + hideApiUser + " medium-form-group inline-right"}>
                                <FloatLabelTextBox
                                    inputLabel="PHONE #"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="phoneNumber"
                                    inputPlaceholder="Phone #"
                                    inputValue={newUser.phoneNumber}
                                    inputClassName={errorClasses.phoneNumber}
                                    handleChangeProps={handlePhoneNumberChange}
                                />
                            </Form.Group>
                        </Row>
                        <div className={hideInternalUser}>
                            Company Information
                            <Row>
                                <div className={newUser.companyId?.length > 0 ? "medium-form-group async-floating-label small-field" : "medium-form-group async-floating-label"} style={{ display: "block" }}>
                                    <AsyncTypeahead
                                        className={errorClasses.companyId}
                                        filterBy={filterBy}
                                        id="companyId"
                                        isLoading={compLoading}
                                        labelKey="companyName"
                                        minLength={3}
                                        onSearch={handleSearch}
                                        onChange={(e) => handleCompanyChange(e)}
                                        options={compOptions}
                                        placeholder="Search Company"
                                        useCache={false}
                                        onBlur={(e) => handleCompanyBlur(e)}
                                        ref={companyTypeaheadRef}
                                    />
                                    <label className={newUser.companyId?.length > 0 ? "floating" : "hidden-label"} htmlFor="companyId">COMPANY</label>
                                    {companySearchError !== undefined && companySearchError.length > 0 ? (
                                        <Form.Text className="error" style={{ marginTop: '-25px' }}>{companySearchError}</Form.Text>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                <Form.Group className={hideApiUser + hideInternalUser + " medium-form-group inline-right"}>
                                    <FloatLabelTextBox
                                        inputLabel="JOB TITLE"
                                        inputAutoFocus="false"
                                        inputType="text"
                                        inputName="title"
                                        inputPlaceholder="Job Title"
                                        inputValue={newUser.title}
                                        inputClassName={errorClasses.title}
                                        handleChangeProps={handleChange}
                                    />
                                </Form.Group>
                            </Row>
                            <div className={hideInternalUser + hideApiUser}>
                                Role & Permission
                                <Row>
                                    <Form.Group className="medium-form-group">
                                        <FloatLabelDropdown
                                            inputLabel="USER ROLE"
                                            inputAutoFocus="false"
                                            inputType="text"
                                            inputName="userRoleId"
                                            inputPlaceholder="Select"
                                            inputValue={newUser.userRoleId}
                                            inputClassName={errorClasses.userRoleId}
                                            handleChangeProps={(e) => handleRoleChange(e)}
                                            inputAs="select"
                                            selectOptions={staticTypes !== undefined ? (staticTypes.userRoles !== undefined ? staticTypes.userRoles : []) : []}
                                            defaultOption="Select"
                                        />
                                    </Form.Group>

                                    {locationAdmin &&
                                        <div className={(newUser.locationId !== null && newUser.locationId.length > 0) ? "medium-form-group async-floating-label small-field" : "medium-form-group async-floating-label"} style={{ display: "block", paddingLeft: "15px", paddingRight: "0px" }}>
                                            <AsyncTypeahead
                                                className={errorClasses.locationId}
                                                filterBy={filterByLocation}
                                                id="locationId"
                                                isLoading={locsLoading}
                                                labelKey="locationName"
                                                minLength={3}
                                                onSearch={handleLocationSearch}
                                                onChange={(e) => handleLocationChange(e)}
                                                options={locationOptions}
                                                placeholder="Search Location"
                                                useCache={false}
                                                disabled={disableLocationSearch}
                                                ref={locationTypeaheadRef}
                                            />
                                            <label className={(newUser.locationId !== null && newUser.locationId.length > 0) ? "floating" : "hidden-label"} htmlFor="locationId">LOCATION</label>
                                            {locationSearchError !== undefined && locationSearchError.length > 0 ? (
                                                <Form.Text className="error" style={{ marginTop: '-25px' }}>{locationSearchError}</Form.Text>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    }
                                </Row>
                            </div>
                        </div>
                        <div className={hideApiUser}>
                            Generate Password
                            <Form.Group className="medium-form-group">
                                <FloatLabelTextBox
                                    inputLabel="PASSWORD"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="password"
                                    inputPlaceholder="Password"
                                    inputValue={newUser.password}
                                    inputClassName={errorClasses.password}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Button className="btn-square" onClick={(e) => { generatePassword(e) }}>Generate Password</Button>
                        </div>
                        <div className={hideInternalUser + hideExternalUser}>
                            Subscription Type
                            <Form.Group className="medium-form-group">
                                <FloatLabelDropdown
                                    inputLabel="SUBSCRIPTION TYPE"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="subscriptionId"
                                    inputPlaceholder="Select"
                                    inputValue={newUser.subscriptionId}
                                    inputClassName={errorClasses.subscriptionId}
                                    handleChangeProps={(e) => handleSubscriptionChange(e)}
                                    inputAs="select"
                                    selectOptions={subscriptionTypes !== undefined ? subscriptionTypes : []}
                                    defaultOption="Select"
                                />
                            </Form.Group>
                        </div>
                        <div className={hideInternalUser + hideExternalUser}>
                            API Authorization Key
                            <Row>
                                <Form.Group className="medium-form-group">
                                    <FloatLabelTextBox
                                        inputLabel="API Key"
                                        inputAutoFocus="true"
                                        inputType="text"
                                        inputName="apiAuthorizationKey"
                                        inputPlaceholder="Generated from AWS"
                                        inputValue={newUser.apiAuthorizationKey}
                                        inputClassName={errorClasses.apiAuthorizationKey}
                                        handleChangeProps={handleChange}
                                    />
                                    {apiKeyError !== undefined && apiKeyError.length > 0 ? (
                                        <Form.Text className="error">{apiKeyError}</Form.Text>
                                    ) : (
                                        <></>
                                    )}
                                </Form.Group>

                            </Row>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-cancel" onClick={handleClose}>Cancel</Button>
                    <Button className="btn-submit" onClick={(e) => { handleSubmit(e) }} type="submit" disabled={isSubmitting}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}