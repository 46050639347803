import { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import "./AddEntityModal.css"
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks'
import { useSessionContext } from "../libs/sessionLib";
import ErrorIcon from '@material-ui/icons/Error';
import USStates from '../constants/USStates';
import ProgressSpinner from "../components/ProgressSpinner";
import OmnitracsLogo from '../images/LocationIntelligence.svg';
import FixedLabelTextBox from "./elements/textbox/FixedLabelTextBox";
import FixedLabelDropdown from "./elements/textbox/FixedLabelDropdown";

export default function AddNewUserRequestModal(props) {
    const [disableButton, setDisableButton] = useState(true);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [errorClasses, setErrorClasses] = useState({
        firstName: "",
        lastName: "",
        email: "",
        title: "",
        phoneNumber: "",
        externalUserTypeId: "",
        companyName: "",
        companyWebsite: "",
        companyCity: "",
        companyStateCode: ""
    });
    const [newUserRequest, setNewUserRequest] = useState({
        firstName: "",
        lastName: "",
        email: "",
        title: "",
        phoneNumber: "",
        externalUserTypeId: 1,
        companyName: "",
        companyWebsite: "",
        companyCity: "",
        companyStateCode: ""
    });

    const api_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/new-user-request" : API_BASE_URL + "/user/new-user-request";
    const [{ data, loading, error, response }, postNewUserRequest] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    const api_get_static_types_url = ENVIRONMENT === "development" ? API_BASE_URL + "/api/user/statictypes?types=UserTypes&types=UserRoles" : API_BASE_URL + "/user/statictypes?types=UserTypes&types=UserRoles";
    const [{ data: staticTypes, error: staticTypesError, response: staticTypesResponse }, fetchStaticTypes] = useAxios(
        {
            url: api_get_static_types_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            }
        },
        { manual: true }
    );

    useEffect(() => {
        fetchStaticTypes();
    }, [])

    function handleChange(e) {
        setNewUserRequest({
            ...newUserRequest,
            [e.target.name]: e.target.value,
        })
    }
    function handlePhoneNumberChange(e) {
        const phoneNumber = e.target.value.replace(/[^\d]/g, "");
        setNewUserRequest({
            ...newUserRequest,
            phoneNumber: phoneNumber,
        })
    }
    function handleClose() {
        clearUser();
        clearErrors();
        props.onHide();
    }
    async function handleSubmit(e) {
        e.preventDefault();
        var missingFields = false;
        var invalidEmail = false;
        var invalidPhoneNumber = false;
        var errors = {};

        if (newUserRequest.firstName.length === 0) {
            errors["firstName"] = "error";
            missingFields = true;
        }
        else {
            errors["firstName"] = "";
        }

        if (newUserRequest.lastName.length === 0) {
            errors["lastName"] = "error";
            missingFields = true;
        }
        else {
            errors["lastName"] = "";
        }

        if (newUserRequest.email.length === 0) {
            errors["email"] = "error";
            missingFields = true;
        }
        else {
            if (validateEmail(newUserRequest.email)) {
                errors["email"] = "";
            }
            else {
                errors["email"] = "error";
                invalidEmail = true;
            }
        }
        if (newUserRequest.phoneNumber.length === 0) {
            errors["phoneNumber"] = "error";
            missingFields = true;
        }
        else {
            if (validatePhoneNumber(newUserRequest.phoneNumber)) {
                errors["phoneNumber"] = "";
            }
            else {
                errors["phoneNumber"] = "error";
                invalidPhoneNumber = true;
            }
        }

        if (newUserRequest.title.length === 0) {
            errors["title"] = "error";
            missingFields = true;
        }
        else {
            errors["title"] = "";
        }

        if (newUserRequest.externalUserTypeId <= 1) {
            errors["externalUserTypeId"] = "error";
            missingFields = true;
        }
        else {
            errors["externalUserTypeId"] = "";
        }

        if (newUserRequest.companyName.length === 0) {
            errors["companyName"] = "error";
            missingFields = true;
        }
        else {
            errors["companyName"] = "";
        }

        if (newUserRequest.companyWebsite.length === 0) {
            errors["companyWebsite"] = "error";
            missingFields = true;
        }
        else {
            errors["companyWebsite"] = "";
        }

        if (newUserRequest.companyCity.length === 0) {
            errors["companyCity"] = "error";
            missingFields = true;
        }
        else {
            errors["companyCity"] = "";
        }

        if (newUserRequest.companyStateCode.length === 0) {
            errors["companyStateCode"] = "error";
            missingFields = true;
        }
        else {
            errors["companyStateCode"] = "";
        }
        setErrorClasses({
            firstName: ("firstName" in errors ? errors["firstName"] : ""),
            lastName: ("lastName" in errors ? errors["lastName"] : ""),
            email: ("email" in errors ? errors["email"] : ""),
            phoneNumber: ("phoneNumber" in errors ? errors["phoneNumber"] : ""),
            title: ("title" in errors ? errors["title"] : ""),
            externalUserTypeId: ("externalUserTypeId" in errors ? errors["externalUserTypeId"] : ""),
            companyName: ("companyName" in errors ? errors["companyName"] : ""),
            companyWebsite: ("companyWebsite" in errors ? errors["companyWebsite"] : ""),
            companyCity: ("companyCity" in errors ? errors["companyCity"] : ""),
            companyStateCode: ("companyStateCode" in errors ? errors["companyStateCode"] : ""),
        });

        if (!missingFields && !invalidEmail && !invalidPhoneNumber) {
            await postNewUserRequest({
                data: {
                    ...newUserRequest,
                    externalUserTypeId: (newUserRequest.externalUserTypeId.length > 0 ? parseInt(newUserRequest.externalUserTypeId) : 1)
                }
            });
            clearUser();
            clearErrors();
            props.onSuccess(true, "New user request has been submitted.");
            props.onHide();
        } else {
            if (missingFields) {
                triggerErrorAlert(true, "Required fields missing.");
            }
            if (invalidEmail) {
                triggerErrorAlert(true, "Email is invalid.");
            }
            if (invalidPhoneNumber) {
                triggerErrorAlert(true, "Phone number is invalid. Format XXXXXXXXXXXX");
            }
        }
    }

    useEffect(() => {
        setDisableButton(loading);
    }, [loading]);

    useEffect(() => {
        if (newUserRequest.firstName.length > 0 && newUserRequest.lastName.length > 0 && newUserRequest.email.length > 0 && newUserRequest.phoneNumber.length > 0  && newUserRequest.title.length > 0 
            && newUserRequest.companyName.length > 0 && newUserRequest.companyWebsite.length > 0 && newUserRequest.companyCity.length > 0 && newUserRequest.companyStateCode.length > 0){
                setDisableButton(false);
            }
            else {
                setDisableButton(true);
            }
    }, [newUserRequest]);

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value) {
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error adding user");
            } else {
                // anything else
                triggerErrorAlert(true, "Error adding user");
            }
        }
    }, [error]);

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function clearUser() {
        setNewUserRequest({
            firstName: "",
            lastName: "",
            email: "",
            title: "",
            phoneNumber: "",
            externalUserTypeId: 1,
            companyName: "",
            companyWebsite: "",
            companyCity: "",
            companyStateCode: ""
        });
    }

    function clearErrors() {
        setErrorClasses({
            firstName: "",
            lastName: "",
            email: "",
            title: "",
            phoneNumber: "",
            externalUserTypeId: "",
            companyName: "",
            companyWebsite: "",
            companyCity: "",
            companyStateCode: ""
        });
    }

    function validateEmail(elementValue) {
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(elementValue);
    }

    function validatePhoneNumber(elementValue) {
        var phonePattern = /^\+?([0-9]{0,2})\)?[- ]?([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$/;
        return phonePattern.test(elementValue);
    }

    return (
        <>
            <Modal
                {...props}
                dialogClassName="sign-up-modal-container"
                centered
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                    <img src={OmnitracsLogo} alt="" />
                    <span>Get started with a free account</span>
                    <p>Create a free, single user, account for web platform access</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingBotton: "0" }}>
                    <Alert className="floating-alert floating-alert-new-user" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                        <ErrorIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>{errorAlertMessage}</p>
                        </div>
                    </Alert>
                    {loading ? (
                        <div className="sign-up-spinner-container" style={{ zIndex: "2" }}>
                            <ProgressSpinner />
                        </div>
                    ) : <></>}
                    <Form onSubmit={handleSubmit} style={{ padding: '0' }}>
                        <Form.Group style={{ marginTop: "10px", width: "100%" }}>
                            <FixedLabelDropdown
                                inputLabel="User Type"
                                inputAutoFocus="false"
                                inputType="text"
                                inputName="externalUserTypeId"
                                inputPlaceholder=""
                                inputValue={newUserRequest.externalUserTypeId}
                                inputClassName={errorClasses.externalUserTypeId}
                                handleChangeProps={handleChange}
                                inputAs="select"
                                selectOptions={staticTypes !== undefined ? (staticTypes.userTypes !== undefined ? staticTypes.userTypes : []) : []}
                                defaultOption=""
                            />
                        </Form.Group>
                        Personal Information
                        <Row style={{ height: "100%", marginBottom: "0"}}>
                            <Form.Group className="inline-left" style={{ width: "50%" }}>
                                <FixedLabelTextBox
                                    inputLabel="First Name"
                                    inputAutoFocus="true"
                                    inputType="text"
                                    inputName="firstName"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.firstName}
                                    inputClassName={errorClasses.firstName}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="inline-right" style={{ width: "50%" }}>
                                <FixedLabelTextBox
                                    inputLabel="Last Name"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="lastName"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.lastName}
                                    inputClassName={errorClasses.lastName}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row style={{ height: "100%", marginBottom: "0"}}>
                            <Form.Group className="inline-left" style={{ width: "50%" }}>
                                <FixedLabelTextBox
                                    inputLabel="Job Title"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="title"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.title}
                                    inputClassName={errorClasses.title}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        Contact Information
                        <Row style={{ height: "100%", marginBottom: "0"}}>
                            <Form.Group className="inline-left" style={{ width: "50%" }}>
                                <FixedLabelTextBox
                                    inputLabel="Email"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="email"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.email}
                                    inputClassName={errorClasses.email}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="inline-right" style={{ width: "50%" }}>
                                <FixedLabelTextBox
                                    inputLabel="Phone #"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="phoneNumber"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.phoneNumber}
                                    inputClassName={errorClasses.phoneNumber}
                                    handleChangeProps={handlePhoneNumberChange}
                                />
                            </Form.Group>
                        </Row>
                        Company Information
                        <Row style={{ height: "100%", marginBottom: "0"}}>
                            <Form.Group className="inline-left" style={{ width: "50%" }}>
                                <FixedLabelTextBox
                                    inputLabel="Company Name"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="companyName"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.companyName}
                                    inputClassName={errorClasses.companyName}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="inline-right" style={{ width: "50%" }}>
                                <FixedLabelTextBox
                                    inputLabel="Company Website"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="companyWebsite"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.companyWebsite}
                                    inputClassName={errorClasses.companyWebsite}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row style={{ height: "100%", marginBottom: "0"}}>
                            <Form.Group className="inline-left" style={{ width: "50%" }}>
                                <FixedLabelTextBox
                                    inputLabel="City"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="companyCity"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.companyCity}
                                    inputClassName={errorClasses.companyCity}
                                    handleChangeProps={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="inline-right" style={{ width: "50%" }}>
                                <FixedLabelDropdown
                                    inputLabel="State"
                                    inputAutoFocus="false"
                                    inputType="text"
                                    inputName="companyStateCode"
                                    inputPlaceholder=""
                                    inputValue={newUserRequest.companyStateCode}
                                    inputClassName={errorClasses.companyStateCode}
                                    handleChangeProps={handleChange}
                                    inputAs="select"
                                    selectOptions={USStates}
                                    defaultOption=""
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-submit" onClick={(e) => { handleSubmit(e) }} type="submit" disabled={disableButton}>Sign-Up</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}