import { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./AddEntityModal.css"
import FloatLabelDropdown from "./FloatLabelDropdown";
import FloatLabelTextBox from "./FloatLabelTextBox";
import { API_BASE_URL, ACCESS_TOKEN_NAME, ENVIRONMENT } from '../constants/apiConstants.js';
import useAxios from 'axios-hooks'
import { useSessionContext } from "../libs/sessionLib";
import ErrorIcon from '@material-ui/icons/Error';
import USStates from '../constants/USStates';

export default function AddCompanyModal(props) {
    const { session } = useSessionContext();
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [errorClass, setErrorClass] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [newCompany, setNewCompany] = useState({
        company_name: "",
        city: "",
        state_code: "",
        website: "",
        created_by: (session !== undefined ? session.id : null),
        modified_by: (session !== undefined ? session.id : null)
    });

    const api_url = ENVIRONMENT === "development" ? "/api/company/" : API_BASE_URL + "/company/";
    const [{ data, loading, error, response }, postData] = useAxios(
        {
            url: api_url,
            headers: {
                'x-api-key': ACCESS_TOKEN_NAME
            },
            method: "POST"
        },
        { manual: true }
    );

    function handleChange(e) {
        setNewCompany({
            ...newCompany,
            [e.target.name]: e.target.value,
        })
    }
    function handleClose() {
        clearCompany();
        props.onHide();
    }
    async function handleSubmit(e) {
        e.preventDefault();

        if (newCompany.company_name.length > 0 && newCompany.city.length > 0 && newCompany.state_code.length > 0) {
            setErrorClass("");
            setErrorMessage("");

            try {
                await postData({
                    data: {
                        ...newCompany,
                        created_by: (session !== undefined ? session.id : ""),
                        modified_by: (session !== undefined ? session.id : "")
                    }
                });
                clearCompany();
                props.onSuccess(true, "'" + newCompany.company_name + "' has been saved and added.");
                props.onHide();
            } catch (err) {
                triggerErrorAlert(true, "Error saving company");
            }
        } else {
            setErrorClass("error");
            setErrorMessage("Required fields missing.");
        }
    }

    useEffect(() => {
        if (error) {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                var message = ""
                if (error.response.data.value){
                    message = error.response.data.value
                }
                else {
                    message = error.response.data
                }
                triggerErrorAlert(true, message);
            } else if (error.request) {
                // client never received a response, or request never left
                triggerErrorAlert(true, "Error adding company");
            } else {
                // anything else
                triggerErrorAlert(true, "Error adding company");
            }
        }
    }, [error]);

    function triggerErrorAlert(show, message) {
        setShowErrorAlert(show);
        setErrorAlertMessage(message);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 5000);
    }

    function clearCompany() {
        setNewCompany({
            company_name: "",
            city: "",
            state_code: "",
            website: "",
            created_by: null,
            modified_by: null
        });
    }
    return (
        <>
            <Modal
                {...props}
                dialogClassName="modal-container"
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        Add Company
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert className="floating-alert" variant="danger" show={showErrorAlert} onClose={() => setShowErrorAlert(false)} dismissible>
                        <ErrorIcon />
                        <div className="floating-alert-body">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>{errorAlertMessage}</p>
                        </div>
                    </Alert>
                    Company Information
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="medium-form-group">
                            <FloatLabelTextBox
                                inputLabel="COMPANY NAME"
                                inputAutoFocus="true"
                                inputType="text"
                                inputName="company_name"
                                inputPlaceholder="Company Name"
                                inputValue={newCompany.company_name}
                                inputClassName={errorClass}
                                handleChangeProps={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="medium-form-group">
                            <FloatLabelTextBox
                                inputLabel="CITY"
                                inputAutoFocus="false"
                                inputType="text"
                                inputName="city"
                                inputPlaceholder="City"
                                inputValue={newCompany.city}
                                inputClassName={errorClass}
                                handleChangeProps={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="medium-form-group">
                            <FloatLabelDropdown
                                inputLabel="STATE"
                                inputAutoFocus="false"
                                inputType="text"
                                inputName="state_code"
                                inputPlaceholder="State"
                                inputValue={newCompany.state_code}
                                inputClassName={errorClass}
                                handleChangeProps={handleChange}
                                inputAs="select"
                                selectOptions={USStates}
                                defaultOption="State"
                            />
                        </Form.Group>
                        <Form.Group className="medium-form-group">
                            <FloatLabelTextBox
                                inputLabel="COMPANY WEBSITE"
                                inputAutoFocus="false"
                                inputType="text"
                                inputName="website"
                                inputPlaceholder="Company Website"
                                inputValue={newCompany.website}
                                inputHelperText={errorMessage}
                                handleChangeProps={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-cancel" onClick={handleClose}>Cancel</Button>
                    <Button className="btn-submit" onClick={(e) => { handleSubmit(e) }} type="submit">Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}