import React, { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import EditLocationRadioButtons from "../editlocationdetails/EditLocationRadioButtons";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationSafetyRequirements from "../locationdetails/LocationSafetyRequirements";
import ApproveDenyAllToggle from "./ApproveDenyAllToggle";

export default function PendingSafetyRequirementsEdits(props) {
  const pendingSafetyRequirementsEdits = useMemo(() => { return props.pendingSafetyRequirementsEdits }, [props]);
  const pendingAdditionalEquipmentsEdits = useMemo(() => { return props.pendingAdditionalEquipmentsEdits }, [props]);
  const [panel1Expanded, setPanel1Expanded] = useState(true);
  const [panel2Expanded, setPanel2Expanded] = useState(true);

  function handleSafetyRequirementsApproveDeny(target){
    props.handleApproveDeny(target, "safetyRequirements");
  }

  function handleReviewedAllSafetyRequirementsCheck(checked){
    props.handleReviewedAllCheck(checked, "safetyRequirements");
  }

  function handleOtherSafetyRequirementsApproveDeny(target){
    props.handleApproveDeny(target, "otherSafetyRequirements");
  }

  function handleReviewedAllOtherSafetyRequirementsCheck(checked){
    props.handleReviewedAllCheck(checked, "otherSafetyRequirements");
  }
  
  return (
    <div className="edit-details-body">
      <div className="edit-details-title">
        Original Safety Requirements
        <div className="original-data-body"> 
          <LocationSafetyRequirements
            safetyRequirements={props.originalSafetyRequirements}
            additionalSafetyRequirements={props.originalAdditionalEquipments}
          ></LocationSafetyRequirements>
          {props.originalAdditionalEquipments.length === 0 && 
          <div style={{ marginTop: '2%' }}>
            <p style={{ marginBottom: '3px' }} className='sub-title'>Additional Equipment Information</p>
            <p className='location-details-nodata'>NO DATA AVAILABLE</p>
          </div>}    
        </div>
      </div>     
      <div className="pending-edits-body-margin">
        {pendingSafetyRequirementsEdits?.length > 0 || pendingAdditionalEquipmentsEdits?.length > 0 ?  
          (<>
          <Accordion expanded={panel1Expanded} onChange={() => setPanel1Expanded(!panel1Expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <div className="edit-details-title">Safety Requirements Edits ({pendingSafetyRequirementsEdits?.length})</div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{paddingLeft: "16px"}}>
                <ApproveDenyAllToggle 
                  handleApproveDenyAll={handleSafetyRequirementsApproveDeny} 
                  reviewedAll={props.reviewedAll.reviewedAll} 
                  approveDenyAll={props.reviewedAll.approvedAll}
                  handleReviewedAllCheck={handleReviewedAllSafetyRequirementsCheck}
                />
              </div>
              <div className="location-pending-edits-table">
                <Table className="floating-table edits-table-content-align-top">
                  <thead>
                    <tr>
                      <th className="edits-table-th-first">Editor Username</th>                           
                      <th>Time Stamp ({props.userTimeZone})</th>
                      <th className="edited-content">Safety Requirements</th>                      
                      <th style={{ textAlign: "center"}}>Review Edits</th>
                    </tr>
                  </thead>               
                  <tbody>
                    {pendingSafetyRequirementsEdits?.length > 0 ?
                      (pendingSafetyRequirementsEdits.map((item, index) =>
                      <tr key={item.editId}>
                        <td className="edits-table-td-first">{item.editAddedBy}</td>                                
                        <td>{props.convertTimeToLocal(item.editAddedDate)}</td>
                        <td className="edited-content pending-safety-edits">
                          <LocationSafetyRequirements safetyRequirements={item}/>
                        </td>
                        <td style={{ textAlign: "center"}}>
                          <EditLocationRadioButtons 
                              radioName={item.editId}
                              radioIndex={index}
                              radioOptions={props.toggleOptions}
                              radioChecked={item.approved !== undefined ? item.approved : null}
                              defaultValue={null}
                              useToggle={true}
                              onRadioChange={handleSafetyRequirementsApproveDeny}
                          />
                        </td>
                      </tr>
                    )) : 
                    (<tr>
                      <td colSpan="4" style={{ textAlign: 'center', borderRadius: '8px' }}>No Safety Requirements Edits to review.</td>
                    </tr>)}
                  </tbody>
                </Table>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={panel2Expanded} onChange={() => setPanel2Expanded(!panel2Expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <div className="edit-details-title">Additional Equipment Edits ({pendingAdditionalEquipmentsEdits?.length})</div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{paddingLeft: "16px"}}>
                <ApproveDenyAllToggle 
                  handleApproveDenyAll={handleOtherSafetyRequirementsApproveDeny} 
                  reviewedAll={props.reviewedAllOther.reviewedAll} 
                  approveDenyAll={props.reviewedAllOther.approvedAll}
                  handleReviewedAllCheck={handleReviewedAllOtherSafetyRequirementsCheck}
                />
              </div>
              <div className="location-pending-edits-table">
                <Table className="floating-table edits-table-content-align-top">
                  <thead>
                    <tr>
                      <th className="edits-table-th-first">Editor Username</th>                           
                      <th>Time Stamp ({props.userTimeZone})</th>
                      <th className="edited-content-type" style={{ textAlign: "center"}}>Add/Remove</th>
                      <th style={{ width: "200px"}}>Equipment Name</th>                      
                      <th style={{ textAlign: "center"}}>Review Edits</th>
                    </tr>
                  </thead>               
                  <tbody>
                    {pendingAdditionalEquipmentsEdits?.length > 0 ?
                      (pendingAdditionalEquipmentsEdits.map((item, index) =>
                      <tr key={item.editId}>
                        <td className="edits-table-td-first">{item.editAddedBy}</td>                                
                        <td>{props.convertTimeToLocal(item.editAddedDate)}</td>
                        <td className="edited-content-type" style={{ textAlign: "center"}}>{item.deleteFlag ? "Remove" : "Add"}</td>
                        <td className="edited-content" style={{ textTransform: "capitalize"}}>{item.equipmentName.toLowerCase()}</td>
                        <td style={{ textAlign: "center"}}>
                          <EditLocationRadioButtons 
                              radioName={item.editId}
                              radioIndex={index}
                              radioOptions={props.toggleOptions}
                              radioChecked={item.approved !== undefined ? item.approved : null}
                              defaultValue={null}
                              useToggle={true}
                              onRadioChange={handleOtherSafetyRequirementsApproveDeny}
                          />
                        </td>
                      </tr>
                    )) : 
                    (<tr>
                      <td colSpan="5" style={{ textAlign: 'center', borderRadius: '8px' }}>No Additional Equipment Edits to review.</td>
                    </tr>)}
                  </tbody>
                </Table>
              </div>
            </AccordionDetails>
        </Accordion>
        </>) :
        (<div className="edit-details-title">*** No Edits to review ***</div>)}       
      </div>
    </div>
  );
}